<template>
  <v-app>
    <notifications/>
    <v-main>
		<div id="content" class="content">
			<Loader :isVisible="loading" :isText="'Chargement en cours...'"/>
    
    <div class="panel panel-inverse" data-sortable-id="table-basic-2" data-init="true">
						<!-- begin panel-heading -->
						<div class="panel-heading ui-sortable-handle">
							<div class="panel-heading-btn">
								<button  type="button" v-if="codeUser!='CAISSIER'"  @click="tele()" class="btn btn-success">Exporté le journal</button>
							</div>
							<h4 class="panel-title">
								Saisie des journaux  
							</h4>
						</div>
						<!-- end panel-heading -->
						<!-- begin panel-body -->
						<div class="panel-body">
							<div class="row">
								<div class="col-md-2">
            	<div class="form-group">
                  <label class="form-label semibold" for="libelle">Caisse </label>
						     	<model-list-select :list="getterCodePenal"
                     v-model="formData.caisse_id"
                     option-value="id"
                     option-text="libelle"
                     placeholder="Caisse">
                 </model-list-select>
							
                </div>
            </div>
             <div class="col-md-2">
                <div class="form-group">
                  <label class="form-label semibold" for="type_operation">Type opération  </label>
                 
               <select
                    class="form-control"
                    v-model="formData.type_operation"
                  >
                    <option></option>
                    <option
                value="DEBIT"
                    >
                      DEBIT
                    </option>
                    <option
                value="CREDIT"
                    >
                      CREDIT
                    </option>
                  </select>
                </div>
              </div> 
<div class="col-md-2">
              <div class="form-group">
                  <label class="form-label semibold" for="libelle">N° de compte d'un tiers </label>
                  <model-list-select :list="getterCriteIndigence"
                     v-model="formData.compte_tier_id"
                     option-value="id"
                     option-text="code"
                     placeholder="N° compte">
              </model-list-select>
              
                </div>
            </div>
                <div class="col-md-2" v-if="formData.type_operation">
              
                 <div class="form-group">
                  <label class="form-label semibold" for="libelle">N° de compte général </label>
                   <select v-if="!formData.compte_tier_id"
                    class="form-control"
                    v-model="formData.compte_general_id" 
                  >
                    <option v-for="item in listeger" :key="item.code" :value="item.id">
                      {{item.code}}
                    </option>
                   
                  </select>

                  <select v-if="formData.compte_tier_id"
                    class="form-control"
                    v-model="formData.compte_general_id" disabled
                  >
                    <option v-for="item in listeger" :key="item.code" :value="item.id">
                      {{item.code}}
                    </option>
                   
                  </select>
                  {{listeger2(formData.compte_tier_id)}}
                </div>
             </div>
            
             <div class="col-md-2">
             	<div class="form-group">
                  <label class="form-label semibold" for="Date">Date</label>
						     	<input type="date"  v-model="formData.date" name="libelle" class="form-control" id="Date" placeholder="Date">
							
                </div>
             </div>

            <div class="col-md-2">
            	<div class="form-group">
                  <label class="form-label semibold" for="numero_piece">N° de la pièce</label>
						     	<input type="text"  v-model="formData.numero_piece" name="numero_piece" class="form-control" id="numero_piece" placeholder="N° de la pièce">
							
                </div>
            </div>
             <div class="col-md-2">
             	<div class="form-group">
                  <label class="form-label semibold" for="numero_facture">N° de la facture </label>
						     	<input type="text"  v-model="formData.numero_facture" name="numero_facture" class="form-control" id="numero_facture" placeholder="N° de la facture">
							
                </div>
             </div>
              <div class="col-md-2">
              	<div class="form-group">
                  <label class="form-label semibold" for="libelle">Référence </label>
						     	<input type="text"   v-model="edite.libelle" name="libelle" class="form-control" id="libelle" placeholder="Référence">
							
                </div>
              </div>

           
             <div class="col-md-2">
             	<div class="form-group">
                  <label class="form-label semibold" for="libelle_ecriture">Libellé écriture   </label>
						     	<input type="text" style="text-transform:uppercase" v-model="formData.libelle_ecriture"  name="libelle_ecriture" class="form-control" id="libelle_ecriture" placeholder="Libellé écriture ">
							
                </div>
             </div>
              <div class="col-md-2">
              	<div class="form-group">
                  <label class="form-label semibold" for="date_echeance">Date d'échéance  </label>
						     	<input type="date"  v-model="formData.date_echeance" name="date_echeance" class="form-control" id="date_echeance" placeholder="date echeance">
                </div>
              </div>
              
               <div class="col-md-2">
              	<div class="form-group">
                  <label class="form-label semibold" for="montant">Montant</label>
						     	<input type="text" v-if='formData.type_operation=="DEBIT"'  v-model="formData.montant_debit" name="montant" class="form-control" id="montant" placeholder="montant">
						     	<input type="text" v-if='formData.type_operation=="CREDIT"'  v-model="formData.montant_credit" name="montant" class="form-control" id="montant" placeholder="montant">
							
                </div>
              </div>
              <div class="col-md-1" style="text-align: right">
              	 <label class="form-label semibold" for="libelle" style="color:#fff">Montant</label>
                	<button type="button" v-if="!dialogEdite"  @click="gotoModale()" class="btn btn-default">Nouvau</button>
                	<button type="button" v-if="dialogEdite"  @click="gotoModale()" class="btn btn-default">Annuler</button>
              </div>
              <div class="col-md-1" style="text-align: right">
              	 <label class="form-label semibold" for="libelle" style="color:#fff">Montant</label>
                	<button v-if="!dialogEdite" type="button"  @click="enregistrement()" class="btn btn-success">Enregistrer</button>
                	<button v-if="dialogEdite" type="button"  @click="modification()" class="btn btn-success">Modifier</button>
              </div>
            </div>

							
							<div class="table-responsive">
								<table class="table table-striped">
                  
									<thead>
                    <tr>
                      <th colspan="12">
                        <div class="row">
                          
                          <div class="col-md-6">Solde en caise : {{formatageSomme(soldeCaise)}}</div>
                        <div class="col-md-6">Solde du jour : {{formatageSomme(soldeCaiseJour)}}</div>
                        </div>
                      </th>
                  </tr>
										<tr style="font-size:10px;background: #008a8a;color:#fff !important">
											<th>Date</th>
											<th>N° pièce</th>
											<th>N° facture </th>
											<th>Référence</th>
											<th>N° compte général</th>
											<th>N° compte tiers</th>
											<th>Libellé écriture </th>
											<th>Date échéance</th>
                      <th>Position journal</th>
											<th>Débit</th>
											<th>Crédit</th>
											<th>Actions</th>
										</tr>
									</thead>
									<tbody >
										<tr v-for="item in listeJournalCaise" :key="item.id">
											<td>{{item.date}}</td>
											<td>{{item.numero_piece}}</td>
											<td>{{item.numero_facture}}</td>
											<td>{{item.references}} {{item.date_enregistrement}}</td>
											<td>{{general(item.compte_general_id)}}</td>
											<td>{{tier(item.compte_tier_id)}}</td>
											<td>{{item.libelle_ecriture}}</td>
											<td>{{item.date_echeance}}</td>
											<td>{{item.possition_journal}}</td>
											<td>{{formatageSomme(item.montant_debit)}}</td>
											<td>{{formatageSomme(item.montant_credit)}}</td>
											<td>
                        <div class="btn-group btn-group-justified">
                <a class="btn btn-default" @click="editItem(item)"><i class="fas fa-edit"></i></a>
                <a style="color:red" class="btn btn-default" @click="deleteItem2(item)"><i class="fas fa-trash-alt"></i></a>
                        
                      </div>
                      </td>
										</tr>
									
									</tbody>
								</table>
							</div>
							<!-- end table-responsive -->
						</div>
						<!-- end panel-body -->
						
					</div>



<v-dialog 
        max-width="600"
         v-model="dialog"
           persistent
      >
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar
             
              dark
            >EXPORTATION </v-toolbar>
            <v-card-text>
                <br>
                
          <div class="form-group">
                         <label class="form-label semibold" for="libelle">DATE DEBUT</label>
              <input type="date"  v-model="date_debut" name="code" class="form-control" id="libelle" placeholder="Entrer la date du debut">
               
                     </div>
                   <div class="form-group">
                         <label class="form-label semibold" for="libelle">DATE FIN</label>
							<input type="date"  v-model="date_fin" name="libelle" class="form-control" id="libelle" placeholder="Entrer la date de fin">
							 
                     </div>
                     
            	
							
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="dialog.value = false"
              >Fermer</v-btn>
              <v-btn
            :disabled="loading"
           :loading="loading"
            text
      @click="tel()"
          >
            Valide
          </v-btn>
            </v-card-actions>
          </v-card>
        </template>
  </v-dialog>
     

<v-dialog
              v-model="dialogDelete"
              max-width="290"
            >
              <v-card >
                <v-card-title class="headline">
                Suppression !!
                </v-card-title>

                <v-card-text>
                  Voulez vous réellement effectuer cette action ?
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    color="green"
                    text
                    @click="dialogDelete = false"
                  >
                    Annuler
                  </v-btn>

                  <v-btn
                    color="red"
                    text
                    @click="deleteItemConfirm()"
                  >
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
        </v-dialog>

 


		</div>
		<!-- end #content -->
  </v-main>
    </v-app>
   
</template>

<script>
import { mapGetters, mapActions} from 'vuex'
    import { required, minLength } from "vuelidate/lib/validators";
import VueApexCharts from "vue-apexcharts";
import { ModelListSelect } from 'vue-search-select'
export default {
  name: 'App',

  components: {
    apexchart: VueApexCharts,
    ModelListSelect 
  },

  data(){
        return {
            
             items: [
        {
          color: 'red lighten-2',
          icon: 'mdi-star',
        },
        {
          color: 'purple darken-1',
          icon: 'mdi-book-variant',
        },
        {
          color: 'green lighten-1',
          icon: 'mdi-airballoon',
        },
        {
          color: 'indigo',
          icon: 'mdi-buffer',
        },
      ],
dialog:false,
               test:true,
               date_debut:"",
               date_fin:"",
               formData:{
            date:"",
        numero_piece:"",
        numero_facture:"",
        references:"",
        date_echeance:"",
        libelle_ecriture:"",
        possition_journal:"",
        montant_debit:0,
        montant_credit:0,
        caisse_id:"",
        compte_general_id:"",
        compte_tier_id:"",
        type_operation:"",
        user_id:""
               },
               edite:"",



     dialogEdite: false,
      dialogDelete: false,

	  series: [800, 50, 150],
          chartOptions: {
            chart: {
              width: 380,
              type: 'pie',
            },
            labels: ["Requêtes en attente d'analyse", "Requêtes en cours d'analyse",'Requêtes analysées'],
          colors:['#f59c1a', '#00acac', '#348fe2'],
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          },
          

            }
        },
        created(){
//console.log(this.getterFicheEntretien)
    this.formData.date=this.dateNowFormatFR

     console.log(this.dateNowFormatFR)
        },
		computed:{
      dateNow(){
let dateObj = new Date();
    let month = String(dateObj.getMonth() + 1).padStart(2, '0');;
    let day = String(dateObj.getDate()).padStart(2, '0');
    let year = dateObj.getFullYear();
      if(month.length==1){
        month='0'+month
      }
    //let output =  day + '-'+ month  + '-' + year;
     let output =  year + '-'+ month  + '-' + day;

    return output 
      },
      dateNowFormatFR(){
let dateObj = new Date();
    let month = String(dateObj.getMonth() + 1).padStart(2, '0');;
    let day = String(dateObj.getDate()).padStart(2, '0');
    let year = dateObj.getFullYear();
      if(month.length==1){
        month='0'+month
      }
    //let output =  day + '-'+ month  + '-' + year;
    // let output =  year + '-'+ month  + '-' + day;
     let output=day+"/"+"/"+month+"/"+year;
      
    return output 
      },
      formatageSomme(){
       return montant=>{
         if(montant){
           return (
               montant.toString()
                .split("")
                .reverse()
                .reduce((acc, montant, i) => {
                  return montant + (i && !(i % 3) ? " " : "") + acc;
                }, "") + " F CFA"
            );
         }
         return 0
       }
      },
      listeJournalCaise(){
       let vm=this
   if(vm.formData.caisse_id){
    return this.getterStructureGeographique.filter(item=>item.caisse_id==vm.formData.caisse_id)
   }
       return this.getterStructureGeographique
      },
      soldeCaiseJour(){
        let vm=this
       //formData.caisse_id

       let objet=[]
       
       objet=this.listeJournalCaise.filter(item=>{
        if((item.compte.code.startsWith("57") || item.compte.code.startsWith("55") ) && item.date==this.dateNow){
          return item
        }
      })

       
      let montant_credit=0;
      let montant_debit=0;
      let montant_total=0;
       montant_debit= objet.reduce(function (total, currentValue) {
                                return total + parseFloat(currentValue.montant_debit) ;
                            }, 0);

        montant_credit= objet.reduce(function (total, currentValue) {
                                return total + parseFloat(currentValue.montant_credit) ;
                            }, 0);

     montant_total=parseFloat(montant_debit) - parseFloat(montant_credit)
     return montant_total
      },
      soldeCaise(){
       let vm=this
       //formData.caisse_id

       let objet=[]
        objet=this.listeJournalCaise.filter(item=>{
        if((item.compte.code.startsWith("57") || item.compte.code.startsWith("55") )){
          return item
        }
      })

      
      let montant_credit=0;
      let montant_debit=0;
      let montant_total=0;
       montant_debit= objet.reduce(function (total, currentValue) {
           let montant=currentValue.montant_debit
                if(currentValue.montant_debit==null){
                  montant=0
                }
                return parseFloat(total) + parseFloat(montant) ;
            }, 0);

     
         montant_credit= objet.reduce(function (total, currentValue) {
                    let montant=currentValue.montant_credit
                if(currentValue.montant_credit==null){
                  montant=0
                }
                      return parseFloat(total) + parseFloat(montant) ;
              }, 0);


     montant_total=parseFloat(montant_debit) - parseFloat(montant_credit)
     return montant_total
      },
			general(){
       return id=>{
       	if(!id) return ""

       		let objte=this.getterStructure.find(item=>item.id==id)

       	return objte.code 
       }
			},
			tier(){
return id=>{
       	if(!id) return ""
       		let objte=this.getterCriteIndigence.find(item=>item.id==id)

       	return objte.code 
       }
			},
   listeger(){
  return this.getterStructure
   },
   listeger2(){
  return id=>{
   
    if(!id) return null
       let cptier=this.getterCriteIndigence.find(item=>item.id==id)
        console.log(cptier)
       
        let objet=  this.getterStructure.find(item=>item.id==cptier.compte_general_id)
        let vm=this
        vm.formData.compte_general_id=objet.id
      return null
   }
   },
            ...mapGetters('Application', ['getterTypeActeurJuridique', 'getterLoadingTypeActeurJuridique',"getterErrorTypeActeurJuridique","getterFicheEntretien","getterLoadingFicheEntretien","getterErrorFicheEntretien","getterActeurJuridique","getterStructure","getterCriteIndigence","getterStructureGeographique","getterLoadingStructureGeographique","getterErrorStructureGeographique","getterCodePenal"]),
            ...mapGetters('Utilisateurs', ["getterErrorUser","getterLoadingUser","getterUser","getterUserStore"]),
             formTitle () {
             return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
           },
           loading(){
               return this.getterLoadingStructureGeographique
           },

             codeUser(){
            if(!this.getterUserStore) return 0
            return this.getterUserStore.role
        },
           idActeurJuridique(){
             if(this.getterUserStore){
               return this.getterUserStore.acteur_juridique_id
             }
             return ""
           },



           idStructure(){
             if(this.getterUserStore){

               return this.getterUserStore.structure_id
             }
             return ""
           },
         
         listeActeurJuriduqe(){
           if(this.idStructure){
            return this.getterActeurJuridique.filter(item=>item.structure_id==this.getterUserStore.structure_id)
           }
           return []
         },
            nombreTotalRequete(){
              return this.getterFicheEntretien.length
			},
			nombrePasStatus(){
             return status=>{
				/* let prixMaison=1000
				 prixMaison.toLocaleString()*/
				 
				 return this.getterFicheEntretien.filter(item=>item.status==status).length
			 }
			},
			nombrePasStatusActeur(){
             return (status,acteur)=>{
			
				 return this.getterFicheEntretien.filter(item=>{
				 	if(item.status==status && item.acteur_juridique_id==acteur){
				 		return item
				 	}
				 }).length
			 }
			},
			nombreTraite(){
              return parseFloat(this.nombrePasStatus(2)) + parseFloat(this.nombrePasStatus(3))
			},
			pourcentageTraite(){
              let taux=(this.nombreTraite * 100)/this.nombreTotalRequete
			  return taux.toFixed(2)
			},
			pourcentageAUtre(){
				return status=>{
  					let taux=(this.nombrePasStatus(status) * 100)/this.nombreTotalRequete
			  		return taux.toFixed(2)
				}
            
			},
		},
        validations: {
            formData: {
               code: { required },
                libelle: { required },
            }
        },

			methods:{
             ...mapActions('Application', ['supprimerStructure','ajouterStructure',"modificationStructure","ajouterStructureGeoGraphique","getStructureGeoGraphique","modificationStructureGeoGraphique","supprimerStructureGeographique","teletest"]),
            gotoModale(){
            	this.getStructureGeoGraphique()
            	this.dialogEdite=false
              this.formData={
            date:"",
        numero_piece:"",
        numero_facture:"",
        references:"",
        date_echeance:"",
        libelle_ecriture:"",
        possition_journal:"",
        montant_debit:0,
     
        montant_credit:0,
        caisse_id:"",
        compte_general_id:"",
        compte_tier_id:"",
        type_operation:"",
        user_id:""
               } 
            }
            ,
            enregistrement () {
              this.formData.libelle_ecriture=this.formData.libelle_ecriture.toUpperCase();
              console.log(this.formData.libelle_ecriture)
                this.ajouterStructureGeoGraphique(this.formData)
                if(this.getterErrorStructureGeographique==false){
                   // this.$v.formData.$reset();
                  this.formData={
                      date:"",
                      numero_piece:"",
                      numero_facture:"",
                      references:"",
                      date_echeance:"",
                      libelle_ecriture:"",
                      possition_journal:"",
                      montant_debit:0,
                      montant_credit:0,
                      caisse_id:"",
                      compte_general_id:"",
                      compte_tier_id:"",
                      type_operation:"",
                      user_id:""
               }
                }
               

            },
              

modification(){
this.modificationStructureGeoGraphique(this.formData)
this.dialogEdite=false
this.formData={
            date:"",
        numero_piece:"",
        numero_facture:"",
        references:"",
        date_echeance:"",
        libelle_ecriture:"",
        possition_journal:"",
        montant_debit:"",
        montant_debit:"",
        montant_credit:"",
        caisse_id:"",
        compte_general_id:"",
        compte_tier_id:"",
        type_operation:"",
        user_id:""
               }
},

      editItem(item) {
       this.formData=item
        this.dialogEdite = true
      },

      deleteItem2(item) {
      this.edite=item
      console.log(this.edite)
     console.log("................")
      this.dialogDelete=true
      },

      deleteItemConfirm(){
         this.dialogDelete=false
        
        this.supprimerStructureGeographique(this.edite.id)
      },
      tele(){
      this.dialog=true
      },
     
     tel(){
      let b={
        date_debut:this.date_debut,
        date_fin:this.date_fin
      }
        this.teletest(b)
     },

        
           getStatus () {
             return status=>{
        if (status == 0) return 'EN ATTENTES'
         if (status ==1) return 'EN-COURS'
         if (status ==2) return 'ACCEPTER'
         return 'REJETTER'
             }
      
      },
	  },
};
</script>

<style scoped>
table ,tr td{
    border:1px solid #008a8a
}
tbody {
    display:block;
    height:500px;
    overflow:auto;
}
thead, tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;
}
thead {
    width: calc( 100% - 1em )
}
table {
    
    width:100%;
}
</style>