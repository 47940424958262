
<template>
<v-app>
    <notifications/>
    <v-main>
    
    <div id="content" class="content">
			<!-- begin breadcrumb -->
			<ol class="breadcrumb pull-right">
				<li class="breadcrumb-item"><a href="javascript:;">Accueil</a></li>
				<li class="breadcrumb-item"><a href="javascript:;">Configuration</a></li>
				<li class="breadcrumb-item active">Grille Commissions </li>
			</ol>
			<!-- end breadcrumb -->
			<!-- begin page-header -->
			<h1 class="page-header">Gestion <small> des grille commissions</small></h1>
			<!-- end page-header -->
			<!-- begin panel -->
			<div class="panel panel-inverse">
				<div class="panel-heading">
					<div class="panel-heading-btn">
						<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-default" data-click="panel-expand"><i class="fa fa-expand"></i></a>
						<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-success" data-click="panel-reload"><i class="fa fa-redo"></i></a>
						<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
					</div>
					<h4 class="panel-title">Liste des grille commissions </h4>
				</div>
				<div class="panel-body">
          <div class="row">
             <div class="col-md-5"></div>
              <div class="col-md-7" style="text-align: right">
                	<button type="button"  @click="gotoModale()" class="btn btn-inverse">NOUVEAU</button>
              </div>
          </div>
		
 <div>
   
   <v-data-table
    :headers="headers"
    :items="getterGrilleCommissions"
    sort-by="code"
    class="elevation-1"
  >
  <template v-slot:item.transaction_jour_min="{item}">
                          <div>
                            {{formatageSomme(item.transaction_jour_min)}}
                          </div>
                        </template>
                        <template v-slot:item.transaction_jour_max="{item}">
                          <div>
                            {{formatageSomme(item.transaction_jour_max)}}
                          </div>
                        </template>
                        <template v-slot:item.commission_jour_grossiste="{item}">
                          <div>
                            {{formatageSomme(item.commission_jour_grossiste)}}
                          </div>
                        </template>
                        <template v-slot:item.commission_jour_detaillant="{item}">
                          <div>
                            {{formatageSomme(item.commission_jour_detaillant)}}
                          </div>
                        </template>
                        <template v-slot:item.commission_jour_kiost="{item}">
                          <div>
                            {{formatageSomme(item.commission_jour_kiost)}}
                          </div>
                        </template>
    <template v-slot:item.actions="{ item }">
      


<div class="btn-group btn-group-justified">
  <a class="btn btn-default" @click="editItem(item)"><i class="fas fa-edit"></i></a>
  <a style="color:red" class="btn btn-default" @click="deleteItem(item)"><i class="fas fa-trash-alt"></i></a>
  
</div>

    </template>
  </v-data-table>

  </div>
				</div>
			</div>
		


<v-dialog 
        max-width="600"
         v-model="dialog"
           persistent
      >
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar
             
              dark
            >Enregistrement</v-toolbar>
            <v-card-text>
                <br>
                

                   <div class="form-group">
                         <label class="form-label semibold" for="transaction_jour_min">Transactions journalières MIN</label>
							<input type="text" :class="{ 'hasErroInpu': $v.formData.transaction_jour_min.$error }" v-model="formData.transaction_jour_min" name="transaction_jour_min" class="form-control" id="transaction_jour_min" placeholder="Transactions journalières MIN">
							 <div style="color: red;" class="" v-if="$v.formData.transaction_jour_min.$error">
                                veuillez entrer la Transactions journalières MIN
                            </div>
                     </div>

                     <div class="form-group">
                         <label class="form-label semibold" for="transaction_jour_max">Transactions journalières MAX</label>
              <input type="text" :class="{ 'hasErroInpu': $v.formData.transaction_jour_max.$error }" v-model="formData.transaction_jour_max" name="transaction_jour_max" class="form-control" id="transaction_jour_max" placeholder="Transactions journalières MAX">
               <div style="color: red;" class="" v-if="$v.formData.transaction_jour_max.$error">
                                veuillez entrer la Transactions journalières MAX
                            </div>
                     </div>

                     <div class="form-group">
                         <label class="form-label semibold" for="commission_jour_grossiste">Commission TTC journalières Grossiste</label>
              <input type="text" :class="{ 'hasErroInpu': $v.formData.commission_jour_grossiste.$error }" v-model="formData.commission_jour_grossiste" name="commission_jour_grossiste" class="form-control" id="commission_jour_grossiste" placeholder="Entrer la Commission TTC journalières Grossiste">
               <div style="color: red;" class="" v-if="$v.formData.commission_jour_grossiste.$error">
                                veuillez entrer la Commission TTC journalières Grossiste
                            </div>
                     </div>

                     <div class="form-group">
                         <label class="form-label semibold" for="commission_jour_detaillant">Commission TTC journalières Détaillant</label>
              <input type="text" :class="{ 'hasErroInpu': $v.formData.commission_jour_detaillant.$error }" v-model="formData.commission_jour_detaillant" name="commission_jour_detaillant" class="form-control" id="commission_jour_detaillant" placeholder="Entrer la Commission TTC journalières Détaillant">
               <div style="color: red;" class="" v-if="$v.formData.commission_jour_detaillant.$error">
                                veuillez entrer la Commission TTC journalières Détaillant
                            </div>
                     </div>

                     <div class="form-group">
                         <label class="form-label semibold" for="commission_jour_kiost">Commission TTC journalières Kiosque</label>
              <input type="text" :class="{ 'hasErroInpu': $v.formData.commission_jour_kiost.$error }" v-model="formData.commission_jour_kiost" name="commission_jour_kiost" class="form-control" id="commission_jour_kiost" placeholder="Entrer la Commission TTC journalières Kiosque">
               <div style="color: red;" class="" v-if="$v.formData.commission_jour_kiost.$error">
                                veuillez entrer la Commission TTC journalières Kiosque
                            </div>
                     </div>
            	
							
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="dialog.value = false"
              >Fermer</v-btn>
              <v-btn
            :disabled="loading"
           :loading="loading"
            text
      @click="enregistrement()"
          >
            Enregistrer
          </v-btn>
            </v-card-actions>
          </v-card>
        </template>
  </v-dialog>
     



<!--DIALOG LOADING--->
        <v-dialog
              v-model="loading"
              persistent
              width="250"
              height="200"
            >
              <v-card
                color="#2d353c"
                dark
                class="text-center"
              >
                <v-card-text  class="white--text">
                  Requête en cours de traitement<br>
                  <v-progress-circular
                    indeterminate
                    color="white"
                    class="mb-0"
                    size="50"
                  ></v-progress-circular>
                </v-card-text>
              </v-card>
        </v-dialog>

    <!---DIALOGUE DE SUPPRESION-->

         <v-dialog
              v-model="dialogDelete"
              max-width="290"
            >
              <v-card >
                <v-card-title class="headline">
                Suppression !!
                </v-card-title>

                <v-card-text>
                  Voulez vous réellement effectuer cette action
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    color="green"
                    text
                    @click="dialogDelete = false"
                  >
                    Annuler
                  </v-btn>

                  <v-btn
                    color="red"
                    text
                    @click="deleteItemConfirm(edite)"
                  >
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
        </v-dialog>



         <v-dialog
        max-width="600"
         v-model="dialogEdite"
      >
        <template v-slot:default="dialogEdite">
          <v-card>
            <v-toolbar
              dark
            >Modification </v-toolbar>
            <v-card-text>
                <br>
                

                
                   <div class="form-group">
                         <label class="form-label semibold" for="transaction_jour_min">Transactions journalières MIN</label>
              <input type="text"  v-model="edite.transaction_jour_min" name="transaction_jour_min" class="form-control" id="transaction_jour_min" placeholder="Transactions journalières MIN">
               
                     </div>

                     <div class="form-group">
                         <label class="form-label semibold" for="transaction_jour_max">Transactions journalières MAX</label>
              <input type="text"  v-model="edite.transaction_jour_max" name="transaction_jour_max" class="form-control" id="transaction_jour_max" placeholder="Transactions journalières MAX">
              
                     </div>

                     <div class="form-group">
                         <label class="form-label semibold" for="commission_jour_grossiste">Commission TTC journalières Grossiste</label>
              <input type="text" v-model="edite.commission_jour_grossiste" name="commission_jour_grossiste" class="form-control" id="commission_jour_grossiste" placeholder="Entrer la Commission TTC journalières Grossiste">
               
                     </div>

                     <div class="form-group">
                         <label class="form-label semibold" for="commission_jour_detaillant">Commission TTC journalières Détaillant</label>
              <input type="text"  v-model="edite.commission_jour_detaillant" name="commission_jour_detaillant" class="form-control" id="commission_jour_detaillant" placeholder="Entrer la Commission TTC journalières Détaillant">
              
                     </div>

                     <div class="form-group">
                         <label class="form-label semibold" for="commission_jour_kiost">Commission TTC journalières Kiosque</label>
              <input type="text" v-model="edite.commission_jour_kiost" name="commission_jour_kiost" class="form-control" id="commission_jour_kiost" placeholder="Entrer la Commission TTC journalières Kiosque">
              
                     </div>
              
							
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="dialogEdite.value = false"
              >Fermer</v-btn>
          <v-btn
            :disabled="loading"
            :loading="loading"
              text
            @click="modification(edite)"
          >
            Modifier
          </v-btn>
            </v-card-actions>
          </v-card>
        </template>
  </v-dialog>
		</div>
    </v-main>
    </v-app>
</template>



<script>
import { mapGetters, mapActions} from 'vuex'
    import { required, minLength } from "vuelidate/lib/validators";
   
    export default {
          name: "GrilleCommission",
    components: {
     
    },
        data() {
            return {
                
    
               dialog:false,
               test:true,
               formData:{
                   transaction_jour_min:"",
                  transaction_jour_max:"",
                  commission_jour_grossiste:"",
                  commission_jour_detaillant:"",
                  commission_jour_kiost:"",
               },
               edite:"",



     dialogEdite: false,
      dialogDelete: false,
      headers: [
        { text: 'Transactions journalières MIN', value: 'transaction_jour_min' },
        { text: 'Transactions journalières MAX', value: 'transaction_jour_max' },
        { text: 'Commission TTC journalières Grossiste', value: 'commission_jour_grossiste' },
        { text: 'Commission TTC journalières Détaillant', value: 'commission_jour_detaillant' },
        { text: 'Commission TTC journalières Kiosque', value: 'commission_jour_kiost' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
      
        libelle: "",
      },
      defaultItem: {
       code: '',
        libelle: "",
      },



            }
        },
        created(){
 
        },
        computed:{
            ...mapGetters('Application', ['getterGrilleCommissions', 'getterLoadingGrilleCommissions',"getterErrorGrilleCommissions"]),
              formTitle () {
             return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
           },
            formatageSomme(){
       return montant=>{
         if(montant==0) return ""
         if(montant){
           return (
               montant.toString()
                .split("")
                .reverse()
                .reduce((acc, montant, i) => {
                  return montant + (i && !(i % 3) ? " " : "") + acc;
                }, "") + " "
            );
         }
         return ""
       }
      },
           loading(){
               return this.getterLoadingGrilleCommissions
           }
        },
        validations: {
            formData: {
               transaction_jour_min: { required },
                  transaction_jour_max: { required },
                  commission_jour_grossiste: { required },
                  commission_jour_detaillant: { required },
                  commission_jour_kiost: { required },
             
            }
        },
        methods:{
             ...mapActions('Application', ['getGrilleCommissions',"ajouterGrilleCommissions",
             "supprimerGrilleCommissions","modificationGrilleCommissions"]),
            gotoModale(){
              
               this.dialog=true 
            }
            ,
            enregistrement () {

                this.$v.formData.$touch();
                if(this.$v.formData.$error) {

                    return
                }
                
                
                this.ajouterGrilleCommissions(this.formData)
                if(!this.getterErrorGrilleCommissions){
                    this.$v.formData.$reset();
                  this.formData={
                         transaction_jour_min:"",
                  transaction_jour_max:"",
                  commission_jour_grossiste:"",
                  commission_jour_detaillant:"",
                  commission_jour_kiost:"",
                     }
                }
               

            },
              

    modification(item){
       this.modificationGrilleCommissions(item)
    },
      editItem (item) {
       this.edite=item
        this.dialogEdite = true
      },

      deleteItem (item) {
      this.edite=item
      console.log(this.edite)
      this.dialogDelete=true
      },

      deleteItemConfirm (item) {
         this.dialogDelete=false
        this.supprimerGrilleCommissions(item.id)
      },
      
 
  
    

      
        },
        watch: {
      
     },
    }
</script>

<style scoped>
.input {
        background-color: #f7f7f7;
        border: 1px solid #c7c7c7;
        padding: 0.3rem 0.5rem;
        border-radius: 2px;
    }
    .hasError .input {
        border: red;
    }
    .hasErroInpu{
        border: 1px red solid;
    }
    .hasError label {
        color: red;
    }
    .icon:hover {
	 cursor: pointer;
}
 .muted {
	 color: gray;
	 font-size: 80%;
}
</style>



<style>
  .vtl .vtl-drag-disabled {
	 background-color: #d0cfcf;
}
 .vtl .vtl-drag-disabled:hover {
	 background-color: #d0cfcf;
}
 .vtl .vtl-disabled {
	 background-color: #d0cfcf;
}
 
 
</style> 
 
