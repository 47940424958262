
<template>
<v-app>
    <notifications/>
    <v-main>
    
    <div id="content" class="content">
			<!-- begin breadcrumb -->
			
			<!-- end breadcrumb -->
			<!-- begin page-header -->
			<h1 class="page-header">Verification des objectifs</h1>
			<!-- end page-header -->
			<!-- begin panel -->
			<div class="panel panel-inverse">
				
				<div class="panel-body">
         
		
 <div>

<div class="col-lg-12 ui-sortable">
    <div class="panel panel-success" data-sortable-id="ui-widget-1" v-for='(item,index) in groupBy(getterObjectifPeriode,"annee")' :key="">
    <div class="panel panel-success" data-sortable-id="ui-widget-1" v-for='(item,index) in groupBy(getterObjectifPeriode,"annee")' :key="">
  <div class="panel-heading">
    <div class="panel-heading-btn">
      
    </div>
    <h4 class="panel-title">{{index}}</h4>
  </div>
  <div class="panel-body">
    <div class="row">
        <div class="col-md-4" v-for="(row,i) in groupBy(dataByMoi(index),'moi_fr')">
            

         <div class="panel panel-default" data-sortable-id="ui-widget-10" style="">
                        <div class="panel-heading ui-sortable-handle">
                            <h4 class="panel-title">{{i}}</h4>
                        </div>
                        <div class="panel-body">
                           
<div class="table-responsive">
                                <table class="table table-hover m-b-0 text-inverse">
                                    <thead>
                                        <tr>
                                            <th>Date début</th>
                                            <th>Date fin</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                            <tr v-for="value in dataByMoiAnnee(index,i)" :key="value.id" @click="goToViewDetail(value.id)" style="cursor: grab;">
                                           
                                <td>{{ formatDate(value.date_debut)}}</td>
                                <td>{{ formatDate(value.date_fin)}}</td>
                            </tr>
                                       
                                    </tbody>
                                </table>
                            </div>


                        </div>
                       
                    </div>


        </div>
    </div>
    
  </div>
</div>


                  
                </div>
            </div>
            <!-- end row -->
					
</div>




   
  

  </div>
				</div>
			</div>
		
		</div>

        <v-dialog
              v-model="loading_activite"
              persistent
              width="250"
              height="200"
            >
              <v-card
                color="#2d353c"
                dark
                class="text-center"
              >
                <v-card-text  class="white--text">
                  Requête en cours de traitement<br>
                  <v-progress-circular
                    indeterminate
                    color="white"
                    class="mb-0"
                    size="50"
                  ></v-progress-circular>
                </v-card-text>
              </v-card>
        </v-dialog>






   <v-dialog
        max-width="500"
         v-model="dialogEdite"
      >
        <template v-slot:default="dialogEdite">
          <v-card>
            <v-toolbar
              dark
            >Comfirmation de la periode</v-toolbar>
            <v-card-text>
                <br>
                 <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                         <label class="form-label semibold" for="code">Comfirmation de la date debut </label>
                        <input type="date" @change="comparaisonDate" v-model="date_debut_confirmation" name="code" class="form-control" id="code" placeholder="Code">
                         <span v-if="bool_compare" style="color:red">La date de comfirmation ne corresponde pas !!</span>
                     </div>


                      <div class="form-group">
                         <label class="form-label semibold" for="code">Comfirmation de la date de fin </label>
                        <input type="date" @change="comparaisonDateFin" v-model="date_fin_confirmation" name="code" class="form-control" id="code" placeholder="Code">
                         <span v-if="bool_compare2" style="color:red">La date de comfirmation ne corresponde pas !!</span>
                     </div>

                    </div>
                   
                    
                </div>
                   
                     
                 
            
                     <!-- <div class="form-group">
                         <label class="form-label semibold">Rôle </label>
                            <model-list-select class="form-control" option-value="id"
                                    option-text="libelle" v-model="edite.role_id" :list="Liste_role"
                                                placeholder="Selectionner Acteur juridique">
                        </model-list-select>
                 </div> -->

            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="dialogEdite.value = false"
              >Fermer</v-btn>
          <v-btn

            :disabled="bool_desible_button"
            :loading="loading_activite"
              text
            @click="enregistrement()"
          >
            Validation
          </v-btn>
            </v-card-actions>
          </v-card>
        </template>
  </v-dialog>



         <v-dialog
              v-model="dialogDelete"
              max-width="290"
            >
              <v-card >
                <v-card-title class="headline">
                Erreur !!
                </v-card-title>

                <v-card-text>
                  Une erreur c'est produit lors de l'importation
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  
                  <v-btn
                    color="red"
                    text
                    @click="deleteItemConfirm(edite)"
                  >
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
        </v-dialog>

    </v-main>
    </v-app>
</template>



<script>
import { mapGetters, mapActions} from 'vuex'
    import { required, minLength ,email } from "vuelidate/lib/validators";
  import { ModelListSelect } from 'vue-search-select'
  import UploadExcelComponent from '@/components/UploadExcel/index.vue'
     import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
          name: "Utilisateur",
    components: {
     ModelListSelect,
     UploadExcelComponent,
     Loading
    },
        data() {
            return {
               dialog:false,
               test:true,
               date_commision:"",
               loading_activite:false,
                tableData: [],
                tableHeader: [],
                namePDFDemandeAno: "",
                fichierPDFDemandeAno: "",
                imagePDFDemandeAno:"",
                date_debut:"",
                date_debut_confirmation:"",
               date_fin:"",
               date_fin_confirmation:"",
               formData:{
                   name:"",
                   email:"",
                   telephone:"",
                   role:"",
                   numero_apv2:"",
                   contact_personnel:"",
                   localite_id:"",
                   
               },
               edite:"",
       dialogEdite: false,
       dialogDelete: false,
       bool_compare:false,
       bool_compare2:false,
       bool_desible_button:true,

       comfirmation_date_activite:"",
       urlBase:process.env.VUE_APP_BASE,
      headers: [
        { text: 'Code', value: 'code' },
        { text: 'Date début', value: 'date_debut' },
        { text: 'Date fin', value: 'date_fin' },
        { text: 'Montant cashin', value: 'montant_cashin' },
        { text: 'Montant cashout', value: 'montant_cashout' },
        { text: 'Moi', value: 'moi_fr' },
        { text: 'Annee', value: 'annee' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      Liste_role:[
        {id:"APV2",libelle:"APV2"},
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
      
        libelle: "",
      },
      defaultItem: {
       code: '',
        libelle: "",
      },



            }
        },
        created(){
 
        },
        computed:{

        groupBy (){
         return (arr, key)=>{
              const initialValue = {};
            return arr.reduce((acc, cval) => {
                const myAttribute = cval[key];
                acc[myAttribute] = [...(acc[myAttribute] || []), cval]
                return acc;
            }, initialValue);
         }
            },

 formatDate() {
        return date=>{
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [day, month, year].join('-');
        }
      },
            ...mapGetters('Application', ['getterStructure','getterLocalisationGeographique', 'getterActeurJuridique',"getterLoadingActeurJurique","getterErrorActeurJuridique","getterTypeActeurJuridique"]),
             
              ...mapGetters('GestionCommission', ['getterObjectifPeriode','getterLoadingObjectifPeriode', 'getterErrorObjectifPeriode']),
             


  

              ...mapGetters('Utilisateurs', ["getterErrorUser","getterLoadingUser","getterUser"]),

              dataByMoi(){
              return annee=>{
                if(annee=="") return []
                    let objet=this.getterObjectifPeriode.filter(item=>item.annee==annee)
                  return objet;
              }
              },

               dataByMoiAnnee(){
              return (annee,i)=>{
                if(annee=="" && i=="") return []
                    let objet=this.getterObjectifPeriode.filter(item=>item.annee==annee && item.moi_fr==i)
                  return objet;
              }
              },
              
              detaillantListe(){
            return this.getterUser.filter(item=>item.role=="APV2")
              },
            dateNow(){
let dateObj = new Date();
    let month = String(dateObj.getMonth() + 1).padStart(2, '0');;
    let day = String(dateObj.getDate()).padStart(2, '0');
    let year = dateObj.getFullYear();
      if(month.length==1){
        month='0'+month
      }
    //let output =  day + '-'+ month  + '-' + year;
     let output =  year + '-'+ month  + '-' + day;

    return output 
      },
            dateNowFormatFR(){
let dateObj = new Date();
    let month = String(dateObj.getMonth() + 1).padStart(2, '0');;
    let day = String(dateObj.getDate()).padStart(2, '0');
    let year = dateObj.getFullYear();
      if(month.length==1){
        month='0'+month
      }
    //let output =  day + '-'+ month  + '-' + year;
    // let output =  year + '-'+ month  + '-' + day;
     let output=day+"/"+"/"+month+"/"+year;
      
    return output 
      },
           formatageSomme(){
       return montant=>{
         if(montant==0) return ""
         if(montant){
           return (
               montant.toString()
                .split("")
                .reverse()
                .reduce((acc, montant, i) => {
                  return montant + (i && !(i % 3) ? " " : "") + acc;
                }, "") + " "
            );
         }
         return ""
       }
      },

        },
        validations: {
            formData: {
                     name:{ required },
                    email:{ required ,email },
                   telephone:{ required },
                   password:{ required },
                   role:{ required },
                   numero_pdv:{ required },
                   contact_personnel:{ required },
                   localite_id:""    
            }
        },
        methods:{
             ...mapActions('Application', ['getActeurJuridique',"ajouterActeurJuridique",
             "supprimerActeurJuridique","modificationActeurJuridique","importationLocalisation"]),


...mapActions('GestionCommission', ['getSyntheseActivite',"ajouterSyntheseActivite","supprimerSyntheseActivite","modificationSyntheseActivite","ajouterObjectif"]),

             ...mapActions('Utilisateurs', ['getUsers',"ajouterUser",
             "supprimerUser","modificationUser"]),

             goToViewDetail(data){
              this.$router.push({name:'DetailObejectifPeriode',params:{id:data}})
             
            },
             comparaisonDate(){

                let vm=this;
               if(vm.date_debut_confirmation){

                  if(vm.date_debut_confirmation!=vm.date_debut) {
                    vm.bool_compare=true
                     vm.bool_desible_button=true
                    return null
                  }else{
                     vm.bool_desible_button=false
                  }
               }
              vm.bool_compare=false
             
              },

comparaisonDateFin(){

                let vm=this;
               if(vm.date_fin_confirmation){

                  if(vm.date_fin_confirmation!=vm.date_fin) {
                    vm.bool_compare2=true
                     vm.bool_desible_button=true
                    return null
                  }else{
                     vm.bool_desible_button=false
                  }
               }
              vm.bool_compare2=false
             
              },
addFichier(file) {
                let reader = new FileReader();
                let vm = this;
                reader.onload = e => {
                    vm.imagePDFDemandeAno = "pdf.png";
                    vm.namePDFDemandeAno = file.name;
                    vm.fichierPDFDemandeAno = e.target.result;
                };
                reader.readAsDataURL(file);
            }
            ,
             OnchangeFichier1(file) {
                const files = file;
                this.selectedFile = file;
                console.log(this.selectedFile)
                Array.from(files).forEach(file => this.addFichier(file));
            },
            beforeUpload(file) {
                const isLt1M = file.size / 1024 / 1024 < 1
                this.OnchangeFichier1(file)
                if (isLt1M) {
                    return true
                }

               /* this.$message({
                    message: 'Please do not upload files larger than 1m in size.',
                    type: 'warning'
                })*/
                return false
            },
            handleSuccess({ results, header }) {
               // console.log(results[0]["GRANDE NATURE DE DEPENSE"])
                this.tableData = results
                this.tableHeader = header

                console.log(this.tableHeader)
                
               // this.importationLocalisation(objet)
                this.dialogEdite=true
                this.bool_compare=false
                this.bool_desible_button=true
                this.comfirmation_date_activite=""
            },
            gotoModale(){
              
               this.dialog=true 
            }
            ,
            enregistrement () {
               
                this.loading_activite=true
                this.dialogEdite=false

             let objet={
                    data:this.tableData,
                    header:this.tableHeader,
                    date_fin:this.date_fin,
                    date_debut:this.date_debut
                }

                this.ajouterObjectif(objet).then((res)=>{
                    console.log(res)
                    this.tableData=[]
                    this.tableHeader=[]
                    this.date_commision=""
                    this.loading_activite=false  
                }).catch(error =>{
                    console.log(error)
                      this.loading_activite=false 
                })

               /* if(this.getterErrorSyntheseActivite==false){
                  
                }else{
                    this.loading_activite=false
                }*/

            },
              

modification(item){
  
this.modificationUser(item)
},
      editItem (item) {
       this.edite=item
        this.dialogEdite = true
      },

      deleteItem (item) {
      this.edite=item
      console.log(this.edite)
      this.dialogDelete=true
      },

      deleteItemConfirm (item) {
         this.dialogDelete=false
        this.supprimerUser(item.id)
      },
      
 
  
    

      
        },
        watch: {
      
     },
    }
</script>

<style scoped>
.input {
        background-color: #f7f7f7;
        border: 1px solid #c7c7c7;
        padding: 0.3rem 0.5rem;
        border-radius: 2px;
    }
    .hasError .input {
        border: red;
    }
    .hasErroInpu{
        border: 1px red solid;
    }
    .hasError label {
        color: red;
    }
    .icon:hover {
	 cursor: pointer;
}
 .muted {
	 color: gray;
	 font-size: 80%;
}
</style>



<style>
  .vtl .vtl-drag-disabled {
	 background-color: #d0cfcf;
}
 .vtl .vtl-drag-disabled:hover {
	 background-color: #d0cfcf;
}
 .vtl .vtl-disabled {
	 background-color: #d0cfcf;
}
 
 
</style> 
 
