
<template>
<v-app>
    <notifications/>
    <v-main>
    
    <div id="content" class="content">
			<!-- begin breadcrumb -->
			<ol class="breadcrumb pull-right">
				<li class="breadcrumb-item"><a href="javascript:;">Accueil</a></li>
				<li class="breadcrumb-item"><a href="javascript:;">Gestion Access</a></li>
				<li class="breadcrumb-item active">Gestion des utilisateurs</li>
			</ol>
			<!-- end breadcrumb -->
			<!-- begin page-header -->
			<h1 class="page-header">Gestion <small> des détaillants</small></h1>
			<!-- end page-header -->
			<!-- begin panel -->
			<div class="panel panel-inverse">
				<div class="panel-heading">
					<div class="panel-heading-btn">
						<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-default" data-click="panel-expand"><i class="fa fa-expand"></i></a>
						<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-success" data-click="panel-reload"><i class="fa fa-redo"></i></a>
						<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
					</div>
					<h4 class="panel-title">Liste des détaillants </h4>
				</div>
				<div class="panel-body">
          <div class="row">
             <div class="col-md-5"></div>
              <div class="col-md-7" style="text-align: right">
                	<button type="button"  @click="gotoModale()" class="btn btn-inverse">NOUVEAU</button>
              </div>
          </div>
		
 <div>

<div class="col-lg-12 ui-sortable">
				<h3 class="m-t-10">Tous les détaillants</h3>
                    <v-data-table
                      :headers="headers"
                      :items="detaillantListe"
                      sort-by="code"
                      class="elevation-1"
                        >
                        <template v-slot:item.role_id="{item}">
                          <div>
                            {{libelle_role(item.role_id)}}
                          </div>
                        </template>

                        
                        <template v-slot:item.profile_photo_path="{ item }">
                          
                            <div class="image">
                                <img :src="imageUsers(item.profile_photo_path,item.id)" style="width:50px;height:50px;" alt="" />
                            </div>
                        </template>
                        
                    <template v-slot:item.actions="{ item }">
                      

                        <div class="btn-group btn-group-justified">
                          <a class="btn btn-default" @click="editItem(item)"><i class="fas fa-edit"></i></a>
                          <a style="color:red" class="btn btn-default" @click="deleteItem(item)"><i class="fas fa-trash-alt"></i></a>
                          
                        </div>

                    </template>
                </v-data-table>
					
				</div>




   
  

  </div>
				</div>
			</div>
		


<v-dialog 
        max-width="750"  
         v-model="dialog"
           persistent
      >
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar
             
              dark
            >Enregistrement des détaillants</v-toolbar>
            <v-card-text>
                <br>
               
                  <div class="form-group">
                         <label class="form-label semibold">Rôle </label>
                            <model-list-select class="form-control" :class="{ 'hasErroInpu': $v.formData.role.$error }" option-value="id"
                                    option-text="libelle" v-model="formData.role" :list="Liste_role"
                                                placeholder="Sélectionner le role">
                        </model-list-select>
							         <div style="color: red;" class="" v-if="$v.formData.role.$error">
                                veuillez choisir le rôle
                       </div>
                 </div>

                 

                <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                         <label class="form-label semibold" for="code">Nom et prénom(s) </label>
                        <input type="text" :class="{ 'hasErroInpu': $v.formData.name.$error }" v-model="formData.name" name="code" class="form-control" id="code" placeholder="Entrez le nom et le(s) prénom(s)">
                      <div style="color: red;" class="" v-if="$v.formData.name.$error">
                                      veuillez entrer le nom et prénom(s)
                        </div>
                     </div>
                    </div>
                    <div class="col-md-6">
                      
                   <div class="form-group">
                         <label class="form-label semibold" for="libelle">Email</label>
						     	<input type="text" :class="{ 'hasErroInpu': $v.formData.email.$error }" v-model="formData.email" name="libelle" class="form-control" id="libelle" placeholder="Libelle">
							    <div style="color: red;" class="" v-if="$v.formData.email.$error">
                                veuillez entrer l'email
                            </div>
                     </div>
                    </div>
                    
                </div>

                  <div class="form-group">
                         <label class="form-label semibold" for="telephone">Numéro REV ou tête de pont</label>
						     	<input type="text" :class="{ 'hasErroInpu': $v.formData.telephone.$error }" v-model="formData.telephone" name="telephone" class="form-control" id="telephone" placeholder="Entrer le téléphone">
							    <div style="color: red;" class="" v-if="$v.formData.telephone.$error">
                                veuillez entrer le numéro REV
                            </div>
                     </div>
                       <div class="form-group">
                         <label class="form-label semibold" for="numero_pdv">
                         Numéro PDV</label>
						     	<input type="text" :class="{ 'hasErroInpu': $v.formData.numero_pdv.$error }" v-model="formData.numero_pdv" name="numero_pdv" class="form-control" id="numero_pdv" placeholder="Entrer le numero pdv">
							    <div style="color: red;" class="" v-if="$v.formData.numero_pdv.$error">
                                veuillez entrer le numéro PDV
                            </div>
                     </div>
                      <div class="form-group">
                         <label class="form-label semibold" for="contact_personnel">Contact personnel</label>
						     	<input type="text" :class="{ 'hasErroInpu': $v.formData.contact_personnel.$error }" v-model="formData.contact_personnel" name="contact_personnel" class="form-control" id="contact_personnel" placeholder="Entrer contact personnel">
							    <div style="color: red;" class="" v-if="$v.formData.contact_personnel.$error">
                                veuillez entrer le contact personnel
                            </div>
                     </div>
             
                 
              <div class="form-group">
                  <label class="form-label semibold">Localite</label>
                  <select
                    class="form-control"
                    v-model="formData.localite_id"
                  >
                    <option></option>
                    <option
                      v-for="item in getterLocalisationGeographique"
                      :key="'+' + item.id"
                      :value="item.id"
                    >
                      {{ item.libelle }}
                    </option>
                  </select>

                </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="dialog.value = false"
              >Fermer</v-btn>
              <v-btn
            :disabled="loading"
           :loading="loading"
            text
      @click="enregistrement()"
          >
            Enregistrer
          </v-btn>
            </v-card-actions>
          </v-card>
        </template>
  </v-dialog>
     



<!--DIALOG LOADING--->
        <v-dialog
              v-model="loading"
              persistent
              width="250"
              height="200"
            >
              <v-card
                color="#2d353c"
                dark
                class="text-center"
              >
                <v-card-text  class="white--text">
                  Requête en cours de traitement<br>
                  <v-progress-circular
                    indeterminate
                    color="white"
                    class="mb-0"
                    size="50"
                  ></v-progress-circular>
                </v-card-text>
              </v-card>
        </v-dialog>

    <!---DIALOGUE DE SUPPRESION-->

         <v-dialog
              v-model="dialogDelete"
              max-width="290"
            >
              <v-card >
                <v-card-title class="headline">
                Suppression !!
                </v-card-title>

                <v-card-text>
                  Voulez vous réellement effectuer cette action
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    color="green"
                    text
                    @click="dialogDelete = false"
                  >
                    Annuler
                  </v-btn>

                  <v-btn
                    color="red"
                    text
                    @click="deleteItemConfirm(edite)"
                  >
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
        </v-dialog>



         <v-dialog
        max-width="600"
         v-model="dialogEdite"
      >
        <template v-slot:default="dialogEdite">
          <v-card>
            <v-toolbar
              dark
            >Modification des utilisateurs</v-toolbar>
            <v-card-text>
                <br>
                 <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                         <label class="form-label semibold" for="code">Nom et prénom(s) </label>
                        <input type="text"  v-model="edite.name" name="code" class="form-control" id="code" placeholder="Code">
                     </div>
                    </div>
                    <div class="col-md-6">
                      
                   <div class="form-group">
                         <label class="form-label semibold" for="libelle">Email</label>
						     	<input type="text"  v-model="edite.email" name="libelle" class="form-control" id="libelle" placeholder="Libelle">
							    
                     </div>
                    </div>
                    
                </div>

                  <div class="form-group">
                         <label class="form-label semibold" for="telephone">Numéro REV ou tête de pont</label>
						       	<input type="text"  v-model="edite.telephone" name="telephone" class="form-control" id="telephone" placeholder="téléphone">
							    
                  </div>
                   <div class="form-group">
                         <label class="form-label semibold" for="numero_pdv">Numéro PDV</label>
						       	<input type="text"  v-model="edite.numero_pdv" name="numero_pdv" class="form-control" id="telephone" placeholder="téléphone">
							    
                  </div>
                        <div class="form-group">
                         <label class="form-label semibold" for="contact_personnel">Contact personnel</label>
						       	<input type="text"  v-model="edite.contact_personnel" name="contact_personnel" class="form-control" id="contact_personnel" placeholder="Contact personnel">
							    
                  </div>
            
                 
                 
              <div class="form-group">
                  <label class="form-label semibold">Localite</label>
                  <select
                    class="form-control"
                    v-model="edite.localite_id"
                  >
                    <option></option>
                    <option
                      v-for="item in getterLocalisationGeographique"
                      :key="'+' + item.id"
                      :value="item.id"
                    >
                      {{ item.libelle }}
                    </option>
                  </select>

                </div>

                     <div class="form-group">
                         <label class="form-label semibold">Rôle</label>
                         <select class="form-control"  v-model="edite.role">
                           <option></option>
                            <option v-for="item in Liste_role" :key="item.id" :value="item.id">{{item.libelle}}</option>
                         </select>
						
                     </div>

                     <!-- <div class="form-group">
                         <label class="form-label semibold">Rôle </label>
                            <model-list-select class="form-control" option-value="id"
                                    option-text="libelle" v-model="edite.role_id" :list="Liste_role"
                                                placeholder="Selectionner Acteur juridique">
                        </model-list-select>
                 </div> -->

            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="dialogEdite.value = false"
              >Fermer</v-btn>
          <v-btn
            :disabled="loading"
            :loading="loading"
              text
            @click="modification(edite)"
          >
            Modifier
          </v-btn>
            </v-card-actions>
          </v-card>
        </template>
  </v-dialog>
		</div>
    </v-main>
    </v-app>
</template>



<script>
import { mapGetters, mapActions} from 'vuex'
    import { required, minLength ,email } from "vuelidate/lib/validators";
  import { ModelListSelect } from 'vue-search-select'
    export default {
          name: "Utilisateur",
    components: {
     ModelListSelect
    },
        data() {
            return {
               dialog:false,
               test:true,
               formData:{
                   name:"",
                   email:"",
                   telephone:"",
                   role:"",
                   password:"default2021",
                   numero_pdv:"",
                   contact_personnel:"",
                   localite_id:"",
                   
               },
               edite:"",
       dialogEdite: false,
       dialogDelete: false,
       urlBase:process.env.VUE_APP_BASE,
      headers: [
        { text: 'Photo', value: 'profile_photo_path' },
        { text: 'Nom et prénom(s)', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Contact personnel', value: 'contact_personnel' },
        { text: 'Numéro TP', value: 'numero_tp' },
        { text: 'Numéro PDV', value: 'numero_pdv' },
      //  { text: 'Acteur Juridique', value: 'acteur_juridique.libelle' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      Liste_role:[
        {id:"DETAILLANT",libelle:"DETAILLANT"},
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
      
        libelle: "",
      },
      defaultItem: {
       code: '',
        libelle: "",
      },



            }
        },
        created(){
 
        },
        computed:{
            ...mapGetters('Application', ['getterStructure','getterLocalisationGeographique', 'getterActeurJuridique',"getterLoadingActeurJurique","getterErrorActeurJuridique","getterTypeActeurJuridique"]),
             
              ...mapGetters('Utilisateurs', ["getterErrorUser","getterLoadingUser","getterUser"]),
              detaillantListe(){
            return this.getterUser.filter(item=>item.role=="DETAILLANT")
              },
             formTitle () {
             return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
           },
           loading(){
               return this.getterLoadingUser
           },
           liste_structure() {
      return this.getterStructure.reverse();
    },

    recup_id_acteur(){
       const data = this.getterActeurJuridique.find(item=>item.id==this.formData.acteur_juridique_id);
       
       if(data){
         return data.structure_id
       }
    },

    recup_libelle_structure(){
       const Objet = this.getterStructure.find(item=>item.id==this.recup_id_acteur);
       if(Objet){
         return Objet.libelle
       }
       return ""
    },

     recup_id_structure(){
       const Objet = this.getterStructure.find(item=>item.id==this.recup_id_acteur);
       if(Objet){
         return Objet.id
       }
        return ""
    },


           typeActeurJu(){
             return this.getterTypeActeurJuridique.reverse();
           },
           utilisateurPasActeurJuridique(){
             return acteur_juridique=>{
               if(!acteur_juridique) return []
               return this.getterUser.filter(item=>item.acteur_juridique_id==acteur_juridique)
             }
           },
           regionActeurJuridique(){
             return localisation_geo_graphique_id=>{
               if(!localisation_geo_graphique_id) return ""

               let objet=this.getterLocalisationGeographique.find(item=>item.id==localisation_geo_graphique_id)
               if(objet==undefined) return ""
               return objet.libelle
             }
           },

             libelle_role(){
        return (id) => {
        if (id != null && id != "" && id != undefined) {
          const Objet= this.Liste_role.find((item)=>item.id==id)
           if(Objet){
             return Objet.libelle;
             }return ""
        }
        }
          
      },

          //  libelle_role(){
          //    return id =>{
          //      let Objet=this.Liste_role.find(item=>item.id==id)
          //      if(Objet){
          //        return Objet.libelle;
          //      }return "";
          //    }
          //  },
            imageUsers(){
              return (image,id)=>{
              //  console.log(id)
                if(!image) return this.urlBase+"/users/default/1.jpg"

               return this.urlBase+'/users/'+id+"/"+image
              }
	          }
        },
        validations: {
            formData: {
                     name:{ required },
                    email:{ required ,email },
                   telephone:{ required },
                   password:{ required },
                   role:{ required },
                   numero_pdv:{ required },
                   contact_personnel:{ required },
                   localite_id:""    
            }
        },
        methods:{
             ...mapActions('Application', ['getActeurJuridique',"ajouterActeurJuridique",
             "supprimerActeurJuridique","modificationActeurJuridique"]),

             ...mapActions('Utilisateurs', ['getUsers',"ajouterUser",
             "supprimerUser","modificationUser"]),

            gotoModale(){
              
               this.dialog=true 
            }
            ,
            enregistrement () {
               //this.formData.structure_id=this.recup_id_structure;
                this.$v.formData.$touch();
                if(this.$v.formData.$error) {

                    return
                }
                 console.log(this.formData)
                
                this.ajouterUser(this.formData)
                if(this.getterErrorUser==false){
                    this.$v.formData.$reset();
                  this.formData={
                           name:"",
                          email:"",
                      numero_pdv:"",
                   contact_personnel:"",
                   localite_id:"",
                      telephone:"",
                      role:"",
                      password:"default2021",
                      
                     }
                }
               

            },
              

modification(item){
  
this.modificationUser(item)
},
      editItem (item) {
       this.edite=item
        this.dialogEdite = true
      },

      deleteItem (item) {
      this.edite=item
      console.log(this.edite)
      this.dialogDelete=true
      },

      deleteItemConfirm (item) {
         this.dialogDelete=false
        this.supprimerUser(item.id)
      },
      
 
  
    

      
        },
        watch: {
      
     },
    }
</script>

<style scoped>
.input {
        background-color: #f7f7f7;
        border: 1px solid #c7c7c7;
        padding: 0.3rem 0.5rem;
        border-radius: 2px;
    }
    .hasError .input {
        border: red;
    }
    .hasErroInpu{
        border: 1px red solid;
    }
    .hasError label {
        color: red;
    }
    .icon:hover {
	 cursor: pointer;
}
 .muted {
	 color: gray;
	 font-size: 80%;
}
</style>



<style>
  .vtl .vtl-drag-disabled {
	 background-color: #d0cfcf;
}
 .vtl .vtl-drag-disabled:hover {
	 background-color: #d0cfcf;
}
 .vtl .vtl-disabled {
	 background-color: #d0cfcf;
}
 
 
</style> 
 
