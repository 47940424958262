import apiGuest from '../../../request/auth-request'
import authHeader from '../../../services/auth-header';


export function getSyntheseActivite({ commit }) {
    commit("SET_LOADING_SYNTHESE_ACTIVITE", true)

    apiGuest.get('/synsthese_activite', { headers: authHeader() }).then(response => {
        commit('GET_SYNTHESE_ACTIVITE', response.data)
        commit("SET_LOADING_SYNTHESE_ACTIVITE", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_SYNTHESE_ACTIVITE", false)
    })
}





export function ajouterSyntheseActivite({ commit,dispatch }, formData) {

    commit("SET_LOADING_SYNTHESE_ACTIVITE", true)

    return new Promise((resolve,reject)=>{

  apiGuest.post('/synsthese_activite', formData, { headers: authHeader() }).then(response => {
        if (response.status == 201) {

            commit('AJOUTER_SYNTHESE_ACTIVITE', response.data.sucess)
            this.$app.$notify({
                title: 'success ',
                text: 'Enregistrement effectué !',
                type: "success"
            })
            dispatch("getCommissionDetaillant")
            resolve(response.data.sucess)
            commit("SET_LOADING_SYNTHESE_ACTIVITE", false)
            commit("SET_ERROR_SYNTHESE_ACTIVITE", false)
         //   dispatch('getStructureGeoGraphique')
        }

    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_SYNTHESE_ACTIVITE", false)
        commit("SET_ERROR_SYNTHESE_ACTIVITE", true)
        reject(error)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                ù2title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })

    })
  
}


export function supprimerSyntheseActivite({ commit }, id) {

    commit("SET_LOADING_SYNTHESE_ACTIVITE", true)
    commit('SUPPRIMER_SYNTHESE_ACTIVITE', id)
    apiGuest.delete('/synsthese_activite/' + id, { headers: authHeader() }).then(() => {
        commit("SET_LOADING_SYNTHESE_ACTIVITE", false)
        this.$app.$notify({
            title: 'success',
            text: 'Suppression effectuer !',
            type: "success"
        })
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_SYNTHESE_ACTIVITE", false)
        this.$app.$notify({
            title: 'error',
            text: "Une erreur c'est produite lors de la suppression ",
            type: "warning"
        })
    })

}



export function modificationSyntheseActivite({ commit }, formData) {
commit("SET_LOADING_SYNTHESE_ACTIVITE", true)

return new Promise((resolve,reject)=>{
    apiGuest.put('/synsthese_activite/' + formData.id, formData, { headers: authHeader() }).then(response => {
        commit('MODIFIER_SYNTHESE_ACTIVITE', response.data)
        this.$app.$notify({
            title: 'success ',
            text: 'Enregistrement effectué !',
            type: "success"
        })
        commit("SET_LOADING_SYNTHESE_ACTIVITE", false)
        commit("SET_ERROR_SYNTHESE_ACTIVITE", false)
        resolve(response.data)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_SYNTHESE_ACTIVITE", false)
        commit("SET_ERROR_SYNTHESE_ACTIVITE", true)
        reject(error)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
})
    
}








export function getCommissionDetaillant({ commit }) {
    commit("SET_LOADING_COMMISSION_DETAILLANT", true)

    apiGuest.get('/activite_reseaux', { headers: authHeader() }).then(response => {
        commit('GET_COMMISSION_DETAILLANT', response.data)
        commit("SET_LOADING_COMMISSION_DETAILLANT", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_COMMISSION_DETAILLANT", false)
    })
}


export function searchCommission({ commit },formData) {
    commit("SET_LOADING_COMMISSION_DETAILLANT", true)
 
 return new Promise((resolve,reject)=>{
 apiGuest.post('/activite_reseaux', formData, { headers: authHeader() }).then(response => {
        commit('GET_COMMISSION_DETAILLANT', response.data)
        commit("SET_LOADING_COMMISSION_DETAILLANT", false)
        resolve(response.data)
    }).catch(error => {
        console.log(error)
        reject(error)
        commit("SET_LOADING_COMMISSION_DETAILLANT", false)
    })
 })
   
}








export function getObjectif({ commit }) {
    commit("SET_LOADING_OBJECTIF_PERIODE", true)

    apiGuest.get('/objetctif_periode', { headers: authHeader() }).then(response => {
        commit('GET_OBJECTIF_PERIODE', response.data)
        commit("SET_LOADING_OBJECTIF_PERIODE", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_OBJECTIF_PERIODE", false)
    })
}





export function ajouterObjectif({ commit,dispatch }, formData) {

    commit("SET_LOADING_OBJECTIF_PERIODE", true)

    return new Promise((resolve,reject)=>{

  apiGuest.post('/objetctif_periode', formData, { headers: authHeader() }).then(response => {
        if (response.status == 201) {

            commit('AJOUTER_OBJECTIF_PERIODE', response.data.sucess)
            this.$app.$notify({
                title: 'success ',
                text: 'Enregistrement effectué !',
                type: "success"
            })
           // dispatch("getCommissionDetaillant")
            resolve(response.data.sucess)
            commit("SET_LOADING_OBJECTIF_PERIODE", false)
            commit("SET_ERROR_OBJECTIF_PERIODE", false)
         //   dispatch('getStructureGeoGraphique')
        }

    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_OBJECTIF_PERIODE", false)
        commit("SET_ERROR_OBJECTIF_PERIODE", true)
        reject(error)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                ù2title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })

    })
  
}


export function getObjectifByPeriode({ commit },id) {
    
return new Promise((resolve,reject)=>{
    apiGuest.get('/objetctif_periode/verification/'+id, { headers: authHeader() }).then(response => {
        commit('')
        resolve(response.data)
    }).catch(error => {
        console.log(error)
        reject(error)
    })
})
    
}
