import { render, staticRenderFns } from "./TableauBord.vue?vue&type=template&id=50c86356&scoped=true&"
import script from "./TableauBord.vue?vue&type=script&lang=js&"
export * from "./TableauBord.vue?vue&type=script&lang=js&"
import style0 from "./TableauBord.vue?vue&type=style&index=0&id=50c86356&scoped=true&lang=css&"
import style1 from "./TableauBord.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50c86356",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VMain } from 'vuetify/lib/components/VMain';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VApp,VCard,VCardText,VDialog,VMain,VProgressCircular})
