



export const GET_SYNTHESE_ACTIVITE = (state, tableau_document) => {
    state.syntheseActivite = tableau_document

}

export const AJOUTER_SYNTHESE_ACTIVITE = (state, elementAjouter) => {
    state.syntheseActivite.unshift(elementAjouter)
}


export const SUPPRIMER_SYNTHESE_ACTIVITE = (state, id) => {
    state.syntheseActivite = state.syntheseActivite.filter(prest => prest.id != id)
}

export const MODIFIER_SYNTHESE_ACTIVITE = (state, elementModif) => {
    state.syntheseActivite = state.syntheseActivite.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const SET_LOADING_SYNTHESE_ACTIVITE = (state, tableau_document) => {
    state.loadingSyntheseActivite = tableau_document
}

export const SET_ERROR_SYNTHESE_ACTIVITE = (state, tableau_document) => {
    state.errorSyntheseActivite = tableau_document
}




/**
 * 
 * objectifPeriode
loadingObjectifPeriode
errorObjectifPeriode
 * 
 * */





export const GET_COMMISSION_DETAILLANT= (state, tableau_document) => {
    state.commissionDetaillant = tableau_document

}

export const AJOUTER_COMMISSION_DETAILLANT = (state, elementAjouter) => {
    state.commissionDetaillant.unshift(elementAjouter)
}


export const SUPPRIMER_COMMISSION_DETAILLANT = (state, id) => {
    state.commissionDetaillant = state.commissionDetaillant.filter(prest => prest.id != id)
}

export const MODIFIER_COMMISSION_DETAILLANT= (state, elementModif) => {
    state.commissionDetaillant = state.commissionDetaillant.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const SET_LOADING_COMMISSION_DETAILLANT = (state, tableau_document) => {
    state.loadingCommissionDetaillant = tableau_document
}

export const SET_ERROR_COMMISSION_DETAILLANT= (state, tableau_document) => {
    state.errorCommissionDetaillant = tableau_document
}




/**
 * 


 * 
 * */


export const GET_OBJECTIF_PERIODE= (state, tableau_document) => {
    state.objectifPeriode = tableau_document

}

export const AJOUTER_OBJECTIF_PERIODE= (state, elementAjouter) => {
    state.objectifPeriode.unshift(elementAjouter)
}


export const SUPPRIMER_OBJECTIF_PERIODE = (state, id) => {
    state.objectifPeriode = state.objectifPeriode.filter(prest => prest.id != id)
}

export const MODIFIER_OBJECTIF_PERIODE= (state, elementModif) => {
    state.objectifPeriode = state.objectifPeriode.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const SET_LOADING_OBJECTIF_PERIODE= (state, tableau_document) => {
    state.loadingObjectifPeriode = tableau_document
}

export const SET_ERROR_OBJECTIF_PERIODE= (state, tableau_document) => {
    state.errorObjectifPeriode = tableau_document
}

