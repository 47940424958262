<template>
  <v-app>
    <notifications/>
    <v-main>
        	<div id="content" class="content" >
                 <h2> Cartographie APV2</h2>
            </div>
  </v-main>
    </v-app>
   
</template>

<script>

export default {
  name: 'App',

  components: {
   
  },

  data(){
        return {
           

            }
        },
        created(){
//console.log(this.getterFicheEntretien)



        },
		computed:{
     
		},
        validations: {
           
        },

			methods:{
           

      goToModule(id){

        localStorage.setItem('module_app', id); 

        switch (id) {
        case 1:
            this.$router.push({
             name: 'Home'
         })
          window.location.reload()
            break;
        case 2:
            console.log(id);
            break;
        case 3:
            console.log(id);
            break;
         case 4:
            console.log(id);
            break;
         default:
            console.log("");
        }
      },
      editItem(item) {
       this.formData=item
        this.dialogEdite = true
      },

      deleteItem2(item) {
      this.edite=item
      console.log(this.edite)
     console.log("................")
      this.dialogDelete=true
      },

      deleteItemConfirm(){
         this.dialogDelete=false
        
        this.supprimerStructureGeographique(this.edite.id)
      },
      tele(){
      this.dialog=true
      },
     
     tel(){
      let b={
        date_debut:this.date_debut,
        date_fin:this.date_fin
      }
        this.teletest(b)
     },

        
           getStatus () {
             return status=>{
        if (status == 0) return 'EN ATTENTES'
         if (status ==1) return 'EN-COURS'
         if (status ==2) return 'ACCEPTER'
         return 'REJETTER'
             }
      
      },
	  },
};
</script>

<style scoped>
table ,tr td{
    border:1px solid #008a8a
}
tbody {
    display:block;
    height:500px;
    overflow:auto;
}
thead, tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;
}
thead {
    width: calc( 100% - 1em )
}
table {
    
    width:100%;
}
</style>