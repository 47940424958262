
<template>
<v-app>
    <notifications/>
    <v-main>
    
    <div id="content" class="content">
			<!-- begin breadcrumb -->
			<ol class="breadcrumb pull-right">
				<li class="breadcrumb-item"><a href="javascript:;">Accueil</a></li>
				<li class="breadcrumb-item"><a href="javascript:;">Gestion APV2</a></li>
				<li class="breadcrumb-item active">Gestion des APV2</li>
			</ol>
			<!-- end breadcrumb -->
			<!-- begin page-header -->
			<h1 class="page-header">Gestion <small> des code QR APV2 </small></h1>
			<!-- end page-header -->
			<!-- begin panel -->
			<div class="panel panel-inverse">
				<div class="panel-heading">
					<div class="panel-heading-btn">
						<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-default" data-click="panel-expand"><i class="fa fa-expand"></i></a>
						<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-success" data-click="panel-reload"><i class="fa fa-redo"></i></a>
						<a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
					</div>
					<h4 class="panel-title">Liste des code QR APV2 </h4>
				</div>
				<div class="panel-body">
          <div class="row">
             <div class="col-md-5">
               <div class="form-group">
                  <label class="form-label semibold">Localite</label>
                  <select
                    class="form-control"
                    v-model="formData.localite_id"
                  >
                    <option></option>
                    <option
                      v-for="item in getterLocalisationGeographique"
                      :key="'+' + item.id"
                      :value="item.id"
                    >
                      {{ item.libelle }}
                    </option>
                  </select>

                </div>
             </div>
             <div class="col-md-2">
               <br>
               <button type="button" @click.prevent="viderFiltre()"  class="btn btn-default"><i class="fas fa-redo-alt "></i></button>
             </div>
              <div class="col-md-5" style="text-align: right">
               <!-- 	<button type="button"  @click="gotoModale()" class="btn btn-inverse">NOUVEAU</button>-->
              
              </div>
          </div>
		
 <div>

<div class="col-lg-12 ui-sortable">
			<div class="row">
    <div class="col-md-3" v-for="item in detaillantListe" :key="item.id">
         <qrcode-vue :value="item.two_factor_recovery_codes" :size="size" level="H"></qrcode-vue>
    <br>
    <div class="widget-list-item">
						
							<div class="widget-list-content">
								<h4 class="widget-list-title">{{item.name}}</h4>
								<p class="widget-list-desc">APV 2 {{item.telephone}}</p>
							</div>
						
						</div>
    </div>
    
</div>
</div>




   
  

  </div>
	</div>
			</div>
		

		</div>
    </v-main>
    </v-app>
</template>



<script>
import { mapGetters, mapActions} from 'vuex'
    import { required, minLength ,email } from "vuelidate/lib/validators";
  import { ModelListSelect } from 'vue-search-select'
  import QrcodeVue from 'qrcode.vue'
    export default {
          name: "Utilisateur",
    components: {
     ModelListSelect,
     QrcodeVue
    },
        data() {
            return {
               dialog:false,
               test:true,
               size: 180,
value:"hgdghdghgdhghdghdghgdhgdg",
               formData:{
                   name:"",
                   email:"",
                   telephone:"",
                   role:"",
                   numero_apv2:"",
                   contact_personnel:"",
                   localite_id:"",
                   
               },
               edite:"",
       dialogEdite: false,
       dialogDelete: false,
       urlBase:process.env.VUE_APP_BASE,
      headers: [
        { text: 'Photo', value: 'profile_photo_path' },
        { text: 'Nom et prénom(s)', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Contact personnel', value: 'contact_personnel' },
        { text: 'Numero APV2', value: 'numero_apv2' },
      //  { text: 'Acteur Juridique', value: 'acteur_juridique.libelle' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      Liste_role:[
        {id:"APV2",libelle:"APV2"},
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
      
        libelle: "",
      },
      defaultItem: {
       code: '',
        libelle: "",
      },



            }
        },
        created(){
 
        },
        computed:{
            ...mapGetters('Application', ['getterStructure','getterLocalisationGeographique', 'getterActeurJuridique',"getterLoadingActeurJurique","getterErrorActeurJuridique","getterTypeActeurJuridique"]),
             
              ...mapGetters('Utilisateurs', ["getterErrorUser","getterLoadingUser","getterUser"]),
              detaillantListe(){
                 let vm=this
                if(vm.formData.localite_id){
                  return this.getterUser.filter(item=>{
                    if(item.role=="APV2" && item.localite_id==vm.formData.localite_id) return item
                  })
                }
            return this.getterUser.filter(item=>item.role=="APV2")
              },
             formTitle () {
             return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
           },
           loading(){
               return this.getterLoadingUser
           },
           liste_structure() {
      return this.getterStructure.reverse();
    },

    recup_id_acteur(){
       const data = this.getterActeurJuridique.find(item=>item.id==this.formData.acteur_juridique_id);
       
       if(data){
         return data.structure_id
       }
    },

    recup_libelle_structure(){
       const Objet = this.getterStructure.find(item=>item.id==this.recup_id_acteur);
       if(Objet){
         return Objet.libelle
       }
       return ""
    },

     recup_id_structure(){
       const Objet = this.getterStructure.find(item=>item.id==this.recup_id_acteur);
       if(Objet){
         return Objet.id
       }
        return ""
    },


           typeActeurJu(){
             return this.getterTypeActeurJuridique.reverse();
           },
           utilisateurPasActeurJuridique(){
             return acteur_juridique=>{
               if(!acteur_juridique) return []
               return this.getterUser.filter(item=>item.acteur_juridique_id==acteur_juridique)
             }
           },
           regionActeurJuridique(){
             return localisation_geo_graphique_id=>{
               if(!localisation_geo_graphique_id) return ""

               let objet=this.getterLocalisationGeographique.find(item=>item.id==localisation_geo_graphique_id)
               if(objet==undefined) return ""
               return objet.libelle
             }
           },

             libelle_role(){
        return (id) => {
        if (id != null && id != "" && id != undefined) {
          const Objet= this.Liste_role.find((item)=>item.id==id)
           if(Objet){
             return Objet.libelle;
             }return ""
        }
        }
          
      },

          //  libelle_role(){
          //    return id =>{
          //      let Objet=this.Liste_role.find(item=>item.id==id)
          //      if(Objet){
          //        return Objet.libelle;
          //      }return "";
          //    }
          //  },
            imageUsers(){
              return (image,id)=>{
              //  console.log(id)
                if(!image) return this.urlBase+"/users/default/1.jpg"

               return this.urlBase+"/users/"+image
              }
	          }
        },
        validations: {
            formData: {
                     name:{ required },
                    email:{ required ,email },
                   telephone:{ required },
                   password:{ required },
                   role:{ required },
                   numero_pdv:{ required },
                   contact_personnel:{ required },
                   localite_id:""    
            }
        },
        methods:{
             ...mapActions('Application', ['getActeurJuridique',"ajouterActeurJuridique",
             "supprimerActeurJuridique","modificationActeurJuridique"]),

             ...mapActions('Utilisateurs', ['getUsers',"ajouterUser",
             "supprimerUser","modificationUser"]),

            gotoModale(){
              
               this.dialog=true 
            }
            ,
            enregistrement () {
               //this.formData.structure_id=this.recup_id_structure;
                this.$v.formData.$touch();
                if(this.$v.formData.$error) {

                    return
                }
                 console.log(this.formData)
                
                this.ajouterUser(this.formData)
                if(this.getterErrorUser==false){
                    this.$v.formData.$reset();
                  this.formData={
                           name:"",
                          email:"",
                      numero_pdv:"",
                   contact_personnel:"",
                   localite_id:"",
                      telephone:"",
                      role:"",
                      password:"default2021",
                      
                     }
                }
               

            },
              

modification(item){
  
this.modificationUser(item)
},
      editItem (item) {
       this.edite=item
        this.dialogEdite = true
      },

      deleteItem (item) {
      this.edite=item
      console.log(this.edite)
      this.dialogDelete=true
      },

      deleteItemConfirm (item) {
         this.dialogDelete=false
        this.supprimerUser(item.id)
      },
      
      viderFiltre(){
                this.formData.localite_id=""
              
            },
  
    

      
        },
        watch: {
      
     },
    }
</script>

<style scoped>
.input {
        background-color: #f7f7f7;
        border: 1px solid #c7c7c7;
        padding: 0.3rem 0.5rem;
        border-radius: 2px;
    }
    .hasError .input {
        border: red;
    }
    .hasErroInpu{
        border: 1px red solid;
    }
    .hasError label {
        color: red;
    }
    .icon:hover {
	 cursor: pointer;
}
 .muted {
	 color: gray;
	 font-size: 80%;
}
</style>



<style>
  .vtl .vtl-drag-disabled {
	 background-color: #d0cfcf;
}
 .vtl .vtl-drag-disabled:hover {
	 background-color: #d0cfcf;
}
 .vtl .vtl-disabled {
	 background-color: #d0cfcf;
}
 
 
</style> 
 
