<template>
   <div>
   <div id="sidebar" class="sidebar" >
			<!-- begin sidebar scrollbar -->
			<div data-scrollbar="true" data-height="100%">
				<!-- begin sidebar user -->
				<ul class="nav">
					<li class="nav-profile">
						<a href="javascript:;">
							<div class="cover with-shadow"></div>
							<div class="image">
								<img :src="imageUsers" alt="" />
							</div>
							<div class="info">
								<b class="caret pull-right"></b>
								{{nameUser}}
								<small></small>
							</div>
						</a>
					</li>
					<li  @click.prevent="activedOptionMenu(100,'ModuleApplication')">
						<a href="widget.html">
						<i class="fas fa-book fa-fw"></i>
							<span>Modules</span> 
						</a>
					</li>
				</ul>
				<!-- end sidebar user -->
				<!-- begin sidebar nav -->
				<TheMenuCaisse v-if="id_module==1"></TheMenuCaisse>
				<TheMenuAPV v-if="id_module==3"></TheMenuAPV>
				<TheMenuParametre v-if="id_module==5"></TheMenuParametre>
				<TheMenuCommission v-if="id_module==4"></TheMenuCommission>
			</div>
			<!-- end sidebar scrollbar -->
		</div>
		<div class="sidebar-bg"></div>
   </div>



</template>
<script>
 import TheMenuCaisse from "./TheMenuCaisse";
 import TheMenuAPV from "./TheMenuAPV";
  import TheMenuParametre from "./TheMenuParametre";
   import TheMenuCommission from "./TheMenuCommission";
import { mapState, mapActions ,mapMutations,mapGetters} from "vuex";
    export default {
		components: {
			TheMenuCaisse,
			TheMenuAPV,
			TheMenuParametre,
			TheMenuCommission
		},
        name: "TheNavBar",
        data() {
            return {
		menu_active:1,
		id_module:"",
		urlBase:process.env.VUE_APP_BASE,
		array1:[1,3],
		array2:[2,3],
            }
        },
		 created(){
			 
			  this.id_module=localStorage.getItem('module_app');
        },

computed: {
...mapGetters("Utilisateurs",["getterUserStore"]),
       ...mapState('parametrageMenu', {
     active_el: state => state.active_el
  }),
  nameUser(){
	  if(!this.getterUserStore) return "JUSTICE"

	  let objet=this.getterUserStore
	  return objet.name
  },
 
   inArray() {
      return (valeur, tableau)=>{
         let length = tableau.length;
        for(let i = 0; i < length; i++) {
            if(tableau[i] == valeur) return true;
        }
    return false;
      }
   
},
  imageUsers(){
	    if(this.getterUserStore.profile_photo_path){
			  let id_user=this.getterUserStore.id
			  return this.urlBase+'/users/'+id_user+"/"+this.getterUserStore.profile_photo_path
		  }
		    return this.urlBase+"/users/default/1.jpg"
  },
  codeUser(){
	  if(!this.getterUserStore) return 0
	  return this.getterUserStore.role
  }
},
        methods: {
        ...mapActions('Utilisateurs', ['logoutUser']),
			afficheMenu(value){
	this.activate(value)
			},
				activedOptionMenu(value,route_name){
		  this.menu_active= value
		  this.$router.push({
        name: route_name
      })
	  
	   }
        }
    }
</script>

<style scoped>

</style>
