import Vue from "vue";
import VueRouter from "vue-router";
import store from '../store/index'

import AuthRoutes from './auth/AuthRoutes'
import AppRoutes from "./app/AppRoutes"
import Apv2Routes from "./apv2/Apv2Routes"
import CommissionRoutes from "./commission/CommissionRoutes"
Vue.use(VueRouter);


const regroupementDesRoutes = [

  AuthRoutes,
  AppRoutes,
  Apv2Routes,
  CommissionRoutes


];
const routes = [].concat(...regroupementDesRoutes);


const router = new VueRouter({
  routes,
    mode: "history"
});


router.beforeEach((to, from, next) => {
  // console.log(store)
   // check if the route requires authentication and user is not logged in
   const publicPages = ['/connexion'];
   const authRequired = !publicPages.includes(to.path);
   const loggedIn = localStorage.getItem('user-token');
 
   // trying to access a restricted page + not logged in
   // redirect to login page
   if (authRequired && !loggedIn) {
    
     next('/connexion');
   } else {

    if(to.path === '/' && loggedIn) {
      next({ name: 'ModuleApplication' })
        return
    }
  
    next()
    
   }

 })

export default router;