

import ActiviteOm from '@/views/gestion_commissions/ActiviteOm'
import Commission from '@/views/gestion_commissions/Commission'
import CommissionReseauPropre from '@/views/gestion_commissions/CommissionReseauPropre'

import ObjectifPeriode from '@/views/gestion_commissions/ObjectifPeriode'
import VerificationObjectif from '@/views/gestion_commissions/VerificationObjectif'
import DetailObejectifPeriode from '@/views/gestion_commissions/DetailObejectifPeriode'
import TableauBord from '@/views/gestion_commissions/TableauBord'

const CommissionRoutes = [
   // { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound, meta: {layout: "no-sidebar"} },
   {
      path: '/Activite-om',
      name: "ActiviteOm",
      meta: { requiresAuth: true },
      component: ActiviteOm
   },
   {
      path: '/commission/epervier',
      name: "Commission",
      meta: { requiresAuth: true },
      component: Commission
   },
   {
      path: '/commission/reseau_en_propre',
      name: "CommissionReseauPropre",
      meta: { requiresAuth: true },
      component: CommissionReseauPropre
   },
     {
      path: '/parametrage/objectif',
      name: "ObjectifPeriode",
      meta: { requiresAuth: true },
      component: ObjectifPeriode
   },
   {
      path: '/verification/objectif',
      name: "VerificationObjectif",
      meta: { requiresAuth: true },
      component: VerificationObjectif
   },
   {
      path: '/verification/objectif/:id',
      name: "DetailObejectifPeriode",
      meta: { requiresAuth: true },
      component: DetailObejectifPeriode
   },
   {
      path: '/commissions/tableau-bord',
      name: "TableauBord",
      meta: { requiresAuth: true },
      component: TableauBord
   },


]

export default CommissionRoutes;
