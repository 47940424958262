


/**
 * MUTATION DES STRUCTURE GEOGRAPHIQUE
 * @param {*} state 
 * @param {*} tableau_document 
 */
export const GET_STRUCTURE_GEGRAPHIQUE = (state, tableau_document) => {
    state.structureGeographique = tableau_document

}

export const AJOUTER_STRUCTURE_GEGRAPHIQUE = (state, elementAjouter) => {
    state.structureGeographique.unshift(elementAjouter)
}


export const SUPPRIMER_STRUCTURE_GEGRAPHIQUE = (state, id) => {
    state.structureGeographique = state.structureGeographique.filter(prest => prest.id != id)
}

export const MODIFIER_STRUCTURE_GEGRAPHIQUE = (state, elementModif) => {
    state.structureGeographique = state.structureGeographique.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const SET_LOADING_STRUCTURE_GEGRAPHIQUE = (state, tableau_document) => {
    state.loadingStructureGeographique = tableau_document
}

export const SET_ERROR_STRUCTURE_GEGRAPHIQUE = (state, tableau_document) => {
    state.errorStructureGeographique = tableau_document
}



/**
 * MUTATION DES TYPE ELEMENT DU DOSSIER
 */



export const GET_TYPE_ELEMENT_DOSSIER = (state, tableau_document) => {
    state.typeElementDossier = tableau_document

}

export const AJOUTER_TYPE_ELEMENT_DOSSIER = (state, elementAjouter) => {
    state.typeElementDossier.unshift(elementAjouter)
}


export const SUPPRIMER_TYPE_ELEMENT_DOSSIER = (state, id) => {
    state.typeElementDossier = state.typeElementDossier.filter(prest => prest.id != id)
}

export const MODIFIER_TYPE_ELEMENT_DOSSIER = (state, elementModif) => {
    state.typeElementDossier = state.typeElementDossier.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const SET_LOADING_TYPE_ELEMENT_DOSSIER = (state, tableau_document) => {
    state.loadingTypeElementDossier = tableau_document
}

export const SET_ERROR_TYPE_ELEMENT_DOSSIER = (state, tableau_document) => {
    state.errorTypeElementDossier = tableau_document
}



/**
 * Type Personnel Tribunal
 */



export const GET_TYPE_PERSONNE_TRIBUNAL = (state, tableau_document) => {
    state.typePersonneTribunal = tableau_document

}

export const AJOUTER_TYPE_PERSONNE_TRIBUNAL = (state, elementAjouter) => {
    state.typePersonneTribunal.unshift(elementAjouter)
}


export const SUPPRIMER_TYPE_PERSONNE_TRIBUNAL = (state, id) => {
    state.typePersonneTribunal = state.typePersonneTribunal.filter(prest => prest.id != id)
}

export const MODIFIER_TYPE_PERSONNE_TRIBUNAL = (state, elementModif) => {
    state.typePersonneTribunal = state.typePersonneTribunal.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const SET_LOADING_TYPE_PERSONNE_TRIBUNAL = (state, tableau_document) => {
    state.loadingtypeTypePersonneTribunal = tableau_document
}

export const SET_ERROR_TYPE_PERSONNE_TRIBUNAL = (state, tableau_document) => {
    state.errortypeTypePersonneTribunal = tableau_document
}





/**
 * Type Acteur Juridique
 */


export const GET_TYPE_ACTEUR_JURIDIQUE = (state, tableau_document) => {
    state.typeActeurJuridique = tableau_document

}

export const AJOUTER_TYPE_ACTEUR_JURIDIQUE = (state, elementAjouter) => {
    state.typeActeurJuridique.unshift(elementAjouter)
}


export const SUPPRIMER_TYPE_ACTEUR_JURIDIQUE = (state, id) => {
    state.typeActeurJuridique = state.typeActeurJuridique.filter(prest => prest.id != id)
}

export const MODIFIER_TYPE_ACTEUR_JURIDIQUE = (state, elementModif) => {
    state.typeActeurJuridique = state.typeActeurJuridique.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const SET_LOADING_TYPE_ACTEUR_JURIDIQUE = (state, tableau_document) => {
    state.loadingtypeTypeActeurJuridique = tableau_document
}

export const SET_ERROR_TYPE_ACTEUR_JURIDIQUE = (state, tableau_document) => {
    state.errortypeTypeActeurJuridique = tableau_document
}



/**
 * Nature de dossier
 */



export const GET_NATURE_DOSSIER = (state, tableau_document) => {
    state.natureDossier = tableau_document

}

export const AJOUTER_NATURE_DOSSIER = (state, elementAjouter) => {
    state.natureDossier.unshift(elementAjouter)
}


export const SUPPRIMER_NATURE_DOSSIER = (state, id) => {
    state.natureDossier = state.natureDossier.filter(prest => prest.id != id)
}

export const MODIFIER_NATURE_DOSSIER = (state, elementModif) => {
    state.natureDossier = state.natureDossier.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const SET_LOADING_NATURE_DOSSIER = (state, tableau_document) => {
    state.loadingtypeNatureDossier = tableau_document
}

export const SET_ERROR_NATURE_DOSSIER = (state, tableau_document) => {
    state.errortypeNatureDossier = tableau_document
}



/**
 * Motif de partie requerante
 */





export const GET_MOTIF_PARTIE = (state, tableau_document) => {
    state.motifPartie = tableau_document

}

export const AJOUTER_MOTIF_PARTIE = (state, elementAjouter) => {
    state.motifPartie.unshift(elementAjouter)
}


export const SUPPRIMER_MOTIF_PARTIE = (state, id) => {
    state.motifPartie = state.motifPartie.filter(prest => prest.id != id)
}

export const MODIFIER_MOTIF_PARTIE = (state, elementModif) => {
    state.motifPartie = state.motifPartie.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const SET_LOADING_MOTIF_PARTIE = (state, tableau_document) => {
    state.loadingMotifPartie = tableau_document
}

export const SET_ERROR_MOTIF_PARTIE = (state, tableau_document) => {
    state.errorMotifPartie = tableau_document
}





/**
 * Mode de saisine
 */





export const GET_MODE_SAISINE = (state, tableau_document) => {
    state.modeSaisine = tableau_document

}

export const AJOUTER_MODE_SAISINE = (state, elementAjouter) => {
    state.modeSaisine.unshift(elementAjouter)
}


export const SUPPRIMER_MODE_SAISINE = (state, id) => {
    state.modeSaisine = state.modeSaisine.filter(prest => prest.id != id)
}

export const MODIFIER_MODE_SAISINE = (state, elementModif) => {
    state.modeSaisine = state.modeSaisine.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const SET_LOADING_MODE_SAISINE = (state, tableau_document) => {
    state.loadingModeSaisine = tableau_document
}

export const SET_ERROR_MODE_SAISINE = (state, tableau_document) => {
    state.errorModeSaisine = tableau_document
}


/**
 * Crite indigence 
 */


export const GET_CRITE_INDIGENCE = (state, tableau_document) => {
    state.criterIndigence = tableau_document

}

export const AJOUTER_CRITE_INDIGENCE = (state, elementAjouter) => {
    state.criterIndigence.unshift(elementAjouter)
}


export const SUPPRIMER_CRITE_INDIGENCE = (state, id) => {
    state.criterIndigence = state.criterIndigence.filter(prest => prest.id != id)
}

export const MODIFIER_CRITE_INDIGENCE = (state, elementModif) => {
    state.criterIndigence = state.criterIndigence.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const SET_LOADING_CRITE_INDIGENCE = (state, tableau_document) => {
    state.loadingCriterIndigence = tableau_document
}

export const SET_ERROR_CRITE_INDIGENCE = (state, tableau_document) => {
    state.errorCriterIndigence = tableau_document
}


/**
 * Crite de victimologie
 */






export const GET_CRITE_VICTIMOLOGIE = (state, tableau_document) => {
    state.critereVictimologie = tableau_document

}

export const AJOUTER_CRITE_VICTIMOLOGIE = (state, elementAjouter) => {
    state.critereVictimologie.unshift(elementAjouter)
}


export const SUPPRIMER_CRITE_VITIMOLOGIE = (state, id) => {
    state.critereVictimologie = state.critereVictimologie.filter(prest => prest.id != id)
}

export const MODIFIER_CRITE_VICTIMOLOGIE = (state, elementModif) => {
    state.critereVictimologie = state.critereVictimologie.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const SET_LOADING_CRITE_VICTIMOLOGIE = (state, tableau_document) => {
    state.loadingCritereVictimologie = tableau_document
}

export const SET_ERROR_CRITE_VICTIMOLOGIE = (state, tableau_document) => {
    state.errorCritereVictimologie = tableau_document
}








export const GET_LOCALISATION_GEOGRAPHIQUE = (state, tableau_document) => {
    state.localisationGeographique = tableau_document

}

export const AJOUTER_LOCALISATION_GEOGRAPHIQUE = (state, elementAjouter) => {
    state.localisationGeographique.unshift(elementAjouter)
}


export const SUPPRIMER_LOCALISATION_GEOGRAPHIQUE = (state, id) => {
    state.localisationGeographique = state.localisationGeographique.filter(prest => prest.id != id)
}

export const MODIFIER_LOCALISATION_GEOGRAPHIQUE = (state, elementModif) => {
    state.localisationGeographique = state.localisationGeographique.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const SET_LOADING_LOCALISATION_GEOGRAPHIQUE = (state, tableau_document) => {
    state.loadingLocalisationGeographisue = tableau_document
}

export const SET_ERROR_LOCALISATION_GEOGRAPHIQUE = (state, tableau_document) => {
    state.errorLocalisationGeographisue = tableau_document
}




/**Acteur Juridique */





export const GET_ACTEUR_JURIDIQUE = (state, tableau_document) => {
    state.acteurJuridique = tableau_document

}

export const AJOUTER_ACTEUR_JURIDIQUE = (state, elementAjouter) => {
    state.acteurJuridique.unshift(elementAjouter)
}


export const SUPPRIMER_ACTEUR_JURIDIQUE = (state, id) => {
    state.acteurJuridique = state.acteurJuridique.filter(prest => prest.id != id)
}

export const MODIFIER_ACTEUR_JURIDIQUE = (state, elementModif) => {
    state.acteurJuridique = state.acteurJuridique.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const SET_LOADING_ACTEUR_JURIDIQUE = (state, tableau_document) => {
    state.loadingActeurJuridique = tableau_document
}

export const SET_ERROR_ACTEUR_JURIDIQUE = (state, tableau_document) => {
    state.errorActeurJuridique = tableau_document
}

//localite saisine
export const SET_LOADING_LOCALITE_SAISINE = (state, tableau_document) => {
    state.loadingLocaliteSaisine = tableau_document
}

export const GET_LOCALITE_SAISINE = (state, tableau_document) => {
    state.LocaliteSaisine = tableau_document

}

export const AJOUTER_LOCALITE_SAISINE = (state, elementAjouter) => {
    state.LocaliteSaisine.unshift(elementAjouter)
}


export const SUPPRIMER_LOCALITE_SAISINE = (state, id) => {
    state.LocaliteSaisine = state.LocaliteSaisine.filter(prest => prest.id != id)
}

export const MODIFIER_LOCALITE_SAISINE = (state, elementModif) => {
    state.LocaliteSaisine = state.LocaliteSaisine.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const SET_ERROR_LOCALITE_SAISINE = (state, tableau_document) => {
    state.errorLocaliteSaisine = tableau_document
}



//article du code penal
export const SET_LOADING_CODE_PENAL = (state, tableau_document) => {
    state.loadingCodePenal = tableau_document
}
export const GET_CODE_PENAL = (state, tableau_document) => {
    state.CodePenal = tableau_document

}
export const AJOUTER_CODE_PENAL = (state, elementAjouter) => {
    state.CodePenal.unshift(elementAjouter)
}
export const SUPPRIMER_CODE_PENAL = (state, id) => {
    state.CodePenal = state.CodePenal.filter(prest => prest.id != id)
}
export const MODIFIER_CODE_PENAL = (state, elementModif) => {
    state.CodePenal = state.CodePenal.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const SET_ERROR_CODE_PENAL = (state, tableau_document) => {
    state.errorCodePenal = tableau_document
}



//Fiche ficheEntretien


export const SET_LOADING_FICHE_ENTRETIEN = (state, tableau_document) => {
    state.loadingFicheEntretien = tableau_document
}
export const GET_FICHE_ENTRETIEN = (state, tableau_document) => {
    state.ficheEntretien = tableau_document
}
export const AJOUTER_FICHE_ENTRETIEN = (state, elementAjouter) => {
    state.ficheEntretien.unshift(elementAjouter)
}
export const SUPPRIMER_FICHE_ENTRETIEN = (state, id) => {
    state.ficheEntretien = state.ficheEntretien.filter(prest => prest.id != id)
}
export const MODIFIER_FICHE_ENTRETIEN = (state, elementModif) => {
    state.ficheEntretien = state.ficheEntretien.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const SET_ERROR_FICHE_ENTRETIEN = (state, tableau_document) => {
    state.errorFicheEntretien = tableau_document
}




//Dossier Maison justice


export const MODIFIER_DATE = (state, tableau_document) => {
    state.DossierMaisonJustice = tableau_document
}

export const SET_LOADING_DOSSIER_MAISON_JUSTICE = (state, tableau_document) => {
    state.loadingDossierMaisonJustice = tableau_document
}
export const GET_DOSSIER_MAISON_JUSTICE = (state, tableau_document) => {
    state.DossierMaisonJustice = tableau_document
}
export const AJOUTER_DOSSIER_MAISON_JUSTICE = (state, elementAjouter) => {
    state.DossierMaisonJustice.unshift(elementAjouter)
}

export const AJOUTER_DOSSIER_MAISON_COMPROMIS = (state, elementAjouter) => {
    state.DossierMaisonJustice.unshift(elementAjouter)
}
export const SUPPRIMER_DOSSIER_MAISON_JUSTICE = (state, id) => {
    state.DossierMaisonJustice = state.DossierMaisonJustice.filter(prest => prest.id != id)
}
export const MODIFIER_DOSSIER_MAISON_JUSTICE = (state, elementModif) => {
    state.DossierMaisonJustice = state.DossierMaisonJustice.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const REORIENTATER_DOSSIER_MAISON_JUSTICE = (state, elementModif) => {
    state.DossierMaisonJustice = state.DossierMaisonJustice.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const MODIFIER_DOSSIER_MAISON_COMPROMIS = (state, elementModif) => {
    state.DossierMaisonJustice = state.DossierMaisonJustice.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const SET_ERROR_DOSSIER_MAISON_JUSTICE = (state, tableau_document) => {
    state.errorDossierMaisonJustice = tableau_document
}
//Analyse CJ


export const SET_LOADING_ANALYSE_CJ = (state, tableau_document) => {
    state.loadingAnalyseCJ = tableau_document
}
export const GET_ANALYSE_CJ = (state, tableau_document) => {
    state.analyseCJ = tableau_document
}
export const AJOUTER_ANALYSE_CJ = (state, elementAjouter) => {
    state.analyseCJ.unshift(elementAjouter)
}
export const SUPPRIMER_ANALYSE_CJ = (state, id) => {
    state.analyseCJ = state.analyseCJ.filter(prest => prest.id != id)
}
export const MODIFIER_ANALYSE_CJ = (state, elementModif) => {
    state.analyseCJ = state.analyseCJ.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const SET_ERROR_ANALYSE_CJ = (state, tableau_document) => {
    state.errorAnalyseCJ = tableau_document
}





//Analyse Maison de MaisonJustice


export const SET_LOADING_ANALYSE_MJ = (state, tableau_document) => {
    state.loadingAnalyseMJ = tableau_document
}
export const GET_ANALYSE_MJ = (state, tableau_document) => {
    state.analyseMJ = tableau_document
}
export const AJOUTER_ANALYSE_MJ = (state, elementAjouter) => {
    state.analyseMJ.unshift(elementAjouter)
}
export const SUPPRIMER_ANALYSE_MJ = (state, id) => {
    state.analyseMJ = state.analyseMJ.filter(prest => prest.id != id)
}
export const MODIFIER_ANALYSE_MJ = (state, elementModif) => {
    state.analyseMJ = state.analyseMJ.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const SET_ERROR_ANALYSE_MJ = (state, tableau_document) => {
    state.errorAnalyseMJ = tableau_document
}



//Dossier CLINIQUE_JURIDIQUE


export const SET_LOADING_DOSSIER_CLINIQUE_JURIDIQUE = (state, tableau_document) => {
    state.loadingDossiercliniquejuridique = tableau_document
}
export const GET_DOSSIER_CLINIQUE_JURIDIQUE = (state, tableau_document) => {
    state.Dossiercliniquejuridique = tableau_document
}
export const AJOUTER_DOSSIER_CLINIQUE_JURIDIQUE = (state, elementAjouter) => {
    state.Dossiercliniquejuridique.unshift(elementAjouter)
}
export const SUPPRIMER_DOSSIER_CLINIQUE_JURIDIQUE = (state, id) => {
    state.Dossiercliniquejuridique = state.Dossiercliniquejuridique.filter(prest => prest.id != id)
}
export const MODIFIER_DOSSIER_CLINIQUE_JURIDIQUE = (state, elementModif) => {
    state.Dossiercliniquejuridique = state.Dossiercliniquejuridique.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const SET_ERROR_DOSSIER_CLINIQUE_JURIDIQUE = (state, tableau_document) => {
    state.errorDossiercliniquejuridique = tableau_document
}




//TYPE AFFAIRE

export const SET_LOADING_TYPE_AFFAIRE = (state, tableau_document) => {
    state.loadingTypeAffaire = tableau_document
}
export const GET_TYPE_AFFAIRE = (state, tableau_document) => {
    state.typeAffaire = tableau_document
}
export const AJOUTER_TYPE_AFFAIRE = (state, elementAjouter) => {
    state.typeAffaire.unshift(elementAjouter)
}
export const SUPPRIMER_TYPE_AFFAIRE = (state, id) => {
    state.typeAffaire = state.typeAffaire.filter(prest => prest.id != id)
}
export const MODIFIER_TYPE_AFFAIRE = (state, elementModif) => {
    state.typeAffaire = state.typeAffaire.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const SET_ERROR_TYPE_AFFAIRE = (state, tableau_document) => {
    state.errorTypeAffaire = tableau_document
}





//partie requerant
export const SET_LOADING_PARTIE_REQUERANT = (state, tableau_document) => {
    state.loadingpartierequerant = tableau_document
}
export const GET_PARTIE_REQUERANT = (state, tableau_document) => {
    state.partierequerant = tableau_document
}
export const AJOUTER_PARTIE_REQUERANT = (state, elementAjouter) => {
    state.partierequerant.unshift(elementAjouter)
}
export const SUPPRIMER_PARTIE_REQUERANT = (state, id) => {
    state.partierequerant = state.partierequerant.filter(prest => prest.id != id)
}
export const MODIFIER_PARTIE_REQUERANT = (state, elementModif) => {
    state.partierequerant = state.partierequerant.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const SET_ERROR_PARTIE_REQUERANT = (state, tableau_document) => {
    state.errorpartierequerant = tableau_document
}


//infractions
export const SET_LOADING_INFRACTION = (state, tableau_document) => {
    state.loadinginfraction = tableau_document
}
export const GET_INFRACTION = (state, tableau_document) => {
    state.infraction = tableau_document
}
export const AJOUTER_INFRACTION = (state, elementAjouter) => {
    state.infraction.unshift(elementAjouter)
}

export const AJOUTER_PARAM = (state, elementAjouter) => {
    state.dataparam.unshift(elementAjouter)
}

export const Vidder_PARAM = (state, data) => {

    state.dataparam = data


}
export const GET_PARAM = (state, tableau_document) => {
    state.dataparam = tableau_document
}
export const SUPPRIMER_INFRACTION = (state, id) => {
    state.infraction = state.infraction.filter(prest => prest.id != id)
}


export const MODIFIER_INFRACTION = (state, elementModif) => {
    state.infraction = state.infraction.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const SET_ERROR_INFRACTION = (state, tableau_document) => {
    state.errorinfraction = tableau_document
}




//infractions
export const SET_LOADING_VICTIME_PREVENU = (state, tableau_document) => {
    state.loadingvictimeprevenu = tableau_document
}
export const GET_VICTIME_PREVENU = (state, tableau_document) => {
    state.victimeprevenu = tableau_document
}

export const SET_ERROR_VICTIME_PREVENU = (state, tableau_document) => {
    state.errorvictimeprevenu = tableau_document
}

export const AJOUTER_VICTIME_PREVENU = (state, elementAjouter) => {
    state.victimeprevenu.unshift(elementAjouter)
}

export const MODIFIER_VICTIME_PREVENU = (state, elementModif) => {
    state.victimeprevenu = state.victimeprevenu.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}




//Compromis Acoord
export const SET_LOADING_COMPROMIS_ACCORD = (state, tableau_document) => {
    state.loadingcompromisaccord = tableau_document
}
export const GET_COMPROMIS_ACCORD = (state, tableau_document) => {
    state.compromisaccord = tableau_document
}


//gestion des audiances

export const SET_LOADING_AUDIENCE = (state, tableau_document) => {
    state.loadingaudiance = tableau_document
}

export const GET_AUDIENCE = (state, tableau_document) => {
    state.audience = tableau_document
}
export const AJOUTER_AUDIENCE = (state, elementAjouter) => {
    state.audience.unshift(elementAjouter)
}
export const SUPPRIMER_AUDIENCE = (state, id) => {
    state.audience = state.audience.filter(prest => prest.id != id)
}
export const MODIFIER_AUDIENCE = (state, elementModif) => {
    state.audience = state.audience.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const SET_ERROR_AUDIENCE = (state, tableau_document) => {
    state.erroraudience = tableau_document
}

// GESTION DES STRUCTURES
export const SET_LOADING_STRUCTURE = (state, tableau_document) => {
    state.loadingStructure = tableau_document
}

export const GET_STRUCTURE = (state, tableau_document) => {
    state.Structure = tableau_document
}
export const AJOUTER_STRUCTURE = (state, elementAjouter) => {
    state.Structure.unshift(elementAjouter)
}
export const SUPPRIMER_STRUCTURE = (state, id) => {
    state.Structure = state.Structure.filter(prest => prest.id != id)
}
export const MODIFIER_STRUCTURE = (state, elementModif) => {
    state.Structure = state.Structure.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const SET_ERROR_STRUCTURE = (state, tableau_document) => {
    state.errorStructure = tableau_document
}

export const GET_DATE_ENTRETIEN =(state,value)=>{
    state.DateEntretien = value
}

export const GET_RENVOI =(state,value)=>{
    state.liistRenvoi = value
}

export const GET_PARTIE_CIVIL =(state,value)=>{
    state.listPartieCivil = value
}




/**
 * 
 * 
 * Peine**/

 export const SET_LOADING_PEINE = (state, tableau_document) => {
    state.loadingPeine = tableau_document
}
export const GET_PEINE = (state, tableau_document) => {
    state.peine = tableau_document
}
export const AJOUTER_PEINE = (state, elementAjouter) => {
    state.peine.unshift(elementAjouter)
}
export const SUPPRIMER_PEINE = (state, id) => {
    state.peine = state.peine.filter(prest => prest.id != id)
}
export const MODIFIER_PEINE = (state, elementModif) => {
    state.peine = state.peine.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}





export const SET_LOADING_RENVOI = (state, tableau_document) => {
    state.loadingRenvoi = tableau_document
}
export const GET_RENVOI2 = (state, tableau_document) => {
    state.renvoi = tableau_document
}
export const AJOUTER_RENVOI = (state, elementAjouter) => {
    state.renvoi.unshift(elementAjouter)
}
export const SUPPRIMER_RENVOI = (state, id) => {
    state.renvoi = state.renvoi.filter(prest => prest.id != id)
}
export const MODIFIER_RENVOI = (state, elementModif) => {
    state.renvoi = state.renvoi.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}


//gestion des appels

export const GET_APPEL = (state, tableau_document) => {
    state.appel = tableau_document

}

export const AJOUTER_APPEL = (state, elementAjouter) => {
    state.appel.unshift(elementAjouter)
}


export const SUPPRIMER_APPEL = (state, id) => {
    state.appel = state.appel.filter(prest => prest.id != id)
}

export const MODIFIER_APPEL = (state, elementModif) => {
    state.appel = state.appel.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const SET_LOADING_APPEL = (state, tableau_document) => {
    state.loadingappel = tableau_document
}

export const SET_ERROR_APPEL = (state, tableau_document) => {
    state.errorappel = tableau_document
}


/**
 * 
 * stateStructureCoopec: [],
     loadingStructureCoopec: false,
     errorStructureCoopec: false,
 */


export const SET_LOADING_STRUCTURE_COOPEC = (state, tableau_document) => {
    state.loadingStructureCoopec = tableau_document
}

export const GET_STRUCTURE_COOPEC = (state, tableau_document) => {
    state.stateStructureCoopec = tableau_document
}
export const AJOUTER_STRUCTURE_COOPEC = (state, elementAjouter) => {
    state.stateStructureCoopec.unshift(elementAjouter)
}
export const SUPPRIMER_STRUCTURE_COOPEC = (state, id) => {
    state.stateStructureCoopec = state.stateStructureCoopec.filter(prest => prest.id != id)
}
export const MODIFIER_STRUCTURE_COOPEC= (state, elementModif) => {
    state.stateStructureCoopec = state.stateStructureCoopec.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const SET_ERROR_STRUCTURE_COOPEC = (state, tableau_document) => {
    state.errorStructureCoopec = tableau_document
}



/***
 * 
 * 
 * stateGrilleCommissions
loadingGrilleCommissions
errorGrilleCommissions
 * */



export const SET_LOADING_GRILLE_COMMSISSION = (state, tableau_document) => {
    state.loadingGrilleCommissions = tableau_document
}

export const GET_GRILLE_COMMSISSION = (state, tableau_document) => {
    state.stateGrilleCommissions = tableau_document
}
export const AJOUTER_GRILLE_COMMSISSION= (state, elementAjouter) => {
    state.stateGrilleCommissions.unshift(elementAjouter)
}
export const SUPPRIMER_GRILLE_COMMSISSION = (state, id) => {
    state.stateGrilleCommissions = state.stateGrilleCommissions.filter(prest => prest.id != id)
}
export const MODIFIER_GRILLE_COMMSISSION= (state, elementModif) => {
    state.stateGrilleCommissions = state.stateGrilleCommissions.map(response => {
        if (response.id == elementModif.id) {
            response = { ...elementModif }
        }
        return response
    })
}

export const SET_ERROR_GRILLE_COMMSISSION = (state, tableau_document) => {
    state.errorGrilleCommissions = tableau_document
}