import Home from '../../views/home/Home'


import CriteIngigence from "../../views/parametre/CriteIngigence";
import Structure from "../../views/parametre/Structure";
import LocalisationGeographique from '@/views/parametre/LocalisationGeographique'
import CodePenal from '@/views/parametre/CodePenal'
import Utilisateur from '@/views/gestionAccess/Utilisateur'
import Detaillant from '@/views/gestionAccess/Detaillant'
import StructureCoopec from '@/views/parametre/StructureCoopec'
import GrilleCommission from '@/views/parametre/GrilleCommission'

import Brouillard from '@/views/Brouillard/Brouillard'
import ModuleApplication from '@/views/presentation/ModuleApplication'
const AppRoutes = [
   // { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound, meta: {layout: "no-sidebar"} },
   {
      path: '/grille-commssion',
      name: "GrilleCommission",
      meta: { requiresAuth: true },
      component: GrilleCommission
   }, 
    {
      path: '/brouillard-de-caisse',
      name: "Brouillard",
      meta: { requiresAuth: true },
      component: Brouillard
   },
   {
      path: '/module',
      name: "ModuleApplication",
      meta: { requiresAuth: true },
      component: ModuleApplication,
      meta: {layout: "no-sidebar"}
   },
   {
      path: '/structure-coopec',
      name: "StructureCoopec",
      meta: { requiresAuth: true },
      component: StructureCoopec
   },
 {
      path: '/caisses',
      name: "CodePenal",
      meta: { requiresAuth: true },
      component: CodePenal
   }
   ,
   {
      path: '/utilisateur',
      name: "Utilisateur",
      meta: { requiresAuth: true },
      component: Utilisateur
   },
   {
      path: '/Detaillant',
      name: "Detaillant",
      meta: { requiresAuth: true },
      component: Detaillant
   },
   {
      path: '/saisie-journaux',
      name: "Home",
      meta: { requiresAuth: true },
      component: Home
   },

   {
      path: '/compte_tier',
      name: "CriteIngigence",
      meta: { requiresAuth: true },
      component: CriteIngigence
   },

   {
      path: '/compte_generals',
      name: "Structure",
      meta: { requiresAuth: true },
      component: Structure
   },
   
   {
      path: '/localisation-geographique',
      name: "LocalisationGeographique",
      meta: { requiresAuth: true },
      component: LocalisationGeographique
   }



]

export default AppRoutes;
