<template>
   <div>
   <ul class="nav">
					<li class="nav-header">Navigation</li>
					
					
					<li :class="{active: menu_active == 1 }" @click.prevent="activedOptionMenu(1,'PresenceApv2')">
						<a href="widget.html">
						<i class="fas fa-book fa-fw"></i>
							<span>Liste APV2</span> 
						</a>
					</li>
                    <li :class="{active: menu_active == 2 }" @click.prevent="activedOptionMenu(2,'CodeQr')">
						<a href="widget.html">
						<i class="fas fa-book fa-fw"></i>
							<span>Code QR</span> 
						</a>
					</li>
                      <li :class="{active: menu_active == 3 }" @click.prevent="activedOptionMenu(3,'Catotographie')">
						<a href="widget.html">
						<i class="fas fa-book fa-fw"></i>
							<span>Cartographie des Présences </span> 
						</a>
					</li>
					<li class="has-sub">
						<a href="javascript:;">
							<b class="caret"></b>
							<i class="fa fa-file"></i>
							<span>Etat</span>
						</a>
						<ul class="sub-menu" >
							<li :class="{active: menu_active == 10 }"><a href="#">Fiche de présences</a></li>
							
						</ul>
					</li>
					
					
					
					
				
					<li><a href="javascript:;" class="sidebar-minify-btn" data-click="sidebar-minify"><i class="fa fa-angle-double-left"></i></a></li>
					<!-- end sidebar minify button -->
				</ul>
				<!-- end sidebar nav -->
   </div>



</template>
<script>
 import TheNavClotureProject from "./TheNavClotureProject";
 import TheNavExecutionProject from "./TheNavExecutionProject";
 import TheNavInitialisationProject from "./TheNavInitialisationProject";
 import TheNavPlanAction from "./TheNavPlanAction";
 import TheNavPlanificationProjet from "./TheNavPlanificationProjet";

 import TheNavSuivieMaitrise from "./TheNavSuivieMaitrise";
  import NavAccueil from "./NavAccueil";

import { mapState, mapActions ,mapMutations,mapGetters} from "vuex";
    export default {
		components: {
			TheNavSuivieMaitrise,
			NavAccueil,
			TheNavClotureProject,
			TheNavExecutionProject,
			TheNavInitialisationProject,
			TheNavPlanAction,
			TheNavPlanificationProjet
		},
        name: "TheNavBar",
        data() {
            return {
		menu_active:1,
		urlBase:process.env.VUE_APP_BASE,
		array1:[1,3],
		array2:[2,3],
            }
        },
		 created(){
			 
			 
        },

computed: {
...mapGetters("Utilisateurs",["getterUserStore"]),
       ...mapState('parametrageMenu', {
     active_el: state => state.active_el
  }),
  nameUser(){
	  if(!this.getterUserStore) return "JUSTICE"

	  let objet=this.getterUserStore
	  return objet.name
  },
 
   inArray() {
      return (valeur, tableau)=>{
         let length = tableau.length;
        for(let i = 0; i < length; i++) {
            if(tableau[i] == valeur) return true;
        }
    return false;
      }
   
},
  imageUsers(){
	    if(this.getterUserStore.profile_photo_path){
			  let id_user=this.getterUserStore.id
			  return this.urlBase+'/users/'+id_user+"/"+this.getterUserStore.profile_photo_path
		  }
		    return this.urlBase+"/users/default/1.jpg"
  },
  codeUser(){
	  if(!this.getterUserStore) return 0
	  return this.getterUserStore.role
  }
},
        methods: {
        ...mapActions('Utilisateurs', ['logoutUser']),
			afficheMenu(value){
	this.activate(value)
			},
				activedOptionMenu(value,route_name){
		  this.menu_active= value
		  this.$router.push({
        name: route_name
      })
	  
	   }
        }
    }
</script>

<style scoped>

</style>
