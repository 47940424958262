import apiGuest from '../../../request/auth-request'
import authHeader from '../../../services/auth-header';

/**
 * 
 * ACTION PROJET
 */


/**
 * ACTION STRUCTURE GEOGRAPHIQUE
 * @param {} param0 
 */
export function getStructureGeoGraphique({ commit }) {
    commit("SET_LOADING_STRUCTURE_GEGRAPHIQUE", true)

    apiGuest.get('/journaux', { headers: authHeader() }).then(response => {
        commit('GET_STRUCTURE_GEGRAPHIQUE', response.data)
        commit("SET_LOADING_STRUCTURE_GEGRAPHIQUE", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_STRUCTURE_GEGRAPHIQUE", false)
    })
}

export function teletest({ commit,dispatch }, formData) {
    commit("SET_LOADING_STRUCTURE_GEGRAPHIQUE", true)
    apiGuest.post('/tele', formData, { headers: authHeader() }).then(response => {
       commit('')
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const fileName = `${+ new Date()}.txt`// whatever your file name .
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            commit("SET_LOADING_STRUCTURE_GEGRAPHIQUE", false)
            commit("SET_ERROR_STRUCTURE_GEGRAPHIQUE", false)

    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_STRUCTURE_GEGRAPHIQUE", false)
        commit("SET_ERROR_STRUCTURE_GEGRAPHIQUE", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                ù2title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}

export function ajouterStructureGeoGraphique({ commit,dispatch }, formData) {
    commit("SET_LOADING_STRUCTURE_GEGRAPHIQUE", true)
    apiGuest.post('/journaux', formData, { headers: authHeader() }).then(response => {
        if (response.status == 201) {

            commit('AJOUTER_STRUCTURE_GEGRAPHIQUE', response.data)
            this.$app.$notify({
                title: 'success ',
                text: 'Enregistrement effectué !',
                type: "success"
            })
            commit("SET_LOADING_STRUCTURE_GEGRAPHIQUE", false)
            commit("SET_ERROR_STRUCTURE_GEGRAPHIQUE", false)
            dispatch('getStructureGeoGraphique')
        }

    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_STRUCTURE_GEGRAPHIQUE", false)
        commit("SET_ERROR_STRUCTURE_GEGRAPHIQUE", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                ù2title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}


export function supprimerStructureGeographique({ commit }, id) {

    commit("SET_LOADING_STRUCTURE_GEGRAPHIQUE", true)
    commit('SUPPRIMER_STRUCTURE_GEGRAPHIQUE', id)
    apiGuest.delete('/journaux/' + id, { headers: authHeader() }).then(() => {
        commit("SET_LOADING_STRUCTURE_GEGRAPHIQUE", false)
        this.$app.$notify({
            title: 'success',
            text: 'Suppression effectuer !',
            type: "success"
        })
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_STRUCTURE_GEGRAPHIQUE", false)
        this.$app.$notify({
            title: 'error',
            text: "Une erreur c'est produite lors de la suppression ",
            type: "warning"
        })
    })

}



export function modificationStructureGeoGraphique({ commit }, formData) {
commit("SET_LOADING_STRUCTURE_GEGRAPHIQUE", true)
    apiGuest.put('/journaux/' + formData.id, formData, { headers: authHeader() }).then(response => {
        commit('MODIFIER_STRUCTURE_GEGRAPHIQUE', response.data)
        this.$app.$notify({
            title: 'success ',
            text: 'Enregistrement effectué !',
            type: "success"
        })
        commit("SET_LOADING_STRUCTURE_GEGRAPHIQUE", false)
        commit("SET_ERROR_STRUCTURE_GEGRAPHIQUE", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_STRUCTURE_GEGRAPHIQUE", false)
        commit("SET_ERROR_STRUCTURE_GEGRAPHIQUE", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}


/**
 * ACTION ELEMENT DOSSIER
 */








/**
 * Type Personne Tribuna
 */



export function getTypePersonneTribunal({ commit }) {
    commit("SET_LOADING_TYPE_PERSONNE_TRIBUNAL", true)

    apiGuest.get('/typeperstribunal', { headers: authHeader() }).then(response => {
        commit('GET_TYPE_PERSONNE_TRIBUNAL', response.data)
        commit("SET_LOADING_TYPE_PERSONNE_TRIBUNAL", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_TYPE_PERSONNE_TRIBUNAL", false)
    })
}

export function ajouterTypePersonneTribunal({ commit }, formData) {
    commit("SET_LOADING_TYPE_PERSONNE_TRIBUNAL", true)
    apiGuest.post('/typeperstribunal', formData, { headers: authHeader() }).then(response => {
        if (response.status == 201) {

            commit('AJOUTER_TYPE_PERSONNE_TRIBUNAL', response.data)
            this.$app.$notify({
                title: 'success ',
                text: 'Enregistrement effectué !',
                type: "success"
            })
            commit("SET_LOADING_TYPE_PERSONNE_TRIBUNAL", false)
            commit("SET_ERROR_TYPE_PERSONNE_TRIBUNAL", false)
        }

    }).catch(error => {

        commit("SET_LOADING_TYPE_PERSONNE_TRIBUNAL", false)
        commit("SET_ERROR_TYPE_PERSONNE_TRIBUNAL", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                ù2title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}


export function supprimerTypePersonneTribunal({ commit }, id) {

    commit("SET_LOADING_TYPE_PERSONNE_TRIBUNAL", true)
    commit('SUPPRIMER_TYPE_PERSONNE_TRIBUNAL', id)
    apiGuest.delete('/typeperstribunal/' + id, { headers: authHeader() }).then(() => {
        commit("SET_LOADING_TYPE_PERSONNE_TRIBUNAL", false)
        this.$app.$notify({
            title: 'success',
            text: 'Suppression effectuer !',
            type: "success"
        })
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_TYPE_PERSONNE_TRIBUNAL", false)
        this.$app.$notify({
            title: 'error',
            text: "Une erreur c'est produite lors de la suppression ",
            type: "warning"
        })
    })

}



export function modificationTypePersonneTribunal({ commit }, formData) {
    commit("SET_LOADING_TYPE_PERSONNE_TRIBUNAL", true)
    apiGuest.put('/typeperstribunal/' + formData.id, formData, { headers: authHeader() }).then(response => {
        commit('MODIFIER_TYPE_PERSONNE_TRIBUNAL', response.data)
        this.$app.$notify({
            title: 'success ',
            text: 'Enregistrement effectué !',
            type: "success"
        })
        commit("SET_LOADING_TYPE_PERSONNE_TRIBUNAL", false)
        commit("SET_ERROR_TYPE_PERSONNE_TRIBUNAL", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_TYPE_PERSONNE_TRIBUNAL", false)
        commit("SET_ERROR_TYPE_PERSONNE_TRIBUNAL", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}




/**
 * Type Acteur Juridique
 */







export function getTypeActeurJuridique({ commit }) {
    commit("SET_LOADING_TYPE_ACTEUR_JURIDIQUE", true)

    apiGuest.get('/typeacteur', { headers: authHeader() }).then(response => {
        commit('GET_TYPE_ACTEUR_JURIDIQUE', response.data)
        commit("SET_LOADING_TYPE_ACTEUR_JURIDIQUE", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_TYPE_ACTEUR_JURIDIQUE", false)
    })
}

export function ajouterTypeActeurJurique({ commit }, formData) {
    commit("SET_LOADING_TYPE_ACTEUR_JURIDIQUE", true)
    apiGuest.post('/typeacteur', formData, { headers: authHeader() }).then(response => {
        if (response.status == 201) {

            commit('AJOUTER_TYPE_ACTEUR_JURIDIQUE', response.data)
            this.$app.$notify({
                title: 'success ',
                text: 'Enregistrement effectué !',
                type: "success"
            })
            commit("SET_LOADING_TYPE_ACTEUR_JURIDIQUE", false)
            commit("SET_ERROR_TYPE_ACTEUR_JURIDIQUE", false)
        }

    }).catch(error => {

        commit("SET_LOADING_TYPE_ACTEUR_JURIDIQUE", false)
        commit("SET_ERROR_TYPE_ACTEUR_JURIDIQUE", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                ù2title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}


export function ajouterTypeAc1teurJurique({ commit }, formData) {
    commit('AJOUTER_PARAM', formData)
}

// export function getTypeActeurJuridique({ commit }) {
//         commit('GET_TYPE_ACTEUR_JURIDIQUE', response.data)
//         commit("SET_LOADING_TYPE_ACTEUR_JURIDIQUE", false)

// }




export function supprimerdataParam({ commit }, data) {
    commit('Vidder_PARAM', data)
}


export function supprimerTypeActeurJuridique({ commit }, id) {

    commit("SET_LOADING_TYPE_ACTEUR_JURIDIQUE", true)
    commit('SUPPRIMER_TYPE_ACTEUR_JURIDIQUE', id)
    apiGuest.delete('/typeacteur/' + id, { headers: authHeader() }).then(() => {
        commit("SET_LOADING_TYPE_ACTEUR_JURIDIQUE", false)
        this.$app.$notify({
            title: 'success',
            text: 'Suppression effectuer !',
            type: "success"
        })
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_TYPE_ACTEUR_JURIDIQUE", false)
        this.$app.$notify({
            title: 'error',
            text: "Une erreur c'est produite lors de la suppression ",
            type: "warning"
        })
    })

}



export function modificationTypeActeurJuridique({ commit }, formData) {
    commit("SET_LOADING_TYPE_ACTEUR_JURIDIQUE", true)
    apiGuest.put('/typeacteur/' + formData.id, formData, { headers: authHeader() }).then(response => {
        commit('MODIFIER_TYPE_ACTEUR_JURIDIQUE', response.data)
        this.$app.$notify({
            title: 'success ',
            text: 'Enregistrement effectué !',
            type: "success"
        })
        commit("SET_LOADING_TYPE_ACTEUR_JURIDIQUE", false)
        commit("SET_ERROR_TYPE_ACTEUR_JURIDIQUE", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_TYPE_ACTEUR_JURIDIQUE", false)
        commit("SET_ERROR_TYPE_ACTEUR_JURIDIQUE", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}




/**
 * Nature de dossier
 */






export function getNatureDossier({ commit }) {
    commit("SET_LOADING_NATURE_DOSSIER", true)

    apiGuest.get('/naturedossier', { headers: authHeader() }).then(response => {
        commit('GET_NATURE_DOSSIER', response.data)
        commit("SET_LOADING_NATURE_DOSSIER", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_NATURE_DOSSIER", false)
    })
}

export function ajouterNatureDossier({ commit }, formData) {
    commit("SET_LOADING_NATURE_DOSSIER", true)
    apiGuest.post('/naturedossier', formData, { headers: authHeader() }).then(response => {
        if (response.status == 201) {
            console.log(response.data)
            commit('AJOUTER_NATURE_DOSSIER', response.data)
            this.$app.$notify({
                title: 'success ',
                text: 'Enregistrement effectué !',
                type: "success"
            })
            commit("SET_LOADING_NATURE_DOSSIER", false)
            commit("SET_ERROR_NATURE_DOSSIER", false)
        }

    }).catch(error => {

        commit("SET_LOADING_NATURE_DOSSIER", false)
        commit("SET_ERROR_NATURE_DOSSIER", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                ù2title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}


export function supprimerNatureDossier({ commit }, id) {

    commit("SET_LOADING_NATURE_DOSSIER", true)
    commit('SUPPRIMER_NATURE_DOSSIER', id)
    apiGuest.delete('/naturedossier/' + id, { headers: authHeader() }).then(() => {
        commit("SET_LOADING_NATURE_DOSSIER", false)
        this.$app.$notify({
            title: 'success',
            text: 'Suppression effectuer !',
            type: "success"
        })
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_NATURE_DOSSIER", false)
        this.$app.$notify({
            title: 'error',
            text: "Une erreur c'est produite lors de la suppression ",
            type: "warning"
        })
    })

}



export function modificationNatureDossier({ commit }, formData) {
    commit("SET_LOADING_NATURE_DOSSIER", true)
    apiGuest.put('/naturedossier/' + formData.id, formData, { headers: authHeader() }).then(response => {
        commit('MODIFIER_NATURE_DOSSIER', response.data)
        this.$app.$notify({
            title: 'success ',
            text: 'Enregistrement effectué !',
            type: "success"
        })
        commit("SET_LOADING_NATURE_DOSSIER", false)
        commit("SET_ERROR_NATURE_DOSSIER", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_NATURE_DOSSIER", false)
        commit("SET_ERROR_NATURE_DOSSIER", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}




/**
 * Motif partie partie requerant
 */







export function getMotifPartie({ commit }) {
    commit("SET_LOADING_MOTIF_PARTIE", true)

    apiGuest.get('/motifpartie', { headers: authHeader() }).then(response => {
        commit('GET_MOTIF_PARTIE', response.data)
        commit("SET_LOADING_MOTIF_PARTIE", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_MOTIF_PARTIE", false)
    })
}

export function ajouterMotifPartie({ commit }, formData) {
    commit("SET_LOADING_MOTIF_PARTIE", true)
    apiGuest.post('/motifpartie', formData, { headers: authHeader() }).then(response => {
        if (response.status == 201) {
            console.log(response.data)
            commit('AJOUTER_MOTIF_PARTIE', response.data)
            this.$app.$notify({
                title: 'success ',
                text: 'Enregistrement effectué !',
                type: "success"
            })
            commit("SET_LOADING_MOTIF_PARTIE", false)
            commit("SET_ERROR_MOTIF_PARTIE", false)
        }

    }).catch(error => {

        commit("SET_LOADING_MOTIF_PARTIE", false)
        commit("SET_ERROR_MOTIF_PARTIE", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                ù2title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}


export function supprimerMotifPartie({ commit }, id) {

    commit("SET_LOADING_MOTIF_PARTIE", true)
    commit('SUPPRIMER_MOTIF_PARTIE', id)
    apiGuest.delete('/motifpartie/' + id, { headers: authHeader() }).then(() => {
        commit("SET_LOADING_MOTIF_PARTIE", false)
        this.$app.$notify({
            title: 'success',
            text: 'Suppression effectuer !',
            type: "success"
        })
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_MOTIF_PARTIE", false)
        this.$app.$notify({
            title: 'error',
            text: "Une erreur c'est produite lors de la suppression ",
            type: "warning"
        })
    })

}



export function modificationMotifPartie({ commit }, formData) {
    commit("SET_LOADING_MOTIF_PARTIE", true)
   // console.log(formData)
    apiGuest.put('/motifpartie/' + formData.id, formData, { headers: authHeader() }).then(response => {
        commit('MODIFIER_MOTIF_PARTIE', response.data)
        this.$app.$notify({
            title: 'success ',
            text: 'Enregistrement effectué !',
            type: "success"
        })
        commit("SET_LOADING_MOTIF_PARTIE", false)
        commit("SET_ERROR_MOTIF_PARTIE", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_MOTIF_PARTIE", false)
        commit("SET_ERROR_MOTIF_PARTIE", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}



/**
 * Mode de saisine
 */



export function getCriteIndigence({ commit }) {
    commit("SET_LOADING_CRITE_INDIGENCE", true)

    apiGuest.get('/compte_tier', { headers: authHeader() }).then(response => {
        commit('GET_CRITE_INDIGENCE', response.data)
        commit("SET_LOADING_CRITE_INDIGENCE", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_CRITE_INDIGENCE", false)
    })
}

export function ajouterCriteIndigence({ commit }, formData) {
    commit("SET_LOADING_CRITE_INDIGENCE", true)
    apiGuest.post('/compte_tier', formData, { headers: authHeader() }).then(response => {
        if (response.status == 201) {
            console.log(response.data)
            commit('AJOUTER_CRITE_INDIGENCE', response.data)
            this.$app.$notify({
                title: 'success ',
                text: 'Enregistrement effectué !',
                type: "success"
            })
            commit("SET_LOADING_CRITE_INDIGENCE", false)
            commit("SET_ERROR_CRITE_INDIGENCE", false)
        }

    }).catch(error => {

        commit("SET_LOADING_CRITE_INDIGENCE", false)
        commit("SET_ERROR_CRITE_INDIGENCE", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                ù2title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}


export function supprimerCriteIndigence({ commit }, id) {

    commit("SET_LOADING_CRITE_INDIGENCE", true)
    commit('SUPPRIMER_CRITE_INDIGENCE', id)
    apiGuest.delete('/compte_tier/' + id, { headers: authHeader() }).then(() => {
        commit("SET_LOADING_CRITE_INDIGENCE", false)
        this.$app.$notify({
            title: 'success',
            text: 'Suppression effectuer !',
            type: "success"
        })
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_CRITE_INDIGENCE", false)
        this.$app.$notify({
            title: 'error',
            text: "Une erreur c'est produite lors de la suppression ",
            type: "warning"
        })
    })

}



export function modificationCriteIndigence({ commit }, formData) {
    commit("SET_LOADING_CRITE_INDIGENCE", true)
    console.log(formData)
    apiGuest.put('/compte_tier/' + formData.id, formData, { headers: authHeader() }).then(response => {
        commit('MODIFIER_CRITE_INDIGENCE', response.data)
        this.$app.$notify({
            title: 'success ',
            text: 'Enregistrement effectué !',
            type: "success"
        })
        commit("SET_LOADING_CRITE_INDIGENCE", false)
        commit("SET_ERROR_CRITE_INDIGENCE", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_CRITE_INDIGENCE", false)
        commit("SET_ERROR_CRITE_INDIGENCE", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}







/**
 * Action victimologie
 */








export function getCriteVictimologie({ commit }) {
    commit("SET_LOADING_CRITE_VICTIMOLOGIE", true)

    apiGuest.get('/criterevictimologie', { headers: authHeader() }).then(response => {
        commit('GET_CRITE_VICTIMOLOGIE', response.data)
        commit("SET_LOADING_CRITE_VICTIMOLOGIE", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_CRITE_VICTIMOLOGIE", false)
    })
}

export function ajouterCriteVictimologie({ commit }, formData) {
    commit("SET_LOADING_CRITE_VICTIMOLOGIE", true)
    apiGuest.post('/criterevictimologie', formData, { headers: authHeader() }).then(response => {
        if (response.status == 201) {
            console.log(response.data)
            commit('AJOUTER_CRITE_VICTIMOLOGIE', response.data)
            this.$app.$notify({
                title: 'success ',
                text: 'Enregistrement effectué !',
                type: "success"
            })
            commit("SET_LOADING_CRITE_VICTIMOLOGIE", false)
            commit("SET_ERROR_CRITE_VICTIMOLOGIE", false)
        }

    }).catch(error => {

        commit("SET_LOADING_CRITE_VICTIMOLOGIE", false)
        commit("SET_ERROR_CRITE_VICTIMOLOGIE", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                ù2title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}


export function supprimerCriteVictimologie({ commit }, id) {

    commit("SET_LOADING_CRITE_VICTIMOLOGIE", true)
    commit('SUPPRIMER_CRITE_VITIMOLOGIE', id)
    apiGuest.delete('/criterevictimologie/' + id, { headers: authHeader() }).then(() => {
        commit("SET_LOADING_CRITE_VICTIMOLOGIE", false)
        this.$app.$notify({
            title: 'success',
            text: 'Suppression effectuer !',
            type: "success"
        })
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_CRITE_VICTIMOLOGIE", false)
        this.$app.$notify({
            title: 'error',
            text: "Une erreur c'est produite lors de la suppression ",
            type: "warning"
        })
    })

}



export function modificationCriteVictologie({ commit }, formData) {
    commit("SET_LOADING_CRITE_VICTIMOLOGIE", true)
    console.log(formData)
    apiGuest.put('/criterevictimologie/' + formData.id, formData, { headers: authHeader() }).then(response => {
        commit('MODIFIER_CRITE_VICTIMOLOGIE', response.data)
        this.$app.$notify({
            title: 'success ',
            text: 'Enregistrement effectué !',
            type: "success"
        })
        commit("SET_LOADING_CRITE_VICTIMOLOGIE", false)
        commit("SET_ERROR_CRITE_VICTIMOLOGIE", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_CRITE_VICTIMOLOGIE", false)
        commit("SET_ERROR_CRITE_VICTIMOLOGIE", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}




/**
 * Localisation
 */








export function getLocalisationGeographique({ commit }) {
    commit("SET_LOADING_LOCALISATION_GEOGRAPHIQUE", true)

    apiGuest.get('/localites', { headers: authHeader() }).then(response => {
        commit('GET_LOCALISATION_GEOGRAPHIQUE', response.data)
        commit("SET_LOADING_LOCALISATION_GEOGRAPHIQUE", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_LOCALISATION_GEOGRAPHIQUE", false)
    })
}

export function ajouterLocalisationGeographique({ commit }, formData) {
    commit("SET_LOADING_LOCALISATION_GEOGRAPHIQUE", true)
    apiGuest.post('/localites', formData, { headers: authHeader() }).then(response => {
        if (response.status == 201) {
            console.log(response.data)
            commit('AJOUTER_LOCALISATION_GEOGRAPHIQUE', response.data)
            this.$app.$notify({
                title: 'success ',
                text: 'Enregistrement effectué !',
                type: "success"
            })
            commit("SET_LOADING_LOCALISATION_GEOGRAPHIQUE", false)
            commit("SET_ERROR_LOCALISATION_GEOGRAPHIQUE", false)
        }

    }).catch(error => {

        commit("SET_LOADING_LOCALISATION_GEOGRAPHIQUE", false)
        commit("SET_ERROR_LOCALISATION_GEOGRAPHIQUE", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                ù2title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}


export function supprimerLocalisationGeographique({ commit }, id) {

    commit("SET_LOADING_LOCALISATION_GEOGRAPHIQUE", true)
    commit('SUPPRIMER_LOCALISATION_GEOGRAPHIQUE', id)
    apiGuest.delete('/localites/' + id, { headers: authHeader() }).then(() => {
        commit("SET_LOADING_LOCALISATION_GEOGRAPHIQUE", false)
        this.$app.$notify({
            title: 'success',
            text: 'Suppression effectuer !',
            type: "success"
        })
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_LOCALISATION_GEOGRAPHIQUE", false)
        this.$app.$notify({
            title: 'error',
            text: "Une erreur c'est produite lors de la suppression ",
            type: "warning"
        })
    })

}



export function modificationLocalisationGeographique({ commit }, formData) {
    commit("SET_LOADING_LOCALISATION_GEOGRAPHIQUE", true)
    console.log(formData)
    apiGuest.put('/localites/' + formData.id, formData, { headers: authHeader() }).then(response => {
        commit('MODIFIER_LOCALISATION_GEOGRAPHIQUE', response.data)
        this.$app.$notify({
            title: 'success ',
            text: 'Enregistrement effectué !',
            type: "success"
        })
        commit("SET_LOADING_LOCALISATION_GEOGRAPHIQUE", false)
        commit("SET_ERROR_LOCALISATION_GEOGRAPHIQUE", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_LOCALISATION_GEOGRAPHIQUE", false)
        commit("SET_ERROR_LOCALISATION_GEOGRAPHIQUE", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}




/**
 * Localisation
 */










//gestion des localite saisine

export function getLocaliteSaisine({ commit }) {
    commit("SET_LOADING_LOCALITE_SAISINE", true)

    apiGuest.get('/localitesaisine', { headers: authHeader() }).then(response => {
        commit('GET_LOCALITE_SAISINE', response.data)
        commit("SET_LOADING_LOCALITE_SAISINE", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_LOCALITE_SAISINE", false)
    })
}

export function ajouterLocaliteSaisine({ commit }, formData) {
    commit("SET_LOADING_LOCALITE_SAISINE", true)
    apiGuest.post('/localitesaisine', formData, { headers: authHeader() }).then(response => {

        if (response.status == 201) {

            commit('AJOUTER_LOCALITE_SAISINE', response.data)
            this.$app.$notify({
                title: 'success ',
                text: 'Enregistrement effectué !',
                type: "success"
            })
            commit("SET_LOADING_LOCALITE_SAISINE", false)
            commit("SET_ERROR_LOCALITE_SAISINE", false)
        }

    }).catch(error => {

        commit("SET_LOADING_LOCALITE_SAISINE", false)
        commit("SET_ERROR_LOCALITE_SAISINE", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                ù2title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}


export function supprimerLocaliteSaisine({ commit }, id) {

    commit("SET_LOADING_LOCALITE_SAISINE", true)
    commit('SUPPRIMER_LOCALITE_SAISINE', id)
    apiGuest.delete('/localitesaisine/' + id, { headers: authHeader() }).then(() => {
        commit("SET_LOADING_LOCALITE_SAISINE", false)
        this.$app.$notify({
            title: 'success',
            text: 'Suppression effectuer !',
            type: "success"
        })
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_LOCALITE_SAISINE", false)
        this.$app.$notify({
            title: 'error',
            text: "Une erreur c'est produite lors de la suppression ",
            type: "warning"
        })
    })

}



export function modificationLocaliteSaisine({ commit }, formData) {
    commit("SET_LOADING_LOCALITE_SAISINE", true)
    console.log(formData)
    apiGuest.put('/localitesaisine/' + formData.id, formData, { headers: authHeader() }).then(response => {
        commit('MODIFIER_LOCALITE_SAISINE', response.data)
        this.$app.$notify({
            title: 'success ',
            text: 'Enregistrement effectué !',
            type: "success"
        })
        commit("SET_LOADING_LOCALITE_SAISINE", false)
        commit("SET_ERROR_LOCALITE_SAISINE", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_LOCALITE_SAISINE", false)
        commit("SET_ERROR_LOCALITE_SAISINE", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}



//gestion des articles du code penal

export function getCodePenal({ commit }) {
    commit("SET_LOADING_CODE_PENAL", true)

    apiGuest.get('/caisses', { headers: authHeader() }).then(response => {
        commit('GET_CODE_PENAL', response.data)
        commit("SET_LOADING_CODE_PENAL", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_CODE_PENAL", false)
    })
}

export function ajouterCodePenal({ commit }, formData) {
    commit("SET_LOADING_CODE_PENAL", true)
    apiGuest.post('/caisses', formData, { headers: authHeader() }).then(response => {

        if (response.status == 201) {

            commit('AJOUTER_CODE_PENAL', response.data)
            this.$app.$notify({
                title: 'success ',
                text: 'Enregistrement effectué !',
                type: "success"
            })
            commit("SET_LOADING_CODE_PENAL", false)
            commit("SET_ERROR_CODE_PENAL", false)
        }

    }).catch(error => {

        commit("SET_LOADING_CODE_PENAL", false)
        commit("SET_ERROR_CODE_PENAL", true)
        console.log(erro);
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                ù2title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}


export function supprimerCodePenal({ commit }, id) {

    commit("SET_LOADING_CODE_PENAL", true)
    commit('SUPPRIMER_CODE_PENAL', id)
    apiGuest.delete('/caisses/' + id, { headers: authHeader() }).then(() => {
        commit("SET_LOADING_CODE_PENAL", false)
        this.$app.$notify({
            title: 'success',
            text: 'Suppression effectuer !',
            type: "success"
        })
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_CODE_PENAL", false)
        this.$app.$notify({
            title: 'error',
            text: "Une erreur c'est produite lors de la suppression ",
            type: "warning"
        })
    })

}



export function modificationCodePenal({ commit }, formData) {
    commit("SET_LOADING_CODE_PENAL", true)
    console.log(formData)
    apiGuest.put('/caisses/' + formData.id, formData, { headers: authHeader() }).then(response => {
        commit('MODIFIER_CODE_PENAL', response.data)
        this.$app.$notify({
            title: 'success ',
            text: 'Enregistrement effectué !',
            type: "success"
        })
        commit("SET_LOADING_CODE_PENAL", false)
        commit("SET_ERROR_CODE_PENAL", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_CODE_PENAL", false)
        commit("SET_ERROR_CODE_PENAL", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}






/**
 * Action fiche entretien
 */


export function getFicheEntretien({ commit }) {
    commit("SET_LOADING_FICHE_ENTRETIEN", true)
    apiGuest.get('/ficheentretien', { headers: authHeader() }).then(response => {
        commit('GET_FICHE_ENTRETIEN', response.data)
        commit("SET_LOADING_FICHE_ENTRETIEN", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_FICHE_ENTRETIEN", false)
    })
}

export function ajouterFicheEntretien({ commit, dispatch }, formData) {

    return new Promise((resolve,reject)=>{

        commit("SET_LOADING_FICHE_ENTRETIEN", true)
        apiGuest.post('/ficheentretien', formData, { headers: authHeader() }).then(response => {
            if (response.status == 201) {
    
                commit('AJOUTER_FICHE_ENTRETIEN', response.data)
                this.$app.$notify({
                    title: 'success ',
                    text: 'Enregistrement effectué !',
                    type: "success"
                })
                commit("SET_LOADING_FICHE_ENTRETIEN", false)
                commit("SET_ERROR_FICHE_ENTRETIEN", false)
                dispatch("getFicheEntretien")
                resolve(response)
            }
    
        }).catch(error => {
            console.log(error)
            commit("SET_LOADING_FICHE_ENTRETIEN", false)
            commit("SET_ERROR_FICHE_ENTRETIEN", true)
            if (error.response == undefined) {
                this.$app.$notify({
                    title: 'error',
                    text: "Veuillez verifier votre connexion et réessayer ",
                    type: "warning"
                })
            } else {
                this.$app.$notify({
                    ù2title: 'error',
                    text: error.response.data.message,
                    type: "warning"
                })
            }
            reject(error)
        })

    })
  
}


export function supprimerFicheEntretien({ commit }, id) {

    commit("SET_LOADING_FICHE_ENTRETIEN", true)
    commit('SUPPRIMER_FICHE_ENTRETIEN', id)
    apiGuest.delete('/ficheentretien/' + id, { headers: authHeader() }).then(() => {
        commit("SET_LOADING_FICHE_ENTRETIEN", false)
        this.$app.$notify({
            title: 'success',
            text: 'Suppression effectuer !',
            type: "success"
        })
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_FICHE_ENTRETIEN", false)
        this.$app.$notify({
            title: 'error',
            text: "Une erreur c'est produite lors de la suppression ",
            type: "warning"
        })
    })

}



export function modificationFicheEntretien({ commit, dispatch }, formData) {
    commit("SET_LOADING_FICHE_ENTRETIEN", true)
    apiGuest.put('/ficheentretien/' + formData.id, formData, { headers: authHeader() }).then(response => {
        commit('MODIFIER_FICHE_ENTRETIEN', response.data)
        this.$app.$notify({
            title: 'success ',
            text: 'Enregistrement effectué !',
            type: "success"
        })

        commit("SET_LOADING_FICHE_ENTRETIEN", false)
        commit("SET_ERROR_FICHE_ENTRETIEN", false)
        dispatch("getFicheEntretien")
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_FICHE_ENTRETIEN", false)
        commit("SET_ERROR_FICHE_ENTRETIEN", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}


/**
 * Action dossier maison justice  listeentretien
 */



export function getDossierMaisonJustice({ commit }) {
    commit("SET_LOADING_DOSSIER_MAISON_JUSTICE", true)
    apiGuest.get('/dossiermaisonjustice', { headers: authHeader() }).then(response => {
        commit('GET_DOSSIER_MAISON_JUSTICE', response.data)
        commit("SET_LOADING_DOSSIER_MAISON_JUSTICE", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_DOSSIER_MAISON_JUSTICE", false)
    })
}

export function ajouterDossierMaisonJustice({ commit, dispatch }, formData) {
    commit("SET_LOADING_DOSSIER_MAISON_JUSTICE", true)
    apiGuest.post('/dossiermaisonjustice', formData, { headers: authHeader() }).then(response => {
        if (response.status == 201) {

            commit('AJOUTER_DOSSIER_MAISON_JUSTICE', response.data)
            this.$app.$notify({
                title: 'success ',
                text: 'Enregistrement effectué !',
                type: "success"
            })
            commit("SET_LOADING_DOSSIER_MAISON_JUSTICE", false)
            commit("SET_ERROR_DOSSIER_MAISON_JUSTICE", false)
            dispatch("getDossierMaisonJustice")
        }

    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_DOSSIER_MAISON_JUSTICE", false)
        commit("SET_ERROR_DOSSIER_MAISON_JUSTICE", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                ù2title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}


export function ajouterDossierCompromis({ commit, dispatch }, formData) {
    commit("SET_LOADING_DOSSIER_MAISON_JUSTICE", true)
    apiGuest.post('/createcompromis', formData, { headers: authHeader() }).then(response => {
        if (response.status == 201) {

            commit('AJOUTER_DOSSIER_MAISON_COMPROMIS', response.data)
            this.$app.$notify({
                title: 'success ',
                text: 'Enregistrement effectué !',
                type: "success"
            })
            commit("SET_LOADING_DOSSIER_MAISON_JUSTICE", false)
            commit("SET_ERROR_DOSSIER_MAISON_JUSTICE", false)
            dispatch("getDossierMaisonJustice")
        }

    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_DOSSIER_MAISON_JUSTICE", false)
        commit("SET_ERROR_DOSSIER_MAISON_JUSTICE", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                ù2title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}

export function supprimerDossierMaisonJustice({ commit }, id) {

    commit("SET_LOADING_DOSSIER_MAISON_JUSTICE", true)
    commit('SUPPRIMER_DOSSIER_MAISON_JUSTICE', id)
    apiGuest.delete('/dossiermaisonjustice/' + id, { headers: authHeader() }).then(() => {
        commit("SET_LOADING_DOSSIER_MAISON_JUSTICE", false)
        this.$app.$notify({
            title: 'success',
            text: 'Suppression effectuer !',
            type: "success"
        })
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_DOSSIER_MAISON_JUSTICE", false)
        this.$app.$notify({
            title: 'error',
            text: "Une erreur c'est produite lors de la suppression ",
            type: "warning"
        })
    })

}

export function modificationDateentretien({ commit, dispatch }, formData) {
    commit("SET_LOADING_DOSSIER_MAISON_JUSTICE", true)
    console.log(formData)
    apiGuest.put('/editdate_entretien/' + formData.dossier_maisons_id, formData, { headers: authHeader() }).then(response => {
        console.log(response.data)
        commit('MODIFIER_DATE', response.data)
        
        this.$app.$notify({
            title: 'success ',
            text: 'Enregistrement effectué !',
            type: "success"
        })

        commit("SET_LOADING_DOSSIER_MAISON_JUSTICE", false)
        dispatch("getDossierMaisonJustice")
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_DOSSIER_MAISON_JUSTICE", false)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}

export function reorientationDossierMaisonJustice({ commit, dispatch }, formData) {
    commit("SET_LOADING_DOSSIER_MAISON_JUSTICE", true)
    console.log(formData.id)
    apiGuest.put('/reorienterdossiermaisonjustice/' + formData.id, formData, { headers: authHeader() }).then(response => {
        commit('REORIENTATER_DOSSIER_MAISON_JUSTICE', response.data)
        this.$app.$notify({
            title: 'success ',
            text: 'Réorientation effectué !',
            type: "success"
        })

        commit("SET_LOADING_DOSSIER_MAISON_JUSTICE", false)
        commit("SET_ERROR_DOSSIER_MAISON_JUSTICE", false)
        dispatch("getDossierMaisonJustice")
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_DOSSIER_MAISON_JUSTICE", false)
        commit("SET_ERROR_DOSSIER_MAISON_JUSTICE", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}



export function modificationDossierMaisonJustice({ commit, dispatch }, formData) {
    commit("SET_LOADING_DOSSIER_MAISON_JUSTICE", true)
    console.log(formData.id)
    apiGuest.put('/dossiermaisonjustice/' + formData.id, formData, { headers: authHeader() }).then(response => {
        commit('MODIFIER_DOSSIER_MAISON_JUSTICE', response.data)
        this.$app.$notify({
            title: 'success ',
            text: 'Modification effectué !',
            type: "success"
        })

        commit("SET_LOADING_DOSSIER_MAISON_JUSTICE", false)
        commit("SET_ERROR_DOSSIER_MAISON_JUSTICE", false)
        dispatch("getDossierMaisonJustice")
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_DOSSIER_MAISON_JUSTICE", false)
        commit("SET_ERROR_DOSSIER_MAISON_JUSTICE", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}



export function modificationDossierCompromis({ commit, dispatch }, formData) {
    commit("SET_LOADING_DOSSIER_MAISON_JUSTICE", true)
    apiGuest.put('/updatedossiercompromis/' + formData.id, formData, { headers: authHeader() }).then(response => {
        commit('MODIFIER_DOSSIER_MAISON_COMPROMIS', response.data)
        this.$app.$notify({
            title: 'success ',
            text: 'Enregistrement effectué !',
            type: "success"
        })

        commit("SET_LOADING_DOSSIER_MAISON_JUSTICE", false)
        commit("SET_ERROR_DOSSIER_MAISON_JUSTICE", false)
        dispatch("getDossierMaisonJustice")
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_DOSSIER_MAISON_JUSTICE", false)
        commit("SET_ERROR_DOSSIER_MAISON_JUSTICE", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}






/**
 * ACTION ANALYSE CJ
 */










/**
 * Type Affaire
 */




export function getTypeAffaire({ commit }) {
    commit("SET_LOADING_TYPE_AFFAIRE", true)
    apiGuest.get('/typeaffaire', { headers: authHeader() }).then(response => {
        commit('GET_TYPE_AFFAIRE', response.data)
        commit("SET_LOADING_TYPE_AFFAIRE", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_TYPE_AFFAIRE", false)
    })
}

export function ajouterTypeAffaire({ commit, dispatch }, formData) {
    commit("SET_LOADING_TYPE_AFFAIRE", true)
    apiGuest.post('/typeaffaire', formData, { headers: authHeader() }).then(response => {
        if (response.status == 201) {

            commit('AJOUTER_TYPE_AFFAIRE', response.data)
            this.$app.$notify({
                title: 'success ',
                text: 'Enregistrement effectué !',
                type: "success"
            })
            commit("SET_LOADING_TYPE_AFFAIRE", false)
            commit("SET_ERROR_TYPE_AFFAIRE", false)

        }

    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_TYPE_AFFAIRE", false)
        commit("SET_ERROR_TYPE_AFFAIRE", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                ù2title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}



export function supprimerTypeAffaire({ commit }, id) {

    commit("SET_LOADING_TYPE_AFFAIRE", true)
    commit('SUPPRIMER_TYPE_AFFAIRE', id)
    apiGuest.delete('/typeaffaire/' + id, { headers: authHeader() }).then(() => {
        commit("SET_LOADING_TYPE_AFFAIRE", false)
        this.$app.$notify({
            title: 'success',
            text: 'Suppression effectuer !',
            type: "success"
        })
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_TYPE_AFFAIRE", false)
        this.$app.$notify({
            title: 'error',
            text: "Une erreur c'est produite lors de la suppression ",
            type: "warning"
        })
    })

}



export function modificationTypeAffaire({ commit, dispatch }, formData) {
    commit("SET_LOADING_TYPE_AFFAIRE", true)
    apiGuest.put('/typeaffaire/' + formData.id, formData, { headers: authHeader() }).then(response => {
        commit('MODIFIER_TYPE_AFFAIRE', response.data)
        this.$app.$notify({
            title: 'success ',
            text: 'Enregistrement effectué !',
            type: "success"
        })

        commit("SET_LOADING_TYPE_AFFAIRE", false)
        commit("SET_ERROR_TYPE_AFFAIRE", false)

    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_TYPE_AFFAIRE", false)
        commit("SET_ERROR_TYPE_AFFAIRE", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}




/**
 * ACtion Analyse MAISON DE JUSTICE
 */







export function getAnalyseMJ({ commit }) {
    commit("SET_LOADING_ANALYSE_MJ", true)
    apiGuest.get('/ficherecevabilitemj', { headers: authHeader() }).then(response => {
        commit('GET_ANALYSE_MJ', response.data)
        commit("SET_LOADING_ANALYSE_MJ", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_ANALYSE_MJ", false)
    })
}

export function ajouterAnalyseMJ({ commit, dispatch }, formData) {

   
 return new Promise((resolve,reject)=>{

        commit("SET_LOADING_ANALYSE_MJ", true)
    apiGuest.post('/ficherecevabilitemj', formData, { headers: authHeader() }).then(response => {
        if (response.status == 201) {

            commit('AJOUTER_ANALYSE_MJ', response.data)
            this.$app.$notify({
                title: 'success ',
                text: 'Enregistrement effectué !',
                type: "success"
            })
            commit("SET_LOADING_ANALYSE_MJ", false)
            commit("SET_ERROR_ANALYSE_MJ", false)
            resolve(response)
            dispatch("getFicheEntretien")
        }

    }).catch(error => {
        console.log(error)
          reject(error)
        commit("SET_LOADING_ANALYSE_MJ", false)
        commit("SET_ERROR_ANALYSE_MJ", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                ù2title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })

    })




   
}



export function supprimerAnalyseMJ({ commit }, id) {

    commit("SET_LOADING_ANALYSE_MJ", true)
    commit('SUPPRIMER_ANALYSE_MJ', id)
    apiGuest.delete('/ficherecevabilitemj/' + id, { headers: authHeader() }).then(() => {
        commit("SET_LOADING_ANALYSE_MJ", false)
        this.$app.$notify({
            title: 'success',
            text: 'Suppression effectuer !',
            type: "success"
        })
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_ANALYSE_MJ", false)
        this.$app.$notify({
            title: 'error',
            text: "Une erreur c'est produite lors de la suppression ",
            type: "warning"
        })
    })

}



export function modificationAnalyseMJ({ commit, dispatch }, formData) {
    commit("SET_LOADING_ANALYSE_MJ", true)
    apiGuest.put('/ficherecevabilitemj/' + formData.id, formData, { headers: authHeader() }).then(response => {
        commit('MODIFIER_ANALYSE_MJ', response.data)
        this.$app.$notify({
            title: 'success ',
            text: 'Enregistrement effectué !',
            type: "success"
        })

        commit("SET_LOADING_ANALYSE_MJ", false)
        commit("SET_ERROR_ANALYSE_MJ", false)
        dispatch("getAnalyseMJ")
        dispatch("getFicheEntretien")
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_ANALYSE_MJ", false)
        commit("SET_ERROR_ANALYSE_MJ", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}



//partie requerant

export function getPartieRequerant({ commit }) {
    commit("SET_LOADING_PARTIE_REQUERANT", true)
    apiGuest.get('/partierequerant', { headers: authHeader() }).then(response => {
        commit('GET_PARTIE_REQUERANT', response.data)
        commit("SET_LOADING_PARTIE_REQUERANT", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_PARTIE_REQUERANT", false)
    })
}

export function ajouterPartieRequerant({ commit, dispatch }, formData) {
    commit("SET_LOADING_PARTIE_REQUERANT", true)
    apiGuest.post('/partierequerant', formData, { headers: authHeader() }).then(response => {
        if (response.status == 201) {

            commit('AJOUTER_PARTIE_REQUERANT', response.data)
            this.$app.$notify({
                title: 'success ',
                text: 'Enregistrement effectué !',
                type: "success"
            })
            commit("SET_LOADING_PARTIE_REQUERANT", false)
            commit("SET_ERROR_PARTIE_REQUERANT", false)

        }

    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_PARTIE_REQUERANT", false)
        commit("SET_ERROR_PARTIE_REQUERANT", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                ù2title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}



export function supprimerPartieRequerant({ commit }, id) {

    commit("SET_LOADING_PARTIE_REQUERANT", true)
    commit('SUPPRIMER_PARTIE_REQUERANT', id)
    apiGuest.delete('/partierequerant/' + id, { headers: authHeader() }).then(() => {
        commit("SET_LOADING_PARTIE_REQUERANT", false)
        this.$app.$notify({
            title: 'success',
            text: 'Suppression effectuer !',
            type: "success"
        })
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_PARTIE_REQUERANT", false)
        this.$app.$notify({
            title: 'error',
            text: "Une erreur c'est produite lors de la suppression ",
            type: "warning"
        })
    })

}



export function modificationPartieRequerant({ commit, dispatch }, formData) {
    commit("SET_LOADING_PARTIE_REQUERANT", true)
    apiGuest.put('/partierequerant/' + formData.id, formData, { headers: authHeader() }).then(response => {
        commit('MODIFIER_PARTIE_REQUERANT', response.data)
        this.$app.$notify({
            title: 'success ',
            text: 'Modification effectué !',
            type: "success"
        })

        commit("SET_LOADING_PARTIE_REQUERANT", false)
        commit("SET_ERROR_PARTIE_REQUERANT", false)

    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_PARTIE_REQUERANT", false)
        commit("SET_ERROR_PARTIE_REQUERANT", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}





//infraction

export function getInfraction({ commit }) {
    commit("SET_LOADING_INFRACTION", true)
    apiGuest.get('/infraction', { headers: authHeader() }).then(response => {
        commit('GET_INFRACTION', response.data)
        commit("SET_LOADING_INFRACTION", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_INFRACTION", false)
    })
}


export function ModifierVictimeprevenu({ commit,dispatch }, formData) {
    commit("SET_LOADING_VICTIME_PREVENU", true)
    apiGuest.put('/modifiervictimeprevenu/' + formData.id, formData, { headers: authHeader() }).then(response => {
        commit('MODIFIER_VICTIME_PREVENU', response.data)
        dispatch('getVictimePrevenu')
        dispatch('getInfraction')
        this.$app.$notify({
            title: 'success ',
            text: 'Modification effectué !',
            type: "success"
        })

        commit("SET_LOADING_VICTIME_PREVENU", false)
        commit("SET_ERROR_VICTIME_PREVENU", false)

    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_VICTIME_PREVENU", false)
        commit("SET_ERROR_VICTIME_PREVENU", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}


export function AjouterVictimeprevenu({ commit, dispatch }, formData) {
    commit("SET_LOADING_VICTIME_PREVENU", true)
    apiGuest.post('/ajoutvictimeprevenu', formData, { headers: authHeader() }).then(response => {
        if (response.status == 201) {

            commit('AJOUTER_VICTIME_PREVENU', response.data)
            dispatch('getVictimePrevenu')
            dispatch('getInfraction')
            this.$app.$notify({
                title: 'success ',
                text: 'Enregistrement effectué !',
                type: "success"
            })
            commit("SET_LOADING_VICTIME_PREVENU", false)
            commit("SET_ERROR_VICTIME_PREVENU", false)

        }

    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_VICTIME_PREVENU", false)
        commit("SET_ERROR_VICTIME_PREVENU", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                ù2title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}

export function ajouterInfraction({ commit, dispatch }, formData) {
    commit("SET_LOADING_INFRACTION", true)
    apiGuest.post('/infraction', formData, { headers: authHeader() }).then(response => {
        if (response.status == 201) {

            commit('AJOUTER_INFRACTION', response.data)
            dispatch('getInfraction')
            this.$app.$notify({
                title: 'success ',
                text: 'Enregistrement effectué !',
                type: "success"
            })
            commit("SET_LOADING_INFRACTION", false)
            commit("SET_ERROR_INFRACTION", false)

        }

    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_INFRACTION", false)
        commit("SET_ERROR_INFRACTION", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                ù2title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}



export function supprimerInfraction({ commit }, id) {

    commit("SET_LOADING_INFRACTION", true)
    commit('SUPPRIMER_INFRACTION', id)
    apiGuest.delete('/infraction/' + id, { headers: authHeader() }).then(() => {
        commit("SET_LOADING_INFRACTION", false)
        this.$app.$notify({
            title: 'success',
            text: 'Suppression effectuer !',
            type: "success"
        })
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_INFRACTION", false)
        this.$app.$notify({
            title: 'error',
            text: "Une erreur c'est produite lors de la suppression ",
            type: "warning"
        })
    })

}



export function modificationInfraction({ commit, dispatch }, formData) {
    commit("SET_LOADING_INFRACTION", true)
    apiGuest.put('/infraction/' + formData.id, formData, { headers: authHeader() }).then(response => {
        commit('MODIFIER_INFRACTION', response.data)
        dispatch('getInfraction')
        this.$app.$notify({
            title: 'success ',
            text: 'Enregistrement effectué !',
            type: "success"
        })

        commit("SET_LOADING_INFRACTION", false)
        commit("SET_ERROR_INFRACTION", false)

    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_INFRACTION", false)
        commit("SET_ERROR_INFRACTION", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}




//clinique juridique

export function getDossierCliniqueJur({ commit }) {
    commit("SET_LOADING_DOSSIER_CLINIQUE_JURIDIQUE", true)
    apiGuest.get('/dossiercliniquejur', { headers: authHeader() }).then(response => {
        commit('GET_DOSSIER_CLINIQUE_JURIDIQUE', response.data)
        commit("SET_LOADING_DOSSIER_CLINIQUE_JURIDIQUE", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_DOSSIER_CLINIQUE_JURIDIQUE", false)
    })
}

export function ajouterDossierCliniqueJur({ commit, dispatch }, formData) {
    commit("SET_LOADING_DOSSIER_CLINIQUE_JURIDIQUE", true)
    return new Promise((resolve,reject)=>{
    apiGuest.post('/dossiercliniquejur', formData, { headers: authHeader() }).then(response => {
        if (response.status == 201) {
            resolve(response)
            commit('AJOUTER_DOSSIER_CLINIQUE_JURIDIQUE', response.data)
            this.$app.$notify({
                title: 'success ',
                text: 'Enregistrement effectué !',
                type: "success"
            })
            commit("SET_LOADING_DOSSIER_CLINIQUE_JURIDIQUE", false)
            commit("SET_ERROR_DOSSIER_CLINIQUE_JURIDIQUE", false)
            dispatch("getDossierCliniqueJur")

        }

    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_DOSSIER_CLINIQUE_JURIDIQUE", false)
        commit("SET_ERROR_DOSSIER_CLINIQUE_JURIDIQUE", true)
        reject(error)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                ù2title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
})
}



export function supprimerDossierCliniqueJur({ commit }, id) {

    commit("SET_LOADING_DOSSIER_CLINIQUE_JURIDIQUE", true)
    commit('SUPPRIMER_DOSSIER_CLINIQUE_JURIDIQUE', id)
    apiGuest.delete('/dossiercliniquejur/' + id, { headers: authHeader() }).then(() => {
        commit("SET_LOADING_DOSSIER_CLINIQUE_JURIDIQUE", false)
        this.$app.$notify({
            title: 'success',
            text: 'Suppression effectuer !',
            type: "success"
        })
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_DOSSIER_CLINIQUE_JURIDIQUE", false)
        this.$app.$notify({
            title: 'error',
            text: "Une erreur c'est produite lors de la suppression ",
            type: "warning"
        })
    })

}



export function modificationDossierCliniqueJur({ commit, dispatch }, formData) {
    commit("SET_LOADING_DOSSIER_CLINIQUE_JURIDIQUE", true)
    apiGuest.put('/dossiercliniquejur/' + formData.id_dossier, formData, { headers: authHeader() }).then(response => {
        commit('MODIFIER_DOSSIER_CLINIQUE_JURIDIQUE', response.data)
        this.$app.$notify({
            title: 'success ',
            text: 'Enregistrement effectué !',
            type: "success"
        })

        commit("SET_LOADING_DOSSIER_CLINIQUE_JURIDIQUE", false)
        commit("SET_ERROR_DOSSIER_CLINIQUE_JURIDIQUE", false)
        dispatch("getDossierCliniqueJur")

    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_DOSSIER_CLINIQUE_JURIDIQUE", false)
        commit("SET_ERROR_DOSSIER_CLINIQUE_JURIDIQUE", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}



export function getVictimePrevenu({ commit }) {
    commit("SET_LOADING_VICTIME_PREVENU", true)
    apiGuest.get('/list_victimeprevenu', { headers: authHeader() }).then(response => {
        commit('GET_VICTIME_PREVENU', response.data)
        commit("SET_LOADING_VICTIME_PREVENU", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_VICTIME_PREVENU", false)
    })
}


export function getCompromisaccord({ commit }) {
    commit("SET_LOADING_COMPROMIS_ACCORD", true)
    apiGuest.get('/compromisaccord', { headers: authHeader() }).then(response => {
        commit('GET_COMPROMIS_ACCORD', response.data)
        commit("SET_LOADING_COMPROMIS_ACCORD", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_COMPROMIS_ACCORD", false)
    })
}









export function getStructure({ commit }) {
    commit("SET_LOADING_STRUCTURE", true)

    apiGuest.get('/compte_generals', { headers: authHeader() }).then(response => {
        commit('GET_STRUCTURE', response.data)
        commit("SET_LOADING_STRUCTURE", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_STRUCTURE", false)
    })
}

export function ajouterStructure({ commit }, formData) {
    commit("SET_LOADING_STRUCTURE", true)
    apiGuest.post('/compte_generals', formData, { headers: authHeader() }).then(response => {
        if (response.status == 201) {

            commit('AJOUTER_STRUCTURE', response.data)
            this.$app.$notify({
                title: 'success ',
                text: 'Enregistrement effectué !',
                type: "success"
            })
            commit("SET_LOADING_STRUCTURE", false)
            commit("SET_ERROR_STRUCTURE", false)
        }

    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_STRUCTURE", false)
        commit("SET_ERROR_STRUCTURE", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                ù2title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}


export function supprimerStructure({ commit }, id) {

    commit("SET_LOADING_STRUCTURE", true)
    commit('SUPPRIMER_STRUCTURE', id)
    apiGuest.delete('/compte_generals/' + id, { headers: authHeader() }).then(() => {
        commit("SET_LOADING_STRUCTURE", false)
        this.$app.$notify({
            title: 'success',
            text: 'Suppression effectuer !',
            type: "success"
        })
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_STRUCTURE", false)
        this.$app.$notify({
            title: 'error',
            text: "Une erreur c'est produite lors de la suppression ",
            type: "warning"
        })
    })

}



export function modificationStructure({ commit }, formData) {
    commit("SET_LOADING_STRUCTURE", true)
    apiGuest.put('/compte_generals/' + formData.id, formData, { headers: authHeader() }).then(response => {
        commit('MODIFIER_STRUCTURE', response.data)
        this.$app.$notify({
            title: 'success ',
            text: 'Enregistrement effectué !',
            type: "success"
        })
        commit("SET_LOADING_STRUCTURE", false)
        commit("SET_ERROR_STRUCTURE", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_STRUCTURE", false)
        commit("SET_ERROR_STRUCTURE", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}







/**
 * LISTE DES PEINES
 * */


export function getPeine({ commit }) {
    commit("SET_LOADING_PEINE", true)
    apiGuest.get('/peine', { headers: authHeader() }).then(response => {
        commit('GET_PEINE', response.data)
        commit("SET_LOADING_PEINE", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_PEINE", false)
    })
}

export function ajouterPeine({ commit }, formData) {
   console.log(formData)
 return new Promise((resolve,reject)=>{
    
     commit("SET_LOADING_PEINE", true)
    apiGuest.post('/peine', formData, { headers: authHeader() }).then(response => {
        if (response.status == 201) {

            commit('AJOUTER_PEINE', response.data)
            this.$app.$notify({
                title: 'success ',
                text: 'Enregistrement effectué !',
                type: "success"
            })
            commit("SET_LOADING_PEINE", false)
            resolve(response)

        }

    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_PEINE", false)
         reject(error)

        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                ù2title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })

   })

}



export function supprimerPeine({ commit }, id) {

    commit("SET_LOADING_PEINE", true)
    commit('SUPPRIMER_PEINE', id)
    apiGuest.delete('/peine/' + id, { headers: authHeader() }).then(() => {
        commit("SUPPRIMER_PEINE", id)
         commit("SET_LOADING_PEINE", false)
        this.$app.$notify({
            title: 'success',
            text: 'Suppression effectuer !',
            type: "success"
        })
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_PEINE", false)
        this.$app.$notify({
            title: 'error',
            text: "Une erreur c'est produite lors de la suppression ",
            type: "warning"
        })
    })

}



export function modificationPeine({ commit, dispatch }, formData) {
    commit("SET_LOADING_PEINE", true)
    apiGuest.put('/peine/' + formData.id, formData, { headers: authHeader() }).then(response => {
        commit('MODIFIER_PEINE', response.data)
        this.$app.$notify({
            title: 'success ',
            text: 'Enregistrement effectué !',
            type: "success"
        })

        commit("SET_LOADING_PEINE", false)
    
        

    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_PEINE", false)
       
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}





/***
 * 
 * RENVOI
 * 
 * */




export function getRenvoi({ commit }) {
    commit("SET_LOADING_RENVOI", true)
    apiGuest.get('/renvoi', { headers: authHeader() }).then(response => {
        commit('GET_RENVOI2', response.data)
        commit("SET_LOADING_RENVOI", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_RENVOI", false)
    })
}

export function ajouterRenvoi({ commit }, formData) {
   console.log(formData)
 return new Promise((resolve,reject)=>{
    
     commit("SET_LOADING_RENVOI", true)
    apiGuest.post('/renvoi', formData, { headers: authHeader() }).then(response => {
        if (response.status == 201) {

            commit('AJOUTER_RENVOI', response.data)
            this.$app.$notify({
                title: 'success ',
                text: 'Enregistrement effectué !',
                type: "success"
            })
            commit("SET_LOADING_RENVOI", false)
            resolve(response)

        }

    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_RENVOI", false)
         reject(error)

        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                ù2title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })

   })

}



export function supprimerRenvoi({ commit }, id) {

    commit("SET_LOADING_RENVOI", true)
    apiGuest.delete('/renvoi/' + id, { headers: authHeader() }).then(() => {
        commit("SUPPRIMER_RENVOI", id)
         commit("SET_LOADING_RENVOI", false)
        this.$app.$notify({
            title: 'success',
            text: 'Suppression effectuer !',
            type: "success"
        })
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_RENVOI", false)
        this.$app.$notify({
            title: 'error',
            text: "Une erreur c'est produite lors de la suppression ",
            type: "warning"
        })
    })

}



export function modificationRenvoi({ commit, dispatch }, formData) {
    commit("SET_LOADING_RENVOI", true)
    apiGuest.put('/renvoi/' + formData.id, formData, { headers: authHeader() }).then(response => {
        commit('MODIFIER_RENVOI', response.data)
        this.$app.$notify({
            title: 'success ',
            text: 'Enregistrement effectué !',
            type: "success"
        })

        commit("SET_LOADING_RENVOI", false)
    
        

    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_RENVOI", false)
       
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}




//gestion des appels

export function getAppel({ commit }) {
    commit("SET_LOADING_APPEL", true)
    apiGuest.get('/appel', { headers: authHeader() }).then(response => {
        commit('GET_APPEL', response.data)
        commit("SET_LOADING_APPEL", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_APPEL", false)
    })
}

export function ajouterAppel({ commit, dispatch }, formData) {
    commit("SET_LOADING_APPEL", true)
    apiGuest.post('/appel', formData, { headers: authHeader() }).then(response => {
        if (response.status == 201) {

            commit('AJOUTER_APPEL', response.data)
            this.$app.$notify({
                title: 'success ',
                text: 'Enregistrement effectué !',
                type: "success"
            })
            commit("SET_LOADING_APPEL", false)
            commit("SET_ERROR_APPEL", false)
            dispatch("getAppel")

        }

    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_APPEL", false)
        commit("SET_ERROR_APPEL", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                ù2title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}



export function supprimerAppel({ commit }, id) {

    commit("SET_LOADING_APPEL", true)
    commit('SUPPRIMER_APPEL', id)
    apiGuest.delete('/appel/' + id, { headers: authHeader() }).then(() => {
        commit("SET_LOADING_APPEL", false)
        this.$app.$notify({
            title: 'success',
            text: 'Suppression effectuer !',
            type: "success"
        })
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_APPEL", false)
        this.$app.$notify({
            title: 'error',
            text: "Une erreur c'est produite lors de la suppression ",
            type: "warning"
        })
    })

}



export function modificationAppel({ commit, dispatch }, formData) {
    commit("SET_LOADING_APPEL", true)
    apiGuest.put('/appel/' + formData.id, formData, { headers: authHeader() }).then(response => {
        commit('MODIFIER_APPEL', response.data)
        this.$app.$notify({
            title: 'success ',
            text: 'Enregistrement effectué !',
            type: "success"
        })

        commit("SET_LOADING_APPEL", false)
        commit("SET_ERROR_APPEL", false)
        dispatch("getAppel")

    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_APPEL", false)
        commit("SET_ERROR_APPEL", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}




export function getStructureCoopec({ commit }) {
    commit("SET_LOADING_STRUCTURE_COOPEC", true)

    apiGuest.get('/structure', { headers: authHeader() }).then(response => {
        commit('GET_STRUCTURE_COOPEC', response.data)
        commit("SET_LOADING_STRUCTURE_COOPEC", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_STRUCTURE_COOPEC", false)
    })
}

export function ajouterStructureCoopec({ commit }, formData) {
    commit("SET_LOADING_STRUCTURE_COOPEC", true)
    apiGuest.post('/structure', formData, { headers: authHeader() }).then(response => {
        if (response.status == 201) {

            commit('AJOUTER_STRUCTURE_COOPEC', response.data)
            this.$app.$notify({
                title: 'success ',
                text: 'Enregistrement effectué !',
                type: "success"
            })
            commit("SET_LOADING_STRUCTURE_COOPEC", false)
            commit("SET_ERROR_STRUCTURE_COOPEC", false)
        }

    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_STRUCTURE_COOPEC", false)
        commit("SET_ERROR_STRUCTURE_COOPEC", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                ù2title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}


export function supprimerStructureCoopec({ commit }, id) {

    commit("SET_LOADING_STRUCTURE_COOPEC", true)
    commit('SUPPRIMER_STRUCTURE_COOPEC', id)
    apiGuest.delete('/structure/' + id, { headers: authHeader() }).then(() => {
        commit("SET_LOADING_STRUCTURE_COOPEC", false)
        this.$app.$notify({
            title: 'success',
            text: 'Suppression effectuer !',
            type: "success"
        })
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_STRUCTURE_COOPEC", false)
        this.$app.$notify({
            title: 'error',
            text: "Une erreur c'est produite lors de la suppression ",
            type: "warning"
        })
    })

}



export function modificationStructureCoopec({ commit }, formData) {
    commit("SET_LOADING_STRUCTURE_COOPEC", true)
    apiGuest.put('/structure/' + formData.id, formData, { headers: authHeader() }).then(response => {
        commit('MODIFIER_STRUCTURE_COOPEC', response.data)
        this.$app.$notify({
            title: 'success ',
            text: 'Enregistrement effectué !',
            type: "success"
        })
        commit("SET_LOADING_STRUCTURE_COOPEC", false)
        commit("SET_ERROR_STRUCTURE_COOPEC", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_STRUCTURE_COOPEC", false)
        commit("SET_ERROR_STRUCTURE_COOPEC", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}








export function importationLocalisation({ commit }, formData) {
    commit("SET_LOADING_STRUCTURE_COOPEC", true)
    apiGuest.post('/importation_user', formData, { headers: authHeader() }).then(response => {
        if (response.status == 201) {
                 console.log(response.data)
           // commit('AJOUTER_STRUCTURE_COOPEC', response.data)
            this.$app.$notify({
                title: 'success ',
                text: 'Enregistrement effectué !',
                type: "success"
            })
           // commit("SET_LOADING_STRUCTURE_COOPEC", false)
           // commit("SET_ERROR_STRUCTURE_COOPEC", false)
        }

    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_STRUCTURE_COOPEC", false)
        commit("SET_ERROR_STRUCTURE_COOPEC", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}





/**
 * 
 * Grille Commissions
 *  
 * **/

export function getGrilleCommissions({ commit }) {
    commit("SET_LOADING_GRILLE_COMMSISSION", true)
    apiGuest.get('/grille_commission', { headers: authHeader() }).then(response => {
        commit('GET_GRILLE_COMMSISSION', response.data)
        commit("SET_LOADING_GRILLE_COMMSISSION", false)
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_GRILLE_COMMSISSION", false)
    })
}

export function ajouterGrilleCommissions({ commit }, formData) {
    commit("SET_LOADING_GRILLE_COMMSISSION", true)
    apiGuest.post('/grille_commission', formData, { headers: authHeader() }).then(response => {
        if (response.status == 201) {

            commit('AJOUTER_GRILLE_COMMSISSION', response.data)
            this.$app.$notify({
                title: 'success ',
                text: 'Enregistrement effectué !',
                type: "success"
            })
            commit("SET_LOADING_GRILLE_COMMSISSION", false)
            commit("SET_ERROR_GRILLE_COMMSISSION", false)
        }

    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_GRILLE_COMMSISSION", false)
        commit("SET_ERROR_GRILLE_COMMSISSION", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}


export function supprimerGrilleCommissions({ commit }, id) {
    commit("SET_LOADING_GRILLE_COMMSISSION", true)
    commit('SUPPRIMER_GRILLE_COMMSISSION', id)
    apiGuest.delete('/grille_commission/' + id, { headers: authHeader() }).then(() => {
        commit("SET_LOADING_GRILLE_COMMSISSION", false)
        this.$app.$notify({
            title: 'success',
            text: 'Suppression effectuer !',
            type: "success"
        })
    }).catch(error => {
        console.log(error)
        commit("SET_LOADING_GRILLE_COMMSISSION", false)
        this.$app.$notify({
            title: 'error',
            text: "Une erreur c'est produite lors de la suppression ",
            type: "warning"
        })
    })

}



export function modificationGrilleCommissions({ commit }, formData) {
    commit("SET_LOADING_GRILLE_COMMSISSION", true)
    apiGuest.put('/grille_commission/' + formData.id, formData, { headers: authHeader() }).then(response => {
        commit('MODIFIER_GRILLE_COMMSISSION', response.data)
        this.$app.$notify({
            title: 'success ',
            text: 'Enregistrement effectué !',
            type: "success"
        })
        commit("SET_LOADING_GRILLE_COMMSISSION", false)
        commit("SET_ERROR_GRILLE_COMMSISSION", false)
    }).catch(error => {
        
        commit("SET_LOADING_GRILLE_COMMSISSION", false)
        commit("SET_ERROR_GRILLE_COMMSISSION", true)
        if (error.response == undefined) {
            this.$app.$notify({
                title: 'error',
                text: "Veuillez verifier votre connexion et réessayer ",
                type: "warning"
            })
        } else {
            this.$app.$notify({
                title: 'error',
                text: error.response.data.message,
                type: "warning"
            })
        }
    })
}



