




export const getterSyntheseActivite= state => state.syntheseActivite;
export const getterLoadingSyntheseActivite= state => state.loadingSyntheseActivite;
export const getterErrorSyntheseActivite= state => state.errorSyntheseActivite;


export const getterCommissionDetaillant= state => state.commissionDetaillant;
export const getterLoadingCommissionDetaillant= state => state.loadingCommissionDetaillant;
export const getterErrorCommissionDetaillant= state => state.errorCommissionDetaillant;


export const getterObjectifPeriode= state => state.objectifPeriode;
export const getterLoadingObjectifPeriode= state => state.loadingObjectifPeriode;
export const getterErrorObjectifPeriode= state => state.errorObjectifPeriode;





