<template>
  <v-app>
    <notifications/>
    <v-main>
		<div id="content" class="content">
			<Loader :isVisible="loading" :isText="'Chargement en cours...'"/>
    
    <div class="panel panel-inverse" data-sortable-id="table-basic-2" data-init="true">
						<!-- begin panel-heading -->
						<div class="panel-heading ui-sortable-handle">
							<div class="panel-heading-btn">
                <div class="row">
                  <div class='col-md-6'>
                   	<button  type="button"  @click.prevent="tableToExcel('table', 'Brouillard de Caisse')" class="btn btn-success">
                  Exporté le brouillard de Caisse
                  </button>
                  </div>
                  <div class='col-md-6'>

                  </div>
                </div>
							
							</div>
							<h4 class="panel-title">
								Brouillard de Caisse  
							</h4>
						</div>
						<!-- end panel-heading -->
						<!-- begin panel-body -->
           
						<div class="panel-body">
							<div class="row">
								<div class="col-md-4">
            	<div class="form-group">
                  <label class="form-label semibold" for="libelle">Caisse </label>
						     	<model-list-select :list="getterCodePenal"
                     v-model="formData.caisse_id"
                     option-value="id"
                     option-text="libelle"
                     placeholder="Caisse">
                 </model-list-select>
                </div>
               
            </div>
            <div class="col-md-3">
                         <div class="form-group">
                         <label class="form-label semibold" for="libelle">DATE DEBUT</label>
                        <input type="date"  v-model="date_debut" name="code" class="form-control" id="libelle" placeholder="Entrer la date du debut">
               </div>
            </div>
            <div class="col-md-3">
                           <div class="form-group">
                         <label class="form-label semibold" for="libelle">DATE FIN</label>
							<input type="date"   v-model="date_fin" name="libelle" class="form-control" id="libelle" placeholder="Entrer la date de fin">
							 
               
                     </div>
            </div>
             <div class="col-md-2">
               <br>
               <button type="button" @click.prevent="viderFiltre()"  class="btn btn-default"><i class="fas fa-redo-alt "></i></button>
             </div>
            </div>
           
    
							<div class="table-responsive" id="printMe" ref="table" summary="Cycle de vie" rules="groups" frame="hsides" border="2">
                <h2>{{nomCaisse}}</h2>
								<table class="table table-striped">
									<thead>
                  
										<tr style="font-size:12px;background: orange;" >
											<th>Jour</th>
											<th>N° facture</th>
											<th>N° compte</th>
											<th>N° tiers</th>
											<th>Libellé écriture </th>
											<th>Mvts débit</th>
											<th>Mvts crédit</th>
                                            <th>SOLDE</th>
											
										</tr>
									</thead>
									<tbody >
									<!--	<tr>
                                            <td>{{index}}</td>
                                            <td></td>
											<td></td>
											<td></td>
											<td>Report solde du {{index}} </td>
											<td>
                       </td>
											<td>
  
                        
                        </td>
                            <td>
                                              {{formatageSomme(reportSolde(index))}} 
                                            </td>
                                        </tr>-->
                                        <tr v-for="item in listeJournalCaise" :key="item.id">
											<td> {{item.date}}</td>
											<td>{{item.numero_facture}}</td>
									
											<td>{{general(item.compte_general_id)}}</td>
											<td>{{tier(item.compte_tier_id)}}</td>
											<td>{{item.libelle_ecriture}}</td>
											
											<td>{{formatageSomme(item.montant_debit)}}</td>
											<td>{{formatageSomme(item.montant_credit)}}</td>
											<td>{{formatageSomme(soldeNouveau(item.order,item.id,item.date))}} </td>
										</tr>
									
									</tbody>
								</table>
							</div>
							<!-- end table-responsive -->
						</div>
						<!-- end panel-body -->
						
					</div>



<v-dialog 
        max-width="600"
         v-model="dialog"
           persistent
      >
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar
             
              dark
            >EXPORTATION </v-toolbar>
            <v-card-text>
                <br>
                
          <div class="form-group">
                         <label class="form-label semibold" for="libelle">DATE DEBUT</label>
              <input type="date"  v-model="date_debut" name="code" class="form-control" id="libelle" placeholder="Entrer la date du debut">
               
                     </div>
                   <div class="form-group">
                         <label class="form-label semibold" for="libelle">DATE FIN</label>
							<input type="date"  v-model="date_fin" name="libelle" class="form-control" id="libelle" placeholder="Entrer la date de fin">
							 
                     </div>
                     
            	
							
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="dialog.value = false"
              >Fermer</v-btn>
              <v-btn
            :disabled="loading"
           :loading="loading"
            text
      @click="tel()"
          >
            Valide
          </v-btn>
            </v-card-actions>
          </v-card>
        </template>
  </v-dialog>
     

<v-dialog
              v-model="dialogDelete"
              max-width="290"
            >
              <v-card >
                <v-card-title class="headline">
                Suppression !!
                </v-card-title>

                <v-card-text>
                  Voulez vous réellement effectuer cette action ?
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    color="green"
                    text
                    @click="dialogDelete = false"
                  >
                    Annuler
                  </v-btn>

                  <v-btn
                    color="red"
                    text
                    @click="deleteItemConfirm()"
                  >
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
        </v-dialog>

 


		</div>
		<!-- end #content -->
  </v-main>
    </v-app>
   
</template>

<script>
import { mapGetters, mapActions} from 'vuex'
    import { required, minLength } from "vuelidate/lib/validators";
import VueApexCharts from "vue-apexcharts";
import { ModelListSelect } from 'vue-search-select'
export default {
  name: 'App',

  components: {
    apexchart: VueApexCharts,
    ModelListSelect 
  },

  data(){
        return {
            
             items: [
        {
          color: 'red lighten-2',
          icon: 'mdi-star',
        },
        {
          color: 'purple darken-1',
          icon: 'mdi-book-variant',
        },
        {
          color: 'green lighten-1',
          icon: 'mdi-airballoon',
        },
        {
          color: 'indigo',
          icon: 'mdi-buffer',
        },
      ],
dialog:false,
               test:true,
               date_debut:"",
               date_fin:"",
               formData:{
            date:"",
        numero_piece:"",
        numero_facture:"",
        references:"",
        date_echeance:"",
        libelle_ecriture:"",
        possition_journal:"",
        montant_debit:0,
        montant_credit:0,
        caisse_id:"",
        compte_general_id:"",
        compte_tier_id:"",
        type_operation:"",
        user_id:""
               },
               edite:"",

 uri :'data:application/vnd.ms-excel;charset=UTF-8;base64,',
                template:'<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
                base64: function(s){ return window.btoa(unescape(encodeURIComponent(s))) },
                format: function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) },

     dialogEdite: false,
      dialogDelete: false,

	  series: [800, 50, 150],
          chartOptions: {
            chart: {
              width: 380,
              type: 'pie',
            },
            labels: ["Requêtes en attente d'analyse", "Requêtes en cours d'analyse",'Requêtes analysées'],
          colors:['#f59c1a', '#00acac', '#348fe2'],
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          },
          

            }
        },
        created(){
//console.log(this.getterFicheEntretien)



        },
		computed:{
    groupBy (){
         return (arr, key)=>{
              const initialValue = {};
            return arr.reduce((acc, cval) => {
                const myAttribute = cval[key];
                acc[myAttribute] = [...(acc[myAttribute] || []), cval]
                return acc;
            }, initialValue);
         }
            },
        journalCaisseJour(){
             let objet=this.listeJournalCaise
             let vm=this
             if(vm.date_debut!="" && vm.date_fin!=""){
               console.log(Date(vm.date_debut))
               objet=objet.filter(item=>{
                 if(vm.date_debut<=item.date && item.date<=vm.date_fin){
                   return item
                 }
               })
             }
           //  let data=this.groupBy(objet,"date");
            
         return objet
          },
      dateNow(){
let dateObj = new Date();
    let month = String(dateObj.getMonth() + 1).padStart(2, '0');;
    let day = String(dateObj.getDate()).padStart(2, '0');
    let year = dateObj.getFullYear();
      if(month.length==1){
        month='0'+month
      }
    //let output =  day + '-'+ month  + '-' + year;
     let output =  year + '-'+ month  + '-' + day;

    return output 
      },
      
      formatageSomme(){
       return montant=>{
         if(montant==0) return ""
         if(montant){
           return (
               montant.toString()
                .split("")
                .reverse()
                .reduce((acc, montant, i) => {
                  return montant + (i && !(i % 3) ? " " : "") + acc;
                }, "") + " "
            );
         }
         return ""
       }
      },
       soldeNouveau(){
         //created_at
        return (index,id,date)=>{
          let montant=this.reportSolde2(index,date)
          
          let objet= this.listeJournalCaise.filter(item=>{
            if(item.id==id){
              return item
            }
          })

       let montant_credit=0;
      let montant_debit=0;
      let montant_total=0;
        montant_debit= objet.reduce(function (total, currentValue) {
                                return total + parseFloat(currentValue.montant_debit) ;
                            }, 0);

        montant_credit= objet.reduce(function (total, currentValue) {
                                return total + parseFloat(currentValue.montant_credit) ;
                            }, 0);
 
     montant_total=(montant + parseFloat(montant_debit)) - parseFloat(montant_credit)
    
     return montant_total 
        }
       },
        reportSolde2(){
    return (index,date)=>{
      
       if(!index) return 0

       let _objet=this.listeJournalCaise.filter(item=>{
         if(item.date==date || item.date<date){
          return item
         }
       })
       console.log(_objet)
   let  objet= _objet.filter(item=>{
        if(item.order<index){
          return item
        }
      })
     

      let montant_credit=0;
      let montant_debit=0;
      let montant_total=0;
        montant_debit= objet.reduce(function (total, currentValue) {
                                return total + parseFloat(currentValue.montant_debit) ;
                            }, 0);

        montant_credit= objet.reduce(function (total, currentValue) {
                                return total + parseFloat(currentValue.montant_credit) ;
                            }, 0);

     montant_total=parseFloat(montant_debit) - parseFloat(montant_credit)
     console.log("DGDGDGD")
      console.log(montant_total)
      console.log("Report de credit")
     return montant_total
    }
   },
   reportSolde(){
    return index=>{
      
       if(!index) return 0
     let objet= this.listeJournalCaise.filter(item=>{
        if(item.date<index){
          console.log(item.date)
          return item
        }
      })
       console.log(objet)
      let montant_credit=0;
      let montant_debit=0;
      let montant_total=0;
        montant_debit= objet.reduce(function (total, currentValue) {
                                return total + parseFloat(currentValue.montant_debit) ;
                            }, 0);

        montant_credit= objet.reduce(function (total, currentValue) {
                                return total + parseFloat(currentValue.montant_credit) ;
                            }, 0);

     montant_total=parseFloat(montant_debit) - parseFloat(montant_credit)
     return montant_total
    }
   },
   soustrationDateDayCalcule() {
        return (date, info)=>{

            var result = new Date(date);

              let rest= result.setDate(result.getDate() - parseInt(info));
             
              let test=new Date(rest)
       
            return this.formatDate(rest);
        }
     },
      formatDate() {
        return date=>{
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        }
      },
      nomCaisse(){
          //getterCodePenal

          let vm=this
          if(vm.formData.caisse_id=="") return "Situation génarale des caisses"

            let objet=vm.getterCodePenal.find(item=>item.id==vm.formData.caisse_id)

          return objet.libelle
      },

      listeJournalCaise(){
       let vm=this
   if(vm.formData.caisse_id){

    return this.getterStructureGeographique.filter(item=>{
      
      if(item.caisse_id==vm.formData.caisse_id && (item.compte.code.startsWith("57") || item.compte.code.startsWith("55") )){
        
       return item
      }
    })
   }
   
       return this.getterStructureGeographique.filter(item=>{
         if((item.compte.code.startsWith("57") || item.compte.code.startsWith("55") )){
           return item
         }
       })
      },
      calculeMontantDebit(){
        return row=>{
          let  montant=0
       montant  = row.reduce(function (total, currentValue) {
                                return total + parseFloat(currentValue.montant_debit) ;
                            }, 0);
         return montant
        }
      },
      calculeMontantCredit(){
          return row=>{
                let  montant=0
            montant = row.reduce(function (total, currentValue) {
                                      return total + parseFloat(currentValue.montant_credit) ;
                                  }, 0);
              return montant
              }
      },
      soldeCaiseJour(){
        let vm=this
       //formData.caisse_id

       let objet=[]
       
       objet=this.listeJournalCaise.filter(item=>{
        if(item.operation_principal==1 && item.date_enregistrement==this.dateNow){
          return item
        }
      })

       
      let montant_credit=0;
      let montant_debit=0;
      let montant_total=0;
       montant_debit= objet.reduce(function (total, currentValue) {
                                return total + parseFloat(currentValue.montant_debit) ;
                            }, 0);

        montant_credit= objet.reduce(function (total, currentValue) {
                                return total + parseFloat(currentValue.montant_credit) ;
                            }, 0);

     montant_total=parseFloat(montant_debit) - parseFloat(montant_credit)
     return montant_total
      },
      soldeCaise(){
       let vm=this
       //formData.caisse_id

       let objet=[]
        objet=this.listeJournalCaise.filter(item=>{
        if(item.operation_principal==1){
          return item
        }
      })

      
      let montant_credit=0;
      let montant_debit=0;
      let montant_total=0;
       montant_debit= objet.reduce(function (total, currentValue) {
           let montant=currentValue.montant_debit
                if(currentValue.montant_debit==null){
                  montant=0
                }
                                return parseFloat(total) + parseFloat(montant) ;
                            }, 0);

      console.log("............MONTANT DEBIT...........")
      console.log(montant_debit)
      console.log("............FIN MONTANT DEBIT........")
         montant_credit= objet.reduce(function (total, currentValue) {
                    let montant=currentValue.montant_credit
                if(currentValue.montant_credit==null){
                  montant=0
                }
                      return parseFloat(total) + parseFloat(currentValue.montant_credit) ;
              }, 0);


    console.log("............MONTANT CREDIT...........")
    console.log(montant_credit)
    console.log("............FIN MONTANT CREDIT........")

     montant_total=parseFloat(montant_debit) - parseFloat(montant_credit)
     return montant_total
      },
			general(){
       return id=>{
       	if(!id) return ""

       		let objte=this.getterStructure.find(item=>item.id==id)

       	return objte.code 
       }
			},
			tier(){
return id=>{
       	if(!id) return ""
       		let objte=this.getterCriteIndigence.find(item=>item.id==id)

       	return objte.code 
       }
			},
   listeger(){
  return this.getterStructure
   },
   listeger2(){
  return id=>{
   
    if(!id) return null
       let cptier=this.getterCriteIndigence.find(item=>item.id==id)
        console.log(cptier)
       
        let objet=  this.getterStructure.find(item=>item.id==cptier.compte_general_id)
        let vm=this
        vm.formData.compte_general_id=objet.id
      return null
   }
   },
            ...mapGetters('Application', ['getterTypeActeurJuridique', 'getterLoadingTypeActeurJuridique',"getterErrorTypeActeurJuridique","getterFicheEntretien","getterLoadingFicheEntretien","getterErrorFicheEntretien","getterActeurJuridique","getterStructure","getterCriteIndigence","getterStructureGeographique","getterLoadingStructureGeographique","getterErrorStructureGeographique","getterCodePenal"]),
            ...mapGetters('Utilisateurs', ["getterErrorUser","getterLoadingUser","getterUser","getterUserStore"]),
             formTitle () {
             return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
           },
           loading(){
               return this.getterLoadingStructureGeographique
           },

             codeUser(){
            if(!this.getterUserStore) return 0
            return this.getterUserStore.role
        },
           idActeurJuridique(){
             if(this.getterUserStore){
               return this.getterUserStore.acteur_juridique_id
             }
             return ""
           },



           idStructure(){
             if(this.getterUserStore){

               return this.getterUserStore.structure_id
             }
             return ""
           },
         
         listeActeurJuriduqe(){
           if(this.idStructure){
            return this.getterActeurJuridique.filter(item=>item.structure_id==this.getterUserStore.structure_id)
           }
           return []
         },
            nombreTotalRequete(){
              return this.getterFicheEntretien.length
			},
			nombrePasStatus(){
             return status=>{
				/* let prixMaison=1000
				 prixMaison.toLocaleString()*/
				 
				 return this.getterFicheEntretien.filter(item=>item.status==status).length
			 }
			},
			nombrePasStatusActeur(){
             return (status,acteur)=>{
			
				 return this.getterFicheEntretien.filter(item=>{
				 	if(item.status==status && item.acteur_juridique_id==acteur){
				 		return item
				 	}
				 }).length
			 }
			},
			nombreTraite(){
              return parseFloat(this.nombrePasStatus(2)) + parseFloat(this.nombrePasStatus(3))
			},
			pourcentageTraite(){
              let taux=(this.nombreTraite * 100)/this.nombreTotalRequete
			  return taux.toFixed(2)
			},
			pourcentageAUtre(){
				return status=>{
  					let taux=(this.nombrePasStatus(status) * 100)/this.nombreTotalRequete
			  		return taux.toFixed(2)
				}
            
			},
		},
        validations: {
            formData: {
               code: { required },
                libelle: { required },
            }
        },

			methods:{
             ...mapActions('Application', ['supprimerStructure','ajouterStructure',"modificationStructure","ajouterStructureGeoGraphique","getStructureGeoGraphique","modificationStructureGeoGraphique","supprimerStructureGeographique","teletest"]),
          randomNumber: function () {
      this.random = Math.floor(Math.random() * (10 - 1 + 1)) + 1
    },
          tableToExcel(table, name){
                console.log(this.$refs.table)
                if (!table.nodeType) table = this.$refs.table
                var ctx = {worksheet: name || 'Worksheet', table: table.innerHTML}
                window.location.href = this.uri + this.base64(this.format(this.template, ctx))
            },
            viderFiltre(){
                this.formData.caisse_id=""
                this.date_debut=""
                this.date_fin=""
          
            },
           gotoModale(){
            	this.getStructureGeoGraphique()
            	this.dialogEdite=false
              this.formData={
            date:"",
        numero_piece:"",
        numero_facture:"",
        references:"",
        date_echeance:"",
        libelle_ecriture:"",
        possition_journal:"",
        montant_debit:0,
     
        montant_credit:0,
        caisse_id:"",
        compte_general_id:"",
        compte_tier_id:"",
        type_operation:"",
        user_id:""
               } 
            }
            ,
            enregistrement () {
              this.formData.libelle_ecriture=this.formData.libelle_ecriture.toUpperCase();
              console.log(this.formData.libelle_ecriture)
                this.ajouterStructureGeoGraphique(this.formData)
                if(this.getterErrorStructureGeographique==false){
                   // this.$v.formData.$reset();
                  this.formData={
                      date:"",
                      numero_piece:"",
                      numero_facture:"",
                      references:"",
                      date_echeance:"",
                      libelle_ecriture:"",
                      possition_journal:"",
                      montant_debit:0,
                      montant_credit:0,
                      caisse_id:"",
                      compte_general_id:"",
                      compte_tier_id:"",
                      type_operation:"",
                      user_id:""
               }
                }
               

            },
              

modification(){
this.modificationStructureGeoGraphique(this.formData)
this.dialogEdite=false
this.formData={
            date:"",
        numero_piece:"",
        numero_facture:"",
        references:"",
        date_echeance:"",
        libelle_ecriture:"",
        possition_journal:"",
        montant_debit:"",
        montant_debit:"",
        montant_credit:"",
        caisse_id:"",
        compte_general_id:"",
        compte_tier_id:"",
        type_operation:"",
        user_id:""
               }
},

      editItem(item) {
       this.formData=item
        this.dialogEdite = true
      },

      deleteItem2(item) {
      this.edite=item
      console.log(this.edite)
     console.log("................")
      this.dialogDelete=true
      },

      deleteItemConfirm(){
         this.dialogDelete=false
        
        this.supprimerStructureGeographique(this.edite.id)
      },
      tele(){
      this.dialog=true
      },
     
     tel(){
      let b={
        date_debut:this.date_debut,
        date_fin:this.date_fin
      }
        this.teletest(b)
     },

        
           getStatus () {
             return status=>{
        if (status == 0) return 'EN ATTENTES'
         if (status ==1) return 'EN-COURS'
         if (status ==2) return 'ACCEPTER'
         return 'REJETTER'
             }
      
      },
	  },
};
</script>

<style scoped>
table ,tr td{
    border:1px solid orange
}
tbody {
    display:block;
    height:100%;
    overflow:auto;
}
thead, tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;
}
thead {
    width: calc( 100% - 1em )
}
table {
    
    width:100%;
}
</style>