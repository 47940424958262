
<template>
<v-app>
    <notifications/>
    <v-main>
    
    <div id="content" class="content">
			<!-- begin breadcrumb -->
			<ol class="breadcrumb pull-right">
				<li class="breadcrumb-item"><a href="javascript:;">Accueil</a></li>
				<li class="breadcrumb-item"><a href="javascript:;">Activité OM</a></li>
			
			</ol>
			<!-- end breadcrumb -->
			<!-- begin page-header -->
			<h1 class="page-header">Gestion  des activités OM</h1>
			<!-- end page-header -->
			<!-- begin panel -->
			<div class="panel panel-inverse">
				
				<div class="panel-body">
         
		
 <div>

<div class="col-lg-12 ui-sortable">
				
                 

<!-- begin row -->
            <div class="row">
               <div class="col-lg-12">
                    <!-- begin nav-tabs -->
                    <ul class="nav nav-tabs">
                        <li class="nav-items">
                            <a href="#default-tab-1" data-toggle="tab" class="nav-link active show">
                                <span class="d-sm-none">Activité OM</span>
                                <span class="d-sm-block d-none">Activité OM</span>
                            </a>
                        </li>
                        <li class="nav-items">
                            <a href="#default-tab-2" data-toggle="tab" class="nav-link">
                                <span class="d-sm-none">Importé le raport d'activités J+1</span>
                                <span class="d-sm-block d-none">Importé le raport d'activités J-1</span>
                            </a>
                        </li>
                       <!-- <li class="nav-items">
                            <a href="#default-tab-3" data-toggle="tab" class="nav-link">
                                <span class="d-sm-none">Tab 3</span>
                                <span class="d-sm-block d-none">Default Tab 3</span>
                            </a>
                        </li>-->
                    </ul>
                    <!-- end nav-tabs -->
                    <!-- begin tab-content -->
                    <div class="tab-content">
                        <!-- begin tab-pane -->
                        <div class="tab-pane fade active show" id="default-tab-1">
                           <v-data-table
    :headers="headers"
    :items="getterSyntheseActivite"
    sort-by="code"
    class="elevation-1"
  >
  <template v-slot:item.cashin_nbr="{item}">
                          <div>
                            {{formatageSomme(item.cashin_nbr)}}
                          </div>
                        </template>
                        <template v-slot:item.cashin_montant="{item}">
                          <div>
                            {{formatageSomme(item.cashin_montant)}}
                          </div>
                        </template>
                        <template v-slot:item.cashout_nbr="{item}">
                          <div>
                            {{formatageSomme(item.cashout_nbr)}}
                          </div>
                        </template>
                        <template v-slot:item.cashout_montant="{item}">
                          <div>
                            {{formatageSomme(item.cashout_montant)}}
                          </div>
                        </template>
                      
    <template v-slot:item.actions="{ item }">
      


<div class="btn-group btn-group-justified">
  <a class="btn btn-default" @click="editItem(item)"><i class="fas fa-edit"></i></a>

  
</div>

    </template>
  </v-data-table>
                        </div>
                        <!-- end tab-pane -->
                        <!-- begin tab-pane -->
                        <div class="tab-pane fade" id="default-tab-2">
                            <div class="row">
                                <div class="col-md-2"></div>
                                <div class="col-md-4">
                                    <label class="form-label semibold" for="code">Date de l'activité </label>
                                    <input type="date" :max="dateNow" v-model="date_commision" name="code" class="form-control" id="code" placeholder="Code">
                                </div>
                            </div>
                            <br>
                             <div class="row" v-if="date_commision">
                                <div class="col-md-2"></div>
                                <div class="col-md-5">
                                    <upload-excel-component :on-success="handleSuccess" :before-upload="beforeUpload" />
                                </div>
                            </div>
         
                        </div>
                        <!-- end tab-pane -->
                        <!-- begin tab-pane -->
                        <div class="tab-pane fade" id="default-tab-3">
                          



                        </div>
                        <!-- end tab-pane -->
                    </div>
                    <!-- end tab-content -->
                    <!-- begin nav-pills -->
                 
                  
                </div>
            </div>
            <!-- end row -->
					
</div>




   
  

  </div>
				</div>
			</div>
		
		</div>

        <v-dialog
              v-model="loading_activite"
              persistent
              width="250"
              height="200"
            >
              <v-card
                color="#2d353c"
                dark
                class="text-center"
              >
                <v-card-text  class="white--text">
                  Requête en cours de traitement<br>
                  <v-progress-circular
                    indeterminate
                    color="white"
                    class="mb-0"
                    size="50"
                  ></v-progress-circular>
                </v-card-text>
              </v-card>
        </v-dialog>






   <v-dialog
        max-width="500"
         v-model="dialogEdite"
      >
        <template v-slot:default="dialogEdite">
          <v-card>
            <v-toolbar
              dark
            >Comfirmation de la date d'activité</v-toolbar>
            <v-card-text>
                <br>
                 <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                         <label class="form-label semibold" for="code">Comfirmation de date d'activité </label>
                        <input type="date" @change="comparaisonDate" v-model="comfirmation_date_activite" name="code" class="form-control" id="code" placeholder="Code">
                         <span v-if="bool_compare" style="color:red">La date de comfirmation ne corresponde pas !!</span>
                     </div>

                    </div>
                   
                    
                </div>
                   
                     
                 
            
                     <!-- <div class="form-group">
                         <label class="form-label semibold">Rôle </label>
                            <model-list-select class="form-control" option-value="id"
                                    option-text="libelle" v-model="edite.role_id" :list="Liste_role"
                                                placeholder="Selectionner Acteur juridique">
                        </model-list-select>
                 </div> -->

            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="dialogEdite.value = false"
              >Fermer</v-btn>
          <v-btn

            :disabled="bool_desible_button"
            :loading="loading_activite"
              text
            @click="enregistrement()"
          >
            Validation
          </v-btn>
            </v-card-actions>
          </v-card>
        </template>
  </v-dialog>



         <v-dialog
              v-model="dialogDelete"
              max-width="290"
            >
              <v-card >
                <v-card-title class="headline">
                Erreur !!
                </v-card-title>

                <v-card-text>
                  Une erreur c'est produit lors de l'importation
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  
                  <v-btn
                    color="red"
                    text
                    @click="deleteItemConfirm(edite)"
                  >
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
        </v-dialog>

    </v-main>
    </v-app>
</template>



<script>
import { mapGetters, mapActions} from 'vuex'
    import { required, minLength ,email } from "vuelidate/lib/validators";
  import { ModelListSelect } from 'vue-search-select'
  import UploadExcelComponent from '@/components/UploadExcel/index.vue'
     import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
          name: "Utilisateur",
    components: {
     ModelListSelect,
     UploadExcelComponent,
     Loading
    },
        data() {
            return {
               dialog:false,
               test:true,
               date_commision:"",
               loading_activite:false,
                tableData: [],
                tableHeader: [],
                namePDFDemandeAno: "",
                fichierPDFDemandeAno: "",
                imagePDFDemandeAno:"",
               formData:{
                   name:"",
                   email:"",
                   telephone:"",
                   role:"",
                   numero_apv2:"",
                   contact_personnel:"",
                   localite_id:"",
                   
               },
               edite:"",
       dialogEdite: false,
       dialogDelete: false,
       bool_compare:false,
       bool_desible_button:true,

       comfirmation_date_activite:"",
       urlBase:process.env.VUE_APP_BASE,
      headers: [
        { text: 'Code', value: 'code' },
        { text: 'Date', value: 'date' },
        { text: 'Cashin Nombre', value: 'cashin_nbr' },
        { text: 'Cashin Montant', value: 'cashin_montant' },
        { text: 'Cashout Nombre', value: 'cashout_nbr' },
        { text: 'Cashout Montant', value: 'cashout_montant' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      Liste_role:[
        {id:"APV2",libelle:"APV2"},
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
      
        libelle: "",
      },
      defaultItem: {
       code: '',
        libelle: "",
      },



            }
        },
        created(){
 
        },
        computed:{

            ...mapGetters('Application', ['getterStructure','getterLocalisationGeographique', 'getterActeurJuridique',"getterLoadingActeurJurique","getterErrorActeurJuridique","getterTypeActeurJuridique"]),
             
              ...mapGetters('GestionCommission', ['getterSyntheseActivite','getterLoadingSyntheseActivite', 'getterErrorSyntheseActivite']),
             


              ...mapGetters('Utilisateurs', ["getterErrorUser","getterLoadingUser","getterUser"]),
              
              detaillantListe(){
            return this.getterUser.filter(item=>item.role=="APV2")
              },
            dateNow(){
let dateObj = new Date();
    let month = String(dateObj.getMonth() + 1).padStart(2, '0');;
    let day = String(dateObj.getDate()).padStart(2, '0');
    let year = dateObj.getFullYear();
      if(month.length==1){
        month='0'+month
      }
    //let output =  day + '-'+ month  + '-' + year;
     let output =  year + '-'+ month  + '-' + day;

    return output 
      },
            dateNowFormatFR(){
let dateObj = new Date();
    let month = String(dateObj.getMonth() + 1).padStart(2, '0');;
    let day = String(dateObj.getDate()).padStart(2, '0');
    let year = dateObj.getFullYear();
      if(month.length==1){
        month='0'+month
      }
    //let output =  day + '-'+ month  + '-' + year;
    // let output =  year + '-'+ month  + '-' + day;
     let output=day+"/"+"/"+month+"/"+year;
      
    return output 
      },
           formatageSomme(){
       return montant=>{
         if(montant==0) return ""
         if(montant){
           return (
               montant.toString()
                .split("")
                .reverse()
                .reduce((acc, montant, i) => {
                  return montant + (i && !(i % 3) ? " " : "") + acc;
                }, "") + " "
            );
         }
         return ""
       }
      },

        },
        validations: {
            formData: {
                     name:{ required },
                    email:{ required ,email },
                   telephone:{ required },
                   password:{ required },
                   role:{ required },
                   numero_pdv:{ required },
                   contact_personnel:{ required },
                   localite_id:""    
            }
        },
        methods:{
             ...mapActions('Application', ['getActeurJuridique',"ajouterActeurJuridique",
             "supprimerActeurJuridique","modificationActeurJuridique","importationLocalisation"]),


...mapActions('GestionCommission', ['getSyntheseActivite',"ajouterSyntheseActivite","supprimerSyntheseActivite","modificationSyntheseActivite"]),

             ...mapActions('Utilisateurs', ['getUsers',"ajouterUser",
             "supprimerUser","modificationUser"]),
             comparaisonDate(){

                let vm=this;
               if(vm.comfirmation_date_activite){

                  if(vm.comfirmation_date_activite!=vm.date_commision) {
                    vm.bool_compare=true
                     vm.bool_desible_button=true
                    return null
                  }else{
                     vm.bool_desible_button=false
                  }
               }
              vm.bool_compare=false
             
              },

addFichier(file) {
                let reader = new FileReader();
                let vm = this;
                reader.onload = e => {
                    vm.imagePDFDemandeAno = "pdf.png";
                    vm.namePDFDemandeAno = file.name;
                    vm.fichierPDFDemandeAno = e.target.result;
                };
                reader.readAsDataURL(file);
            }
            ,
             OnchangeFichier1(file) {
                const files = file;
                this.selectedFile = file;
                console.log(this.selectedFile)
                Array.from(files).forEach(file => this.addFichier(file));
            },
            beforeUpload(file) {
                const isLt1M = file.size / 1024 / 1024 < 1
                this.OnchangeFichier1(file)
                if (isLt1M) {
                    return true
                }

               /* this.$message({
                    message: 'Please do not upload files larger than 1m in size.',
                    type: 'warning'
                })*/
                return false
            },
            handleSuccess({ results, header }) {
               // console.log(results[0]["GRANDE NATURE DE DEPENSE"])
                this.tableData = results
                this.tableHeader = header

                console.log(this.tableHeader)
                
               // this.importationLocalisation(objet)
                this.dialogEdite=true
                this.bool_compare=false
                this.bool_desible_button=true
                this.comfirmation_date_activite=""
            },
            gotoModale(){
              
               this.dialog=true 
            }
            ,
            enregistrement () {
               
                this.loading_activite=true
                this.dialogEdite=false

             let objet={
                    data:this.tableData,
                    header:this.tableHeader,
                    date:this.date_commision
                }

                this.ajouterSyntheseActivite(objet).then((res)=>{
                    console.log(res)
                    this.tableData=[]
                    this.tableHeader=[]
                    this.date_commision=""
                    this.loading_activite=false  
                }).catch(error =>{
                    console.log(error)
                      this.loading_activite=false 
                })

               /* if(this.getterErrorSyntheseActivite==false){
                  
                }else{
                    this.loading_activite=false
                }*/

            },
              

modification(item){
  
this.modificationUser(item)
},
      editItem (item) {
       this.edite=item
        this.dialogEdite = true
      },

      deleteItem (item) {
      this.edite=item
      console.log(this.edite)
      this.dialogDelete=true
      },

      deleteItemConfirm (item) {
         this.dialogDelete=false
        this.supprimerUser(item.id)
      },
      
 
  
    

      
        },
        watch: {
      
     },
    }
</script>

<style scoped>
.input {
        background-color: #f7f7f7;
        border: 1px solid #c7c7c7;
        padding: 0.3rem 0.5rem;
        border-radius: 2px;
    }
    .hasError .input {
        border: red;
    }
    .hasErroInpu{
        border: 1px red solid;
    }
    .hasError label {
        color: red;
    }
    .icon:hover {
	 cursor: pointer;
}
 .muted {
	 color: gray;
	 font-size: 80%;
}
</style>



<style>
  .vtl .vtl-drag-disabled {
	 background-color: #d0cfcf;
}
 .vtl .vtl-drag-disabled:hover {
	 background-color: #d0cfcf;
}
 .vtl .vtl-disabled {
	 background-color: #d0cfcf;
}
 
 
</style> 
 
