

import Catotographie from '@/views/apv2/cartographie/Catotographie'
import CodeQr from '@/views/apv2/codeqr/CodeQr'
import PresenceApv2 from '@/views/apv2/presence/PresenceApv2'

const Apv2Routes = [
   // { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound, meta: {layout: "no-sidebar"} },
   {
      path: '/cartographie',
      name: "Catotographie",
      meta: { requiresAuth: true },
      component: Catotographie
   },
   {
      path: '/code-qr-apv2',
      name: "CodeQr",
      meta: { requiresAuth: true },
      component: CodeQr,
   },
   {
      path: '/presence-apv2',
      name: "PresenceApv2",
      meta: { requiresAuth: true },
      component: PresenceApv2
   },


]

export default Apv2Routes;
