
<template>
<v-app>
    <notifications/>
    <v-main>
    
    <div id="content" class="content">
			<!-- begin breadcrumb -->
			
			<!-- end breadcrumb -->
			<!-- begin page-header -->
			

              <div class="row">
                  <div class='col-md-8'>
                    <h1 class="page-header">Objectif 
            {{detail_objectif_periode.moi_fr}} 
{{formatDate(detail_objectif_periode.date_debut)}} /
{{formatDate(detail_objectif_periode.date_fin)}}
             </h1>
                  </div>
                  <div class='col-md-4'>
<button  type="button"  @click.prevent="tableToExcel('table','Objectif')" class="btn btn-success">
                  Exporté 
                  </button>
                  </div>
                </div>
			<!-- end page-header -->
			<!-- begin panel -->
			<div class="panel panel-inverse">
				
				<div class="panel-body">
         


		<div class="row row-space-10 m-b-20">
            <!-- begin col-4 -->
            <div class="col-lg-4" style="cursor: grab;" @click="OjetctionAll()">
              <div class="widget widget-stats bg-gradient-teal m-b-10">
                <div class="stats-icon stats-icon-lg"><i class="fa fa-globe fa-fw"></i></div>
                <div class="stats-content">
                  <div class="stats-title">Total Objetctif</div>
                  <div class="stats-number"> {{tpm_data_objectif.length}}</div>
                 
                  
                </div>
              </div>
            </div>
            <!-- end col-4 -->
            <!-- begin col-4 -->
            <div class="col-lg-4" style="cursor: grab;" @click="OjetctionAtteind()">
              <div class="widget widget-stats bg-gradient-blue m-b-10">
                <div class="stats-icon stats-icon-lg"><i class="fa fa-dollar-sign fa-fw"></i></div>
                <div class="stats-content">
                  <div class="stats-title">Total Objectif réalisé</div>
                  <div class="stats-number">
                      {{tpm_data_objectif.filter(item=>item.status==1).length}}
                  </div>
                 
                 
                </div>
              </div>
            </div>
            <!-- end col-4 --> 
            <!-- begin col-4 -->
            <div class="col-lg-4" style="cursor: grab;" @click="OjetctionNonAtteind()">
              <div class="widget widget-stats bg-gradient-purple m-b-10">
                <div class="stats-icon stats-icon-lg"><i class="fa fa-archive fa-fw"></i></div>
                <div class="stats-content">
                  <div class="stats-title">Total objectif non réalisé</div>
                  <div class="stats-number">
                      {{tpm_data_objectif.filter(item=>item.status==0).length}}
                  </div>
                  
                </div>
              </div>
            </div>
            
          </div>
 <div>

<div class="col-lg-12 ui-sortable" >

      <table class="table table-condensed m-b-0 text-inverse"  ref="table" summary="Cycle de vie" rules="groups" frame="hsides" border="2">
                                    <thead>
                                        <tr>
                                            
                                            <th>Nom prenom</th>
                                            <th>Numéro TP</th>
                                            <th>Numéro PDV</th>
                                            <th>Objectif Cashin </th>
                                            <th>Realisé Cashin </th>
                                            <th>Objectif Cashout</th>
                                            <th>Realisé Cashout </th>
                                            <th>Part variable </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in data_objectif" :key="item.id" :class="{ red_type_marche :item.status }">
                                            <td>{{item.name}}</td>
                                            <td>{{item.numero_tp}}</td>
                                            <td>{{item.numero_pdv}}</td>
                                            <td>{{formatageSomme(Math.round(item.objectif_cashin))}}
                                            </td>
                                            <td>{{formatageSomme(Math.round(item.somm_montant_cashin))}}
                                            </td>
                                            <td>{{formatageSomme(Math.round(item.objectif_cashout))}}
                                            </td>
                                             <td>
                                                 {{formatageSomme(Math.round(item.somm_montant_cashout))}}
                                             </td>
                                            <td>
                                                {{formatageSomme(Math.round(item.part_variable))}}
                                            </td>
                                        </tr>
                                        
                                    </tbody>
    </table>


            </div>
            <!-- end row -->
					
</div>




   
  

  </div>
				</div>
			</div>
		
		</div>

        <v-dialog
              v-model="loading_activite"
              persistent
              width="250"
              height="200"
            >
              <v-card
                color="#2d353c"
                dark
                class="text-center"
              >
                <v-card-text  class="white--text">
                  Requête en cours de traitement<br>
                  <v-progress-circular
                    indeterminate
                    color="white"
                    class="mb-0"
                    size="50"
                  ></v-progress-circular>
                </v-card-text>
              </v-card>
        </v-dialog>






    </v-main>
    </v-app>
</template>



<script>
import { mapGetters, mapActions} from 'vuex'
    import { required, minLength ,email } from "vuelidate/lib/validators";
  import { ModelListSelect } from 'vue-search-select'
  import UploadExcelComponent from '@/components/UploadExcel/index.vue'
     import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
          name: "Utilisateur",
    components: {
     ModelListSelect,
     UploadExcelComponent,
     Loading
    },
        data() {
            return {
               dialog:false,
               test:true,
               date_commision:"",
               loading_activite:true,
                tableData: [],
                tableHeader: [],
                namePDFDemandeAno: "",
                fichierPDFDemandeAno: "",
                imagePDFDemandeAno:"",
                date_debut:"",
                date_debut_confirmation:"",
               date_fin:"",
               date_fin_confirmation:"",
               detail_objectif_periode:"",
                uri :'data:application/vnd.ms-excel;charset=UTF-8;base64,',
                template:'<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
                base64: function(s){ return window.btoa(unescape(encodeURIComponent(s))) },
                format: function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) },
               formData:{
                   name:"",
                   email:"",
                   telephone:"",
                   role:"",
                   numero_apv2:"",
                   contact_personnel:"",
                   localite_id:"",
                   
               },
               edite:"",
       dialogEdite: false,
       dialogDelete: false,
       bool_compare:false,
       bool_compare2:false,
       bool_desible_button:true,
       data_objectif:[],
       tpm_data_objectif:[],
       comfirmation_date_activite:"",
       urlBase:process.env.VUE_APP_BASE,
      headers: [
        { text: 'Code', value: 'code' },
        { text: 'Date début', value: 'date_debut' },
        { text: 'Date fin', value: 'date_fin' },
        { text: 'Montant cashin', value: 'montant_cashin' },
        { text: 'Montant cashout', value: 'montant_cashout' },
        { text: 'Moi', value: 'moi_fr' },
        { text: 'Annee', value: 'annee' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      Liste_role:[
        {id:"APV2",libelle:"APV2"},
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
      
        libelle: "",
      },
      defaultItem: {
       code: '',
        libelle: "",
      },
     


            }
        },
        created(){

           this.detail_objectif_periode= this.getterObjectifPeriode.find(item=>item.id==this.$route.params.id)

         this.getObjectifByPeriode(this.$route.params.id).then(item=>{
            this.loading_activite=false
            this.data_objectif=item
            this.tpm_data_objectif=item
          

         }).catch(error=>{
            this.loading_activite=false
            console.log(error)
         })
        },
        computed:{
  formatDate() {
        return date=>{
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [day, month, year].join('-');
        }
      },
        groupBy(){
         return (arr, key)=>{
              const initialValue = {};
            return arr.reduce((acc, cval) => {
                const myAttribute = cval[key];
                acc[myAttribute] = [...(acc[myAttribute] || []), cval]
                return acc;
            }, initialValue);
         }
            },
         
            ...mapGetters('Application', ['getterStructure','getterLocalisationGeographique', 'getterActeurJuridique',"getterLoadingActeurJurique","getterErrorActeurJuridique","getterTypeActeurJuridique"]),
             
              ...mapGetters('GestionCommission', ['getterObjectifPeriode','getterLoadingObjectifPeriode', 'getterErrorObjectifPeriode']),
             


  

              ...mapGetters('Utilisateurs', ["getterErrorUser","getterLoadingUser","getterUser"]),

              dataByMoi(){
              return annee=>{
                if(annee=="") return []
                    let objet=this.getterObjectifPeriode.filter(item=>item.annee==annee)
                  return objet;
              }
              },

               dataByMoiAnnee(){
              return (annee,i)=>{
                if(annee=="" && i=="") return []
                    let objet=this.getterObjectifPeriode.filter(item=>item.annee==annee && item.moi_fr==i)
                  return objet;
              }
              },
              
              detaillantListe(){
            return this.getterUser.filter(item=>item.role=="APV2")
              },
            dateNow(){
let dateObj = new Date();
    let month = String(dateObj.getMonth() + 1).padStart(2, '0');;
    let day = String(dateObj.getDate()).padStart(2, '0');
    let year = dateObj.getFullYear();
      if(month.length==1){
        month='0'+month
      }
    //let output =  day + '-'+ month  + '-' + year;
     let output =  year + '-'+ month  + '-' + day;

    return output 
      },
            dateNowFormatFR(){
let dateObj = new Date();
    let month = String(dateObj.getMonth() + 1).padStart(2, '0');;
    let day = String(dateObj.getDate()).padStart(2, '0');
    let year = dateObj.getFullYear();
      if(month.length==1){
        month='0'+month
      }
    //let output =  day + '-'+ month  + '-' + year;
    // let output =  year + '-'+ month  + '-' + day;
     let output=day+"/"+"/"+month+"/"+year;
      
    return output 
      },
           formatageSomme(){
       return montant=>{
         if(montant==0) return ""
         if(montant){
           return (
               montant.toString()
                .split("")
                .reverse()
                .reduce((acc, montant, i) => {
                  return montant + (i && !(i % 3) ? " " : "") + acc;
                }, "") + " "
            );
         }
         return ""
       }
      },

        },
        validations: {
            formData: {
                     name:{ required },
                    email:{ required ,email },
                   telephone:{ required },
                   password:{ required },
                   role:{ required },
                   numero_pdv:{ required },
                   contact_personnel:{ required },
                   localite_id:""    
            }
        },
        methods:{
             ...mapActions('Application', ['getActeurJuridique',"ajouterActeurJuridique",
             "supprimerActeurJuridique","modificationActeurJuridique","importationLocalisation"]),


...mapActions('GestionCommission', ['getSyntheseActivite',"ajouterSyntheseActivite","supprimerSyntheseActivite","modificationSyntheseActivite","ajouterObjectif","getObjectifByPeriode"]),

             ...mapActions('Utilisateurs', ['getUsers',"ajouterUser",
             "supprimerUser","modificationUser"]),
              tableToExcel(table, name){
                console.log(this.$refs.table)
                if (!table.nodeType) table = this.$refs.table
                var ctx = {worksheet: name || 'Worksheet', table: table.innerHTML}
                window.location.href = this.uri + this.base64(this.format(this.template, ctx))
            },
             OjetctionAtteind(){
       this.data_objectif=this.tpm_data_objectif.filter(item=>item.status==1)
           
         },
          OjetctionNonAtteind(){
       this.data_objectif=this.tpm_data_objectif.filter(item=>item.status==0)
           
         },
         OjetctionAll(){
       this.data_objectif=this.tpm_data_objectif
           
         },
             comparaisonDate(){

                let vm=this;
               if(vm.date_debut_confirmation){

                  if(vm.date_debut_confirmation!=vm.date_debut) {
                    vm.bool_compare=true
                     vm.bool_desible_button=true
                    return null
                  }else{
                     vm.bool_desible_button=false
                  }
               }
              vm.bool_compare=false
             
              },

comparaisonDateFin(){

                let vm=this;
               if(vm.date_fin_confirmation){

                  if(vm.date_fin_confirmation!=vm.date_fin) {
                    vm.bool_compare2=true
                     vm.bool_desible_button=true
                    return null
                  }else{
                     vm.bool_desible_button=false
                  }
               }
              vm.bool_compare2=false
             
              },
addFichier(file) {
                let reader = new FileReader();
                let vm = this;
                reader.onload = e => {
                    vm.imagePDFDemandeAno = "pdf.png";
                    vm.namePDFDemandeAno = file.name;
                    vm.fichierPDFDemandeAno = e.target.result;
                };
                reader.readAsDataURL(file);
            }
            ,
             OnchangeFichier1(file) {
                const files = file;
                this.selectedFile = file;
                console.log(this.selectedFile)
                Array.from(files).forEach(file => this.addFichier(file));
            },
            beforeUpload(file) {
                const isLt1M = file.size / 1024 / 1024 < 1
                this.OnchangeFichier1(file)
                if (isLt1M) {
                    return true
                }

               /* this.$message({
                    message: 'Please do not upload files larger than 1m in size.',
                    type: 'warning'
                })*/
                return false
            },
            handleSuccess({ results, header }) {
               // console.log(results[0]["GRANDE NATURE DE DEPENSE"])
                this.tableData = results
                this.tableHeader = header

                console.log(this.tableHeader)
                
               // this.importationLocalisation(objet)
                this.dialogEdite=true
                this.bool_compare=false
                this.bool_desible_button=true
                this.comfirmation_date_activite=""
            },
            gotoModale(){
              
               this.dialog=true 
            }
            ,
            enregistrement () {
               
                this.loading_activite=true
                this.dialogEdite=false

             let objet={
                    data:this.tableData,
                    header:this.tableHeader,
                    date_fin:this.date_fin,
                    date_debut:this.date_debut
                }

                this.ajouterObjectif(objet).then((res)=>{
                    console.log(res)
                    this.tableData=[]
                    this.tableHeader=[]
                    this.date_commision=""
                    this.loading_activite=false  
                }).catch(error =>{
                    console.log(error)
                      this.loading_activite=false 
                })

               /* if(this.getterErrorSyntheseActivite==false){
                  
                }else{
                    this.loading_activite=false
                }*/

            },
              

modification(item){
  
this.modificationUser(item)
},
      editItem (item) {
       this.edite=item
        this.dialogEdite = true
      },

      deleteItem (item) {
      this.edite=item
      console.log(this.edite)
      this.dialogDelete=true
      },

      deleteItemConfirm (item) {
         this.dialogDelete=false
        this.supprimerUser(item.id)
      },
      
 
  
    

      
        },
        watch: {
      
     },
    }
</script>

<style scoped>
.input {
        background-color: #f7f7f7;
        border: 1px solid #c7c7c7;
        padding: 0.3rem 0.5rem;
        border-radius: 2px;
    }
    .hasError .input {
        border: red;
    }
    .hasErroInpu{
        border: 1px red solid;
    }
    .hasError label {
        color: red;
    }
    .icon:hover {
	 cursor: pointer;
}
 .muted {
	 color: gray;
	 font-size: 80%;
}

.red_type_marche {
        color: black !important;
        background-color: green !important;
    }
</style>



<style>
  .vtl .vtl-drag-disabled {
	 background-color: #d0cfcf;
}
 .vtl .vtl-drag-disabled:hover {
	 background-color: #d0cfcf;
}
 .vtl .vtl-disabled {
	 background-color: #d0cfcf;
}
 
 
</style> 
 
