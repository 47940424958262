

export const getterStructureGeographique= state => state.structureGeographique;
export const getterLoadingStructureGeographique= state => state.loadingStructureGeographique;
export const getterErrorStructureGeographique = state => state.errorStructureGeographique;



export const getterTypeELemntDossier= state => state.typeElementDossier;
export const getterLoadingTypeElementDossier= state => state.loadingTypeElementDossier;
export const getterErrorTypeElementDossier = state => state.errorTypeElementDossier;



export const getterTypePersonneTribunal= state => state.typePersonneTribunal;
export const getterLoadingTypePersonneTribunal= state => state.loadingtypeTypePersonneTribunal;
export const getterErrorTypePersonneTribunal = state => state.errortypeTypePersonneTribunal;


export const getterTypeActeurJuridique= state => state.typeActeurJuridique;
export const getterLoadingTypeActeurJuridique= state => state.loadingtypeTypeActeurJuridique;
export const getterErrorTypeActeurJuridique = state => state.errortypeTypeActeurJuridique;


export const getterNatureDossier= state => state.natureDossier;
export const getterLoadingNatureDossier= state => state.loadingtypeNatureDossier;
export const getterErrorNatureDossier= state => state.errortypeNatureDossier;



export const getterMotifPartie= state => state.motifPartie;
export const getterLoadingMotifPartie= state => state.loadingMotifPartie;
export const getterErrorMotifPartie= state => state.errorMotifPartie;




export const getterModeSaisine= state => state.modeSaisine;
export const getterLoadingModeSaisine= state => state.loadingModeSaisine;
export const getterErrorMotifModeSaisine= state => state.errorModeSaisine;



export const getterCriteIndigence= state => state.criterIndigence;
export const getterLoadingCritereIndigence= state => state.loadingCriterIndigence;
export const getterErrorCriterIndigence= state => state.errorCriterIndigence;


export const getterCritereVictimologie= state => state.critereVictimologie;
export const getterLoadingCritereVictimologie= state => state.loadingCritereVictimologie;
export const getterErrorCritereVictimologie= state => state.errorCritereVictimologie;


export const getterLocalisationGeographique= state => state.localisationGeographique;
export const getterLoadingLocalisationGeographisue= state => state.loadingLocalisationGeographisue;
export const getterErrorLocalisationGeographisue= state => state.errorLocalisationGeographisue;



export const getterActeurJuridique= state => state.acteurJuridique;
export const getterLoadingActeurJurique= state => state.loadingActeurJuridique;
export const getterErrorActeurJuridique= state => state.errorActeurJuridique;


export const getterLocaliteSaisine= state => state.LocaliteSaisine;
export const getterLoadingLocaliteSaisine= state => state.loadingLocaliteSaisine;
export const getterErrorLocaliteSaisine= state => state.errorLocaliteSaisine;


export const getterCodePenal= state => state.CodePenal;
export const getterLoadingCodePenal= state => state.loadingCodePenal;
export const getterErrorCodePenal= state => state.errorLocaliteSaisine;



export const getterFicheEntretien= state => state.ficheEntretien;
export const getterLoadingFicheEntretien= state => state.loadingFicheEntretien;
export const getterErrorFicheEntretien= state => state.errorFicheEntretien;


export const getterDossierMaisonJustice= state => state.DossierMaisonJustice;
export const getterLoadingDossierMaisonJustice= state => state.loadingDossierMaisonJustice;
export const getterErrorDossierMaisonJustice= state => state.errorDossierMaisonJustice;


export const getterDossiercliniquejuridique= state => state.Dossiercliniquejuridique;
export const getterLoadingDossiercliniquejuridique= state => state.loadingDossiercliniquejuridique;
export const getterErrorDossiercliniquejuridique= state => state.errorDossiercliniquejuridique;

export const getterAnalyseCJ= state => state.analyseCJ;
export const getterLoadingAnalyseCF= state => state.loadingAnalyseCJ;
export const getterErrorAnalyseCF= state => state.errorAnalyseCJ;


export const getterAnalyseMJ= state => state.analyseMJ;
export const getterLoadingAnalyseMJ= state => state.loadingAnalyseMJ;
export const getterErrorAnalyseMJ= state => state.errorAnalyseMJ;

export const getterTypeAffaire= state => state.typeAffaire;
export const getterLoadingTypeAffaire= state => state.loadingTypeAffaire;
export const getterErrorTypeAffaire= state => state.errorTypeAffaire;

export const getterPeine= state =>state.peine;
export const getterLoadingPeine= state =>state.loadingPeine;


export const getterRenvoi2= state =>state.renvoi;
export const getterLoadingRenvoi= state =>state.loadingRenvoi;

export const getterPartieRequerant= state => state.partierequerant;
export const getterLoadingPartieRequerante= state => state.loadingpartierequerant;
export const getterErrorPartieRequerante= state => state.errorpartierequerant;

export const getterInfraction= state => state.infraction;
export const getterLoadingInfraction= state => state.loadinginfraction;
export const getterErrorInfraction= state => state.errorinfraction;

export const getterVictimeprevenu= state => state.victimeprevenu;
export const getterLoadingVictimePrevenu= state => state.loadingvictimeprevenu;

export const gettersCompromisaccord= state => state.compromisaccord;
export const getterLoadingCompromisaccord= state => state.loadingcompromisaccord;

export const getterAudience= state => state.audience;
export const getterLoadingAudience= state => state.loadingaudiance;
export const getterErrorAudience= state => state.erroraudience;


export const getterStructure= state => state.Structure;
export const getterLoadingStructure= state => state.loadingStructure;
export const getterErrorStructure= state => state.errorStructure;

export const getterelementParam= state => state.dataparam;
export const getterDateEnetretien= state => state.DateEntretien;

export const getterRenvoi= state => state.liistRenvoi;
export const getterPartieCivil= state => state.listPartieCivil;


export const getterAppel= state => state.appel;
export const getterLoadingAppel= state => state.loadingappel;
export const getterErrorAppel= state => state.errorappel;

export const getterStructureCoopec= state => state.stateStructureCoopec;
export const getterLoadingStructureCoopec= state => state.loadingStructureCoopec;
export const getterErrorStructureCoopec= state => state.errorStructureCoopec;


export const getterGrilleCommissions= state => state.stateGrilleCommissions;
export const getterLoadingGrilleCommissions= state => state.loadingGrilleCommissions;
export const getterErrorGrilleCommissions= state => state.errorGrilleCommissions;



