
<template>
<v-app>
    <notifications/>
    <v-main>
    
    <div id="content" class="content">
			
			<div class="panel panel-inverse">
				

				<div class="panel-body">
         
		
 <div>

<div class="col-lg-12 ui-sortable">
				<h3 class="m-t-10">Tous les commissions EPERVIER</h3>
        <div class="row">
          <div class="col-md-3">
                         <div class="form-group">
                         <label class="form-label semibold" for="libelle">DATE DEBUT</label>
                        <input type="date" :class="{ 'hasErroInpu': $v.formData.date_debut.$error }"  v-model="formData.date_debut" name="code" class="form-control" id="libelle" placeholder="Entrer la date du debut">
                        <div style="color: red;" class="" v-if="$v.formData.date_debut.$error">
                                veuillez entrer la date de debut
                          </div>
               </div>
            </div>
            <div class="col-md-3">
                           <div class="form-group">
                         <label class="form-label semibold" for="libelle">DATE FIN</label>
              <input type="date" :min="formData.date_debut" :class="{ 'hasErroInpu': $v.formData.date_fin.$error }"   v-model="formData.date_fin" name="libelle" class="form-control" id="libelle" placeholder="Entrer la date de fin">
               <div style="color: red;" class="" v-if="$v.formData.date_fin.$error">
                                veuillez entrer la date de fin
                            </div>
                     </div>
            </div>
            <div class="col-md-2">
               <br>
               <button type="button" @click='searchCom()'  class="btn btn-default"><i class="fas fa-search "></i> valider</button>
             </div>
          <div class="col-md-4">
            <v-text-field
          v-model="search"
          
          label="Récherche"
          single-line
          hide-details
        ></v-text-field>
          </div>

        </div>
   


<div class="row row-space-10 m-b-20">
            <!-- begin col-4 -->
            <div class="col-lg-4">
              <div class="widget widget-stats bg-gradient-teal m-b-10">
                <div class="stats-icon stats-icon-lg"><i class="fa fa-globe fa-fw"></i></div>
                <div class="stats-content">
                  <div class="stats-title">Commission grossiste</div>
                  <div class="stats-number"> {{formatageSomme(commissionGrossite)}}</div>
                  <div class="stats-progress progress">
                    <div class="progress-bar" style="width: 70.1%;"></div>
                  </div>
                  
                </div>
              </div>
            </div>
            <!-- end col-4 -->
            <!-- begin col-4 -->
            <div class="col-lg-4">
              <div class="widget widget-stats bg-gradient-blue m-b-10">
                <div class="stats-icon stats-icon-lg"><i class="fa fa-dollar-sign fa-fw"></i></div>
                <div class="stats-content">
                  <div class="stats-title">Commissions detaillant</div>
                  <div class="stats-number">{{formatageSomme(commissionDetaillant)}}</div>
                  <div class="stats-progress progress">
                    <div class="progress-bar" style="width: 40.5%;"></div>
                  </div>
                 
                </div>
              </div>
            </div>
            <!-- end col-4 -->
            <!-- begin col-4 -->
            <div class="col-lg-4">
              <div class="widget widget-stats bg-gradient-purple m-b-10">
                <div class="stats-icon stats-icon-lg"><i class="fa fa-archive fa-fw"></i></div>
                <div class="stats-content">
                  <div class="stats-title">Taxe</div>
                  <div class="stats-number">{{formatageSomme(commissionTauxcommission)}}</div>
                  <div class="stats-progress progress">
                    <div class="progress-bar" style="width: 76.3%;"></div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>

                   <v-data-table
                      :headers="headers"
                      :items="detaillant"
                      :search="search"
                      sort-by="code"
                      class="elevation-1"
                        >
                       

<template v-slot:item.somm_montant_cashin="{item}">
                          <div>
                            {{formatageSomme(item.somm_montant_cashin)}}
                          </div>
                        </template>
                        <template v-slot:item.somm_nbr_cashin="{item}">
                          <div>
                            {{formatageSomme(item.somm_nbr_cashin)}}
                          </div>
                        </template>
                        <template v-slot:item.somm_montant_cashout="{item}">
                          <div>
                            {{formatageSomme(item.somm_montant_cashout)}}
                          </div>
                        </template>
                        <template v-slot:item.somm_nbr_cashout="{item}">
                          <div>
                            {{formatageSomme(item.somm_nbr_cashout)}}
                          </div>
                        </template>
                        <template v-slot:item.montant_total="{item}">
                          <div>
                            {{formatageSomme(item.montant_total)}}
                          </div>
                        </template>

                        <template v-slot:item.nbr_total_operation="{item}">
                          <div>
                            {{formatageSomme(item.nbr_total_operation)}}
                          </div>
                        </template>

                        <template v-slot:item.detaillant_commision="{item}">
                          <div>
                            {{formatageSomme(Math.round(item.detaillant_commision))}}
                          </div>
                        </template>

                         <template v-slot:item.taxe="{item}">
                          <div>
                            {{formatageSomme(Math.round(item.taxe))}}
                          </div>
                        </template>



                    <template v-slot:item.actions="{ item }">
                      

                        <div class="btn-group btn-group-justified">
                          <a class="btn btn-success" @click="editItem(item)"><i class="fas fa-edit"></i> Payé la commission</a>
                       
                          
                        </div>

                    </template>
                </v-data-table>
					
				</div>




   
  

  </div>
				</div>
			</div>
		
		</div>

     <v-dialog
              v-model="getterLoadingCommissionDetaillant"
              persistent
              width="250"
              height="200"
            >
              <v-card
                color="#2d353c"
                dark
                class="text-center"
              >
                <v-card-text  class="white--text">
                  Requête en cours de traitement<br>
                  <v-progress-circular
                    indeterminate
                    color="white"
                    class="mb-0"
                    size="50"
                  ></v-progress-circular>
                </v-card-text>
              </v-card>
        </v-dialog>
    </v-main>
    </v-app>
</template>



<script>
import { mapGetters, mapActions} from 'vuex'
    import { required, minLength ,email } from "vuelidate/lib/validators";
  import { ModelListSelect } from 'vue-search-select'
    export default {
          name: "Utilisateur",
    components: {
     ModelListSelect
    },
        data() {
            return {
               dialog:false,
               test:true,
               search:"",
               date_debut:"",
               date_fin:"",
               loading_activite:false,
               formData:{
                   date_debut:"",
                   date_fin:"",   
               },
               edite:"",
       dialogEdite: false,
       dialogDelete: false,
       urlBase:process.env.VUE_APP_BASE,
     
      headers: [
        { text: 'Nom et prénom(s)', value: 'name' },
        { text: 'Contact personnel', value: 'contact_personnel' },
        { text: 'Numéro TP', value: 'numero_tp' },
        { text: 'Numéro PDV', value: 'numero_pdv' },
        { text: 'Montant total CASHIN', value: 'somm_montant_cashin' },
        { text: 'Montant total CASHOUT', value: 'somm_montant_cashout' },
        { text: 'Montant total(CASHIN + CASHOUT)', value: 'montant_total' },
        { text: 'Taxe', value: 'taxe' },
         { text: 'Commssions', value: 'detaillant_commision' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      Liste_role:[
        {id:"APV2",libelle:"APV2"},
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
      
        libelle: "",
      },
      defaultItem: {
       code: '',
        libelle: "",
      },



            }
        },
        created(){
         this.getSyntheseActivite()
        },
        computed:{
            ...mapGetters('Application', ['getterStructure','getterLocalisationGeographique', 'getterActeurJuridique',"getterLoadingActeurJurique","getterErrorActeurJuridique","getterTypeActeurJuridique"]),
             
              ...mapGetters('GestionCommission', ['getterCommissionDetaillant',"getterLoadingCommissionDetaillant"]),
             
             detaillant(){
             return this.getterCommissionDetaillant.filter(item=>item.segment=="EPERVIER")
             },

              ...mapGetters('Utilisateurs', ["getterErrorUser","getterLoadingUser","getterUser"]),
              formatageSomme(){
       return montant=>{
         if(montant==0) return 0
         if(montant){
           return (
               montant.toString()
                .split("")
                .reverse()
                .reduce((acc, montant, i) => {
                  return montant + (i && !(i % 3) ? " " : "") + acc;
                }, "") + " "
            );
         }
         return montant
       }
      },
   commissionGrossite(){
  let  grossiste_commision= this.detaillant.reduce(function (total, currentValue) {
                                return total + parseFloat(currentValue.grossiste_commision) ;
                            }, 0);
    if(grossiste_commision==0){
      grossiste_commision=0
    }


     return grossiste_commision
   },
    commissionDetaillant(){
  let  detaillant_commision= this.detaillant.reduce(function (total, currentValue) {
                    return total + parseFloat(currentValue.detaillant_commision) ;
                            }, 0);

  if(detaillant_commision==0){
      detaillant_commision=0
    }
     return Math.round(detaillant_commision) 
   },
   commissionTauxcommission(){
  let  taux_commission= this.detaillant.reduce(function (total, currentValue) {               console.log(currentValue.taxe)
                    return total + parseFloat(currentValue.taxe) ;
                            }, 0);
  if(taux_commission==0){
    taux_commission=0;
  }
  console.log("...........")
     return Math.round(taux_commission) 
   },
              detaillantListe(){
            return this.getterUser.filter(item=>item.role=="APV2")
              },
             formTitle () {
             return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
           },
           loading(){
               return this.getterLoadingUser
           },
           liste_structure() {
      return this.getterStructure.reverse();
    },

    recup_id_acteur(){
       const data = this.getterActeurJuridique.find(item=>item.id==this.formData.acteur_juridique_id);
       
       if(data){
         return data.structure_id
       }
    },

    recup_libelle_structure(){
       const Objet = this.getterStructure.find(item=>item.id==this.recup_id_acteur);
       if(Objet){
         return Objet.libelle
       }
       return ""
    },

     recup_id_structure(){
       const Objet = this.getterStructure.find(item=>item.id==this.recup_id_acteur);
       if(Objet){
         return Objet.id
       }
        return ""
    },


           typeActeurJu(){
             return this.getterTypeActeurJuridique.reverse();
           },
           utilisateurPasActeurJuridique(){
             return acteur_juridique=>{
               if(!acteur_juridique) return []
               return this.getterUser.filter(item=>item.acteur_juridique_id==acteur_juridique)
             }
           },
           regionActeurJuridique(){
             return localisation_geo_graphique_id=>{
               if(!localisation_geo_graphique_id) return ""

               let objet=this.getterLocalisationGeographique.find(item=>item.id==localisation_geo_graphique_id)
               if(objet==undefined) return ""
               return objet.libelle
             }
           },

             libelle_role(){
        return (id) => {
        if (id != null && id != "" && id != undefined) {
          const Objet= this.Liste_role.find((item)=>item.id==id)
           if(Objet){
             return Objet.libelle;
             }return ""
        }
        }
          
      },

          //  libelle_role(){
          //    return id =>{
          //      let Objet=this.Liste_role.find(item=>item.id==id)
          //      if(Objet){
          //        return Objet.libelle;
          //      }return "";
          //    }
          //  },
            imageUsers(){
              return (image,id)=>{
              //  console.log(id)
                if(!image) return this.urlBase+"/users/default/1.jpg"

               return this.urlBase+"/users/"+image
              }
	          }
        },
        validations: {
            formData: {
                     date_debut:{ required },
                    date_fin:{ required  },
                   
            }
        },

        //
        methods:{
             ...mapActions('Application', ['getActeurJuridique',"ajouterActeurJuridique",
             "supprimerActeurJuridique","modificationActeurJuridique"]),

...mapActions('GestionCommission', ['searchCommission',"getSyntheseActivite"]),

             ...mapActions('Utilisateurs', ['getUsers',"ajouterUser",
             "supprimerUser","modificationUser"]),



            gotoModale(){
              
               this.dialog=true 
            }
            ,

            searchCom() {
               //this.formData.structure_id=this.recup_id_structure;
                this.$v.formData.$touch();
                if(this.$v.formData.$error) {

                    return
                }
                    this.loading_activite=true
                // console.log(this.formData)
                this.searchCommission(this.formData).then((res)=>{
                    this.loading_activite=false
                }).catch((err)=>{
                    this.loading_activite=false
                })
               
               

            },
              

modification(item){
  
this.modificatiomnUser(item)
},
      editItem (item) {
       this.edite=item
        this.dialogEdite = true
      },

      deleteItem (item) {
      this.edite=item
      console.log(this.edite)
      this.dialogDelete=true
      },

      deleteItemConfirm (item) {
         this.dialogDelete=false
        this.supprimerUser(item.id)
      },
      
 
  
    

      
        },
        watch: {
      
     },
    }
</script>

<style scoped>
.input {
        background-color: #f7f7f7;
        border: 1px solid #c7c7c7;
        padding: 0.3rem 0.5rem;
        border-radius: 2px;
    }
    .hasError .input {
        border: red;
    }
    .hasErroInpu{
        border: 1px red solid;
    }
    .hasError label {
        color: red;
    }
    .icon:hover {
	 cursor: pointer;
}
 .muted {
	 color: gray;
	 font-size: 80%;
}
</style>



<style>
  .vtl .vtl-drag-disabled {
	 background-color: #d0cfcf;
}
 .vtl .vtl-drag-disabled:hover {
	 background-color: #d0cfcf;
}
 .vtl .vtl-disabled {
	 background-color: #d0cfcf;
}
 
 
</style> 
 
