const defaultstate = {



     syntheseActivite: [],
     loadingSyntheseActivite: true,
     errorSyntheseActivite: false,


     commissionDetaillant:[],
     loadingCommissionDetaillant: true,
     errorCommissionDetaillant: false,


     objectifPeriode: [],
     loadingObjectifPeriode: true,
     errorObjectifPeriode: false,


    

};

export default defaultstate