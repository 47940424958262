<template>
  <v-app style="background: url(assets/img/img7.jpg) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;">
    <notifications/>
    <v-main>
        	<div id="content" class="content" >
<div class="row">
                <!-- Mega menu -->
                <div class="dropdown mega-menu show col-md-8" >
                    
                    <div class="dropdown-menu text-left show" aria-labelledby="dropdownMenuButton" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 100px; left: -55px; transform: translate3d(144px, 57px, 0px);">
                        <div class="row m-0">
                            <div class="col-md-4 p-4 bg-img2">
                                <h2 class="title">Babylone <br></h2>
                                <p>Systeme Informatique de Gestion d'Amanda Prestige
                                </p>
                              
                                <button class="btn btn-lg btn-rounded btn-outline-warning">Documentation</button>
                            </div>
                            <div class="col-md-8 p-4">
                              
                                <div class="menu-icon-grid w-auto p-0" style="color:black !important">
                                    <a href="#" @click.prevent="goToModule(1)" style="color:black !important"><i class="i-Shop-4"></i> Ma Caisse</a>
                                    <a href="#" @click.prevent="goToModule(2)" style="color:black !important"><i class="i-Library"></i>Intermede</a>
                                    <a href="#" @click.prevent="goToModule(3)" style="color:black !important"><i class="i-Drop"></i> APV2</a>
                                    <a href="#" @click.prevent="goToModule(4)" style="color:black !important"><i class="i-File-Clipboard-File--Text"></i> Gestion des commissions</a>
                                    <a href="#" @click.prevent="goToModule(5)" style="color:black !important"><i class="i-Checked-User"></i> Parametre</a>
                                    <a href="#" @click.prevent="goToModule(6)" style="color:black !important"><i class="i-Ambulance"></i> Support</a>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
                
            </div>
            </div>
  </v-main>
    </v-app>
   
</template>

<script>

export default {
  name: 'App',

  components: {
   
  },

  data(){
        return {
           

            }
        },
        created(){
//console.log(this.getterFicheEntretien)



        },
		computed:{
     
		},
        validations: {
           
        },

			methods:{
           

      goToModule(id){

        localStorage.setItem('module_app', id); 

        switch (id) {
        case 1:
        
           this.gotoModuleRoute("Home");
            break;
        case 2:
            console.log(id);
            break;
        case 3:
            this.gotoModuleRoute("PresenceApv2");
            break;
         case 4:
             this.gotoModuleRoute("Commission");
            break;
         case 5:
             this.gotoModuleRoute("Utilisateur");
            break;
               
         default:
            console.log("");
        }
      },
      gotoModuleRoute(nameRoute){
        console.log(nameRoute)
          this.$router.push({
             name: nameRoute
          })
          window.location.reload()
      },
      editItem(item) {
       this.formData=item
        this.dialogEdite = true
      },

      deleteItem2(item) {
      this.edite=item
      console.log(this.edite)
     console.log("................")
      this.dialogDelete=true
      },

      deleteItemConfirm(){
         this.dialogDelete=false
        
        this.supprimerStructureGeographique(this.edite.id)
      },
      tele(){
      this.dialog=true
      },
     
     tel(){
      let b={
        date_debut:this.date_debut,
        date_fin:this.date_fin
      }
        this.teletest(b)
     },

        
           getStatus () {
             return status=>{
        if (status == 0) return 'EN ATTENTES'
         if (status ==1) return 'EN-COURS'
         if (status ==2) return 'ACCEPTER'
         return 'REJETTER'
             }
      
      },
	  },
};
</script>

<style scoped>
table ,tr td{
    border:1px solid #008a8a
}
tbody {
    display:block;
    height:500px;
    overflow:auto;
}
thead, tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;
}
thead {
    width: calc( 100% - 1em )
}
table {
    
    width:100%;
}
</style>